import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { HttpService } from '../../core/http/http.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../core/notification/notification.service';
import { NotificationType } from '../../core/notification/notification-type.enum';
import { sendGAEvent } from '../../utils/google-analytics';

const CONTACT_URL = environment.contactMail;

@Component({
    selector: 'app-contact-form-view',
    templateUrl: './contact-form-view.component.html',
    styleUrls: ['./contact-form-view.component.scss'],
})
export class ContactFormViewComponent implements OnInit {
    contactForm: FormGroup;
    showSuccessMessage = false;
    constructor(private fb: FormBuilder, private http: HttpService, private notificationService: NotificationService) {}

    ngOnInit() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            address: ['', Validators.required],
            city: ['', Validators.required],
            phone: [
                '',
                Validators.pattern(
                    /\+?\d{1,4}?[\s]?[-.\s\/]?[\s]?\(?\d{1,3}?\)?[\s]?[-.\s\/]?[\s]?\d{1,4}[\s]?[-.\s\/]?[\s]?\d{1,4}[\s]?[-.\s\/]?[\s]?\d{1,9}[\s]?[-.\s\/]?[\s]?\d{1,9}[\s]?[-.\s\/]?[\s]?\d{1,9}/
                ),
            ],
            mail: ['', Validators.email],
            msg: ['', Validators.required],
            ConfirmPrivacy: [false, Validators.requiredTrue],
        });
    }

    get valid(): boolean {
        return this.contactForm.valid;
    }

    onSubmit() {
        this.http.post(CONTACT_URL, { ...this.contactForm.value }, { notify: 'ERROR' }).subscribe(
            (resp: any) => {
                if (resp.ErrorCode === 0) {
                    this.showSuccessMessage = true;
                    this.notificationService.notify(
                        'Ihre Nachricht wurde erfolgreich gesendet',
                        NotificationType.SUCCESS,
                        5000
                    );
                    sendGAEvent('sent_contact_form', 'contact_form', 'contact_success');
                    this.contactForm.reset();
                } else {
                    this.showSuccessMessage = false;
                    sendGAEvent('sent_contact_form', 'contact_form', 'contact_error');
                }
            },
            err => {
                this.showSuccessMessage = false;
                sendGAEvent('sent_contact_form', 'contact_form', 'contact_error');
            }
        );
    }
}
