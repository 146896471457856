import { environment } from '../../../environments/environment';
import { InsurantsFormConfig } from '../../models/insurants-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: [
        '',
        'tarif-antragsteller',
        'versicherte-personen',
        'vertragsbeginn',
        'rechnungsadresse',
        'zahlungsdaten',
        'vertragserklaerungen',
    ],
    taxInfo: 'Steuerfrei nach § 4 Abs. 1 Nr. 5 VersStG',
    hasFlexPrice: true,
    requiresMedicalData: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    insurantMaxAge: 99,
    contractStartMode: 'month',
    monthsInAdvance: 5,
};

const presentation: ProductPresentation = {
    description: `
        <p><strong class="blue">Die Zahn-Zusatzversicherung für gesetzlich Versicherte ab 15,<sup>55</sup>&nbsp;<span class="currency-sign">€</span> pro Monat.</strong></p>
        <p>Diese Zahn-Zusatzversicherung ergänzt sinnvoll die unvollständigen Leistungen gesetzlicher Krankenversicherungen und reduziert Ihren Eigenanteil auf ein Minimum. So bleiben gesunde Zähne und notwendige Behandlungen oder Zahnersatz einfacher bezahlbar.</p>
        <p>Für Kinder, die in der GKV der Eltern mitversichert sind, gelten attraktive Jugend-Tarife bereits ab 6,<sup>57</sup>&nbsp;<span class="currency-sign">€</span> pro Monat.</p>
        <p><strong class="blue">Tarif Basic:</strong></p>
        <ul>
            <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Zahnersatz</li>
            <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Kieferorthopädie bis 800&nbsp;€ bei Vorliegen der kieferorthopädischen Indikationsgruppen 3,4 und 5 sowie 300&nbsp;€ Zuschuss bei Vorliegen der kieferorthopädischen Indikationsgruppen 1 und 2.</li>
            <li>Zahnprophylaxe bis 150&nbsp;€ im Jahr</li>
        </ul>
        <p><strong class="blue">Tarif Plus:</strong></p>
        <ul>
            <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Zahnbehandlung</li>
            <li>Erstattungen und Leistungen wie Tarif Basic bei Zahnersatz, Kieferorthopädie und Prophylaxe</li>
        </ul>
        <p><strong class="blue">Tarif Premium:</strong></p>
        <ul>
            <li class="no-margin-bottom">100&nbsp;% Erstattung* bei Zahnbehandlung</li>
            <li class="no-margin-bottom">90&nbsp;% Erstattung* bei Zahnersatz</li>
            <li class="no-margin-bottom">90&nbsp;% Erstattung* bei Kieferorthopädie bis 900&nbsp;€ bei Vorliegen der kieferorthopädischen Indikationsgruppen 3,4,5 sowie 300&nbsp;€ Zuschuss bei Vorliegen der kieferorthopädischen Indikationsgruppen 1 und 2.</li>
            <li>Zahnprophylaxe bis 150&nbsp;€ im Jahr</li>
        </ul>
        <p><small>(*zusammen mit den Vorleistungen der GKV)</small></p>
        <p>Nach drei Versicherungsjahren entfällt eine Begrenzung der Versicherungsleistungen für Zahnersatz, Zahnbehandlung und Kieferorthopädie (Begrenzung auf 600&nbsp;€ im ersten Versicherungsjahr, auf 1000&nbsp;€ im zweiten Versicherungsjahr, auf 1500&nbsp;€ im dritten Versicherungsjahr).</p>
        <p>Die Kosten für die Prophylaxe werden nicht auf die Höchstsätze angerechnet.</p>
        <p>Bei einer unfallbedingten Behandlung (einschließlich Zahnersatz) entfällt die Begrenzung auf diese Höchstsätze.</p>
        <p><strong class="blue">Ihr Zusatzschutz für gesunde und schöne Zähne – wählen Sie hier Ihren Wunsch-Tarif.</strong></p>
    `,
    bulletPoints: [
        'Umfassende Zahn-Zusatzversicherung für gesetzlich Versicherte',
        'Inkl. Schutz für mitversicherte Kinder und Jugendliche ab nur 6,57 € im Monat',
        'Wählen Sie aus 3 Tarifen Ihren individuellen Wunsch-Zusatzschutz: Basic, Plus und Premium – für mitversicherte Kinder gelten die gleichen Leistungen wie für den versicherten Elternteil',
        'Europaweiter Schutz – auch im Urlaub und auf Reisen',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherungen_Zahnzusatzversicherung.jpg',
    imageAlt: 'Ihr Zusatzschutz für gesunde und schöne Zähne von astra Versicherung AG',
    imageHeadline: '<h3 class="no-margin-bottom">Ihr Zusatzschutz für gesunde und schöne Zähne</h3>',
    docIPIDUrl: 'astra_IPID_Zahnzusatzversicherung.pdf',
    docAVBUrl: 'astra_AVB_Zahnzusatz.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Zahnzusatz.pdf',
    pageTitle: 'Zahn-Zusatzversicherung | ab 15,55 € / Monat | astra Versicherung',
};

const insurantsForm: InsurantsFormConfig = {
    headline: 'Versicherte Personen',
    priceFromInsurants: true,
    requiresInsurantsFamilyRelationship: 2,
    insurants: [
        {
            category: 'insuree',
            displayName: 'Antragsteller',
            label: 'Antragsteller',
            minAge: 18,
            maxAge: 99,
            maxCount: 1,
            startWith: 'insuree',
            rankIdMandatory: true,
            insuranceRanks: [...environment.insuranceRanks],
        },
        {
            category: 'partner',
            displayName: 'mitversicherte Person',
            displayNameSummary: 'Mitversicherte Personen',
            label: '',
            minAge: 0,
            maxAge: 99,
            maxCount: 99,
            firstOneRemovable: true,
            addButtonLabel: 'Weitere Person hinzufügen',
            rankIdMandatory: true,
            insuranceRanks: 'insuree',
        },
    ],
};

export const zahnzusatzversicherung: Product = new Product({
    slug: 'zahnzusatz',
    title: 'Zahnzusatzversicherung',
    fullTitle: 'Zahnzusatzversicherung',
    insuranceTypeName: 'Zahnzusatz',
    config,
    presentation,
    insurantsForm,
});
