import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

const allowedKeys = 'Backspace;Enter;ArrowLeft;ArrowRight;Delete;Home;End'.split(';');

@Directive({
    selector: '[appDateInput]',
})
export class DateInputDirective {
    @HostListener('input', ['$event']) public onInput(e: InputEvent): void {
        if (e.inputType === 'insertText') {
            const target = e.target as HTMLInputElement;
            const cursorPosition = target.selectionStart === target.value.length ? 100 : target.selectionStart;
            const val = this.control.value.replace(/\.+/gm, '');
            this.control.control.setValue(
                [val.substr(0, 2), val.substr(2, 2), val.substr(4, 4)].filter(val => val.length).join('.')
            );
            target.selectionEnd = target.selectionStart = cursorPosition;
        }
    }
    @HostListener('keydown', ['$event']) public onKeydown(e: KeyboardEvent): void {
        // TODO: allow copy & paste
        const target = e.target as HTMLInputElement;
        if (
            !(/^[0-9]$/.test(e.key) && (target.value.length < 10 || target.selectionStart !== target.selectionEnd)) &&
            !allowedKeys.includes(e.key)
        ) {
            e.preventDefault();
            return;
        }
    }

    constructor(private control: NgControl) {}
}
