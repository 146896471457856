import { environment } from '../../environments/environment';

declare let gtag: (
    type: string,
    action: string,
    params?: {
        event_category?: string;
        event_label?: string;
        send_to?: string;
        currency?: 'EUR';
        transaction_id?: string;
        value?: number;
        event_callback?: (id?: string | number) => void;
    }
) => void;

export function sendGAEvent(action: string, category: string, label: string): void {
    if (typeof gtag === 'function' && environment.sendGAEvents) {
        gtag('event', action, { event_category: category, event_label: label });
    }
}

export function sendConversionEvent(value: number, callback?: (id?: string | number) => void): void {
    if (typeof gtag === 'function' && environment.sendGAEvents) {
        gtag('event', 'conversion', {
            send_to: `${environment.googleConversionId}/gmlBCJiYzogDEMqwwZ8D`,
            value,
            currency: 'EUR',
            transaction_id: '',
            event_callback: callback,
        });
    }
}
