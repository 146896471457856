<ng-container *ngIf="options" [formGroup]="selectForm">
    <div class="form-control">
        <select formControlName="select" required>
            <option [ngValue]="null" disabled>Bitte wählen</option>
            <option *ngFor="let option of options" [ngValue]="option.value || option">
                {{ option.label || option }}
            </option>
            <option value="other">{{ otherOptionLabel }}</option>
        </select>
    </div>
    <div class="form-control" *ngIf="selectForm.get('select').value === 'other'">
        <label for="other-input">{{ pleaseEnterOtherLabel }}</label>
        <input id="other-input" type="text" formControlName="other" [placeholder]="placeholder" required />
    </div>
</ng-container>
