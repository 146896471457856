<div class="content-container">
    <div class="grid-container triple-gutter-vertical">
        <div class="grid-1-1 gutter-bottom">
            <h2 class="no-margin-bottom">Passwort vergessen</h2>
            <hr>
        </div>
        <form class="gutter-top" [formGroup]="form" (submit)="onSubmit()" *ngIf="!resetRequested">
            <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 gutter-bottom">
                <p>Wenn Sie Ihr Passwort vergessen haben, können Sie hier ein neues anfordern. Geben Sie hierzu die E-Mailadresse ein, mit der Sie sich auch sonst für unser Kundenportal anmelden, sowie die Versicherungsnummer einer mit Ihrem Kundenkonto verknüpften Versicherung. Sie finden die Versicherungsnummer auf Ihrem Versicherungsschein.</p>
                <p>Sie erhalten anschließend eine E-Mail, mit der Sie die Anforderung eines neuen Passworts bestätigen können.</p>
            </div>
            <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 gutter-bottom">
                <label class="no-margin-top">Email</label>
                <input type="email" formControlName="email" placeholder="E-Mailadresse" required>
                <label>Versicherungsnummer</label>
                <input type="text" formControlName="insuranceNo" placeholder="Versicherungsnummer" required>
                <div class="grid-container">
                    <div class="grid-1-2 grid-sm-1-2 hidden-xxs">&nbsp;</div>
                    <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 gutter">
                        <button type="submit" [disabled]="invalid()">Senden</button>
                    </div>
                </div>
            </div>
        </form>
        <ng-container *ngIf="resetRequested">
            <div class="grid-1-1">
                <p><strong>Fast geschafft!</strong></p>
                <p>Wir haben eine E-Mail an die von Ihnen angegebene Adresse {{ form.get('email').value }} geschickt. Bitte benutzen Sie den Bestätigungslink in dieser E-Mail, um die Anforderung eines neuen Passworts zu bestätigen.</p>
                <p>Wenn Sie keine E-Mail erhalten, prüfen Sie bitte auch Ihren Spam-Ordner.</p>
                <p>Sie können dieses Fenster nun schließen.</p>
            </div>
        </ng-container>
    </div>
</div>
