import { InsuranceTypeDto } from '../models/insurance-type-dto';
import { InsuranceType } from '../models/insurance-type';

export function dtoToInsuranceType(dto: InsuranceTypeDto): InsuranceType {
    return {
        id: dto.Id,
        name: dto.Name,
        insuredPersonMandatory: dto.InsuredPersonsMandatory,
        settlementPeriod: dto.SettlementPeriod === 2 ? 'month' : 'year',
        pricePerSettlementPeriod: dto.PricePerSettlementPeriod,
    };
}

export function insuranceTypeToDto(insuranceType: InsuranceType): InsuranceTypeDto {
    return {
        Id: insuranceType.id,
        Name: insuranceType.name,
        InsuredPersonsMandatory: insuranceType.insuredPersonMandatory,
        SettlementPeriod: insuranceType.settlementPeriod === 'month' ? 0 : 1,
        PricePerSettlementPeriod: insuranceType.pricePerSettlementPeriod,
    };
}
