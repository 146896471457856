import { Pipe, PipeTransform } from '@angular/core';
import { products } from '../../config/products.const';

@Pipe({
    name: 'insuranceType',
})
export class InsuranceTypePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (typeof value !== 'string') return null;
        let product = products.find(item => item.insuranceTypeName.toUpperCase() == value.toUpperCase());
        return product ? product.title : value;
    }
}
