import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-person', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
};

const presentation: ProductPresentation = {
    description: `
        <p><strong>Schüler-Fahrrad-Versicherung</strong></p>
        <p>Versichert durch die astra Versicherung AG</p>
        <p><strong>Bike, Roller, Kickboards: der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung für nur 14,<sup>99</sup> <span class="currency-sign">€</span> im Jahr.</strong></p>
        <p>Wer kennt die Geschichte nicht – ewig Taschengeld gespart und noch mit finanzieller Hilfe von Eltern und Verwandtschaft endlich das Traumbike gekauft… ein Desaster, wenn dann das neue Fahrrad trotz Schloss vom Schulhof geklaut wird oder beschädigt wird.</p>
        <p>Dafür gibt es nun speziell für Schüler den erschwinglichen Diebstahl- und Reparatur-Schutz für die Fahrten zur Schule, während des Unterrichts und sonstigen schulischen Veranstaltung wie Fahrten zum Sportunterricht, Kirche oder Schwimmbad.</p>
        <p>Diese Schüler-Fahrrad-Versicherung, die auch für Roller und Kickboards gilt, ist ideal um seinen mobilen Untersatz auf dem Weg zur Schule und nach Hause sowie während des Unterricht zu schützen.</p>
        <p><strong>Der Schutz für Bikes, Roller und Kickboards für nur 14,<sup>99</sup> <span class="currency-sign">€</span> im Jahr</strong></p>
    `,
    bulletPoints: [
        'Die Schüler-Fahrrad-Versicherung mit Reparatur- und Diebstahlschutz',
        'Plus Schutz bei Beschädigung und Zerstörung',
        'Inkl. Versicherungsschutz für Roller',
        'Inkl. Versicherungsschutz für Kickboards',
    ],
    imageUrl: 'assets/img/product-headers/astra-Versicherungen_Schüler-Fahrrad-Versicherung.jpg',
    imageAlt: 'Die Fahrrad-Versicherung speziell für Schüler. Von astra Versicherungen',
    imageHeadline: '<h3 class="no-margin-bottom">Die Fahrrad-Versicherung speziell für Schüler</h3>',
    docIPIDUrl: 'astra_IPID_Fahrrad.pdf',
    docAVBUrl: 'astra_AVB_Fahrrad.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Fahrrad.pdf',
};

export const fahrradversicherungSchueler: Product = new Product({
    slug: 'schueler-fahrrad-versicherung',
    title: 'Schüler-Fahrrad-Versicherung',
    fullTitle: 'Schüler-Fahrrad-Versicherung',
    insuranceTypeName: 'Schüler Fahrrad 2020',
    config,
    presentation,
});
