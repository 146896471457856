import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerService } from './customer/customer.service';
import { AuthService } from './auth/auth.service';
import { HttpService } from './http/http.service';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification/notification.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SpinnerService } from './spinner/spinner.service';
import { AuthInterceptor } from './http/auth.interceptor';
import { LoggerService } from './logger/logger.service';
import { ConsoleLoggerService } from './logger/console-logger.service';
import { HelperService } from './helper.service';
import { ContractService } from './contract/contract.service';
import { DamageService } from './damage/damage.service';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';

@NgModule({
    imports: [CommonModule, HttpClientModule, RouterModule, ReactiveFormsModule, SharedModule],
    declarations: [NotificationComponent, SpinnerComponent, HeaderComponent, FooterComponent, CookieSettingsComponent],
    exports: [HeaderComponent, FooterComponent, NotificationComponent, SpinnerComponent, CookieSettingsComponent],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: 'Window', useValue: window },
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
                { provide: LoggerService, useClass: ConsoleLoggerService },
                AuthService,
                AuthGuard,
                HttpService,
                CustomerService,
                SpinnerService,
                NotificationService,
                HelperService,
                ContractService,
                DamageService,
            ],
        };
    }
}
