import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { navigation } from '../../config/navigationLinks.const';
import { fromEvent } from 'rxjs';
import { filter, tap, map, merge } from 'rxjs/operators';
import { HeaderModeService } from '../header-mode/header-mode.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public isLoggedIn: boolean;
    public navigation = navigation.sort((a, b) => (a.index > b.index ? 1 : -1));
    public navLinkCheckedIndex: number = -1;
    private lastNavLinkChecked: number = -1;
    private ignoredElements: HTMLCollectionOf<HTMLElement>;

    // TODO: clean this up!
    constructor(public authService: AuthService, private router: Router, public headerModeService: HeaderModeService) {
        this.authService.isLoggedIn$.subscribe(val => (this.isLoggedIn = val));
        // close portal nav when clicking outside:
        fromEvent(document, 'click')
            .pipe(
                // TODO: fix deprecation warning!
                merge(fromEvent(document, 'touchend')),
                //throttleTime(500),
                map(e => [e, e.target as HTMLElement]),
                filter(([event, target]: [Event, HTMLElement]) => {
                    let t = !(
                        this.lastNavLinkChecked != -1 &&
                        target.parentElement.dataset.index &&
                        Number(target.parentElement.dataset.index) == this.lastNavLinkChecked
                    );
                    return t;
                }),
                tap(([event, target]: [Event, HTMLElement]) => {
                    // clicked element has data-index attribute?
                    // show the sub-navigation with the same index:
                    const index = target.parentElement?.dataset?.index
                        ? Number(target.parentElement.dataset.index)
                        : -1;
                    if (index != -1) this.uncheckAll(index, index == 999); // force hiding of main-nav when portal button (index 999) is clicked.

                    if (index != -1 && event.type == 'touchend') {
                        if (!target.parentElement.classList.contains('disable-hover'))
                            target.parentElement.classList.add('disable-hover');
                        event.preventDefault();

                        this.lastNavLinkChecked = index;
                        setTimeout(
                            (() => {
                                this.lastNavLinkChecked = -1;
                            }).bind(this),
                            500
                        );
                    }
                }),
                filter(([event, target]: [Event, HTMLElement]) => {
                    return !target.classList.contains('nav-link');
                }),
                filter(([event, target]: [Event, HTMLElement]) => {
                    for (let i = 0; i < this.ignoredElements.length; i++) {
                        if (this.ignoredElements[i].isSameNode(target) || this.ignoredElements[i].contains(target))
                            return false;
                    }
                    return true;
                })
            )
            .subscribe(() => {
                // user clicked outside of any elements
                // not marked as 'ignore-toggle-events',
                // hide all sub-navigation elements:
                this.uncheckAll();
            });
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                // close navigation when navigated succesfully:
                this.uncheckAll();
            }
        });
    }

    ngOnInit() {
        this.ignoredElements = document.getElementsByClassName('ignore-toggle-events') as HTMLCollectionOf<HTMLElement>;
    }

    public logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    public uncheckAll(keepChecked: number = -1, forceNavClosing: boolean = true) {
        if (forceNavClosing) {
            const drop = document.getElementById('drop') as HTMLInputElement;
            if (drop) {
                drop.checked = false;
            }
        }
        this.navLinkCheckedIndex = this.navLinkCheckedIndex == keepChecked ? -1 : keepChecked;
    }
}
