<app-hero imagePath="/assets/img/product-headers/astra_Versicherung_Schutzbrief_Students.jpg"
    imageAlt="astra Versicherung AG - Impressum und Datenschutz" imageAlign="top"></app-hero>
<div class="content-container double-gutter-vertical">
    <div class="grid-container">
        <div class="grid-1-1 double-gutter-bottom">
            <h1 class="light-blue">Impressum</h1>
        </div>
        <div class="grid-2-3">
            <!-- <h2>I. Impressum</h2> -->
            <p>
                <strong>astra Versicherung AG</strong><br />
                Dudenstr. 46<br />
                68167 Mannheim<br />
                Telefon: +49 621 1247682-0<br />
                Telefax: +49 621 1247682-642<br />
                E-Mail: <a href="mailto:info@astra-versicherung.de">info@astra-versicherung.de</a>
            </p>
            <p>
                <strong>Vorsitzender des Aufsichtsrats</strong><br />
                Stefan Laus
            </p>
            <p>
                <strong>Vorstand</strong><br />
                Reinhard Kunz<br />
                Philipp Langendörfer<br />
                Rudolf Bönsch 
            </p>
            <p>
                <strong>Handelsregister</strong><br />
                Amtsgericht Mannheim, HRB 724799<br />
                Umsatzsteueridentifikationsnummer (USt-ID): DE305626656<br />
                Versicherungssteuernummer: 37007/10579
            </p>
            <p>
                <strong>Verantwortlich für den Inhalt</strong><br />
                Reinhard Kunz, Anschrift wie oben
            </p>
            <p>
                <strong>Zuständige Aufsichtsbehörde</strong><br />
                Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)<br />
                Graurheindorfer Straße 108, 53117 Bonn<br />
                <a href="http://www.bafin.de" target="_blank" rel="nofollow">www.bafin.de</a>
            </p>
            <p>
                <strong>Information zur Online-Streitbeilegung</strong><br />
                Beschwerdeverfahren via Online-Streitbeilegung für Verbraucher (OS):
                <a href="http://ec.europa.eu/consumers/odr/" target="_blank"
                    rel="nofollow">http://ec.europa.eu/consumers/odr/</a>. Verbraucher können sich zur Beilegung von
                Streitigkeiten an die vorbenannte
                Online-Streitbeilegungs-Plattform wenden.
            </p>
            <h2 class="double-margin-top">II. Nutzung der Internetseite</h2>
            <h3>1. Haftung für Inhalte, Hyperlinks</h3>
            <p>Für die eigenen Inhalte unserer Seite sind wir nach den allgemeinen Gesetzen verantwortlich.</p>
            <p>
                Wir haben die in diesem Internetangebot enthaltenen Informationen sorgfältig erstellt und sind laufende
                Überprüfung bemüht. Dennoch können wir für die Richtigkeit, Aktualität, Vollständigkeit und laufende
                Verfügbarkeit keine Gewähr übernehmen. Verbindliche Auskünfte, Beratungen, Empfehlungen oder Erklärungen
                sind wir ausschließlich im Rahmen individueller Kommunikation.
            </p>
            <p>
                Wir behalten uns vor, unser Internetangebot jederzeit zu ändern, zu ergänzen, zu kürzen oder ganz
                einzustellen. Wir stehen auch nicht dafür ein, dass Inhalte unseres Internetangebots für den Nutzer und
                seine Zwecke geeignet sind.
            </p>
            <p>
                Unser Angebot enthält auch Links zu Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für
                die Inhalte der verlinkten Seiten ist ausschließlich der jeweilige Anbieter der Seiten verantwortlich.
            </p>
            <h3>2. Abrufgebiet</h3>
            <p>
                Unser Internetangebot ist für das Gebiet der Bundesrepublik Deutschland bestimmt und auf der Grundlage
                der deutschen Rechtsordnung erstellt. Ein Abruf unseres Angebots von Gebieten außerhalb Deutschlands ist
                von uns nicht beabsichtigt. Wir übernehmen keine Haftung dafür, dass unser Internetangebot für Nutzer
                aus anderen Staaten geeignet, verwendbar und dort rechtlich zulässig ist.
            </p>
            <h3>3. Urheberrecht</h3>
            <p>
                Der Inhalt, die Gestaltung und der Aufbau unseres Internetangebots sind urheberrechtlich geschützt und
                wir behalten uns sämtliche Schutzrechte ausdrücklich vor. Insbesondere bedürfen die Vervielfältigung,
                Bearbeitung, Verbreitung und jede Art der Verwertung unserer schriftlichen Zustimmung, sofern die
                Maßnahme nicht nach gesetzlichen Vorschriften zustimmungsfrei erlaubt ist. Downloads und Kopien dieser
                Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
        </div>
        <div class="grid-1-3">
            <app-info-box headline="Ihr Kontakt zur astra Versicherung AG">
                <p class="big">0621 1247682-0</p>
                <a href="mailto:email@astra-versicherung.de" title="Mail an email@astra-versicherung.de"
                    class="button block margin-top small align-left">&raquo; Direkt zu Ihrem Ansprechpartner</a>
            </app-info-box>
        </div>
    </div>
</div>