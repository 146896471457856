import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    // redirects for obsolete products:
    {
        path: 'produkte/schueler-kombischutz-m',
        redirectTo: '/start',
        pathMatch: 'full',
    },
    {
        path: 'produkte/schueler-haftpflicht-und-sachschadenversicherung',
        redirectTo: '/start',
        pathMatch: 'full',
    },
    // modules:
    {
        path: 'portal',
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    },
    {
        path: 'produkte',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
    },
    // redirects for adword campaign:
    {
        path: 'schueler/instrumenten-versicherung',
        redirectTo: 'produkte/schueler-musikinstrumenten-versicherung',
        pathMatch: 'full',
    },
    {
        path: 'schueler/fahrrad-versicherung',
        redirectTo: 'produkte/schueler-fahrrad-versicherung',
        pathMatch: 'full',
    },
    {
        path: 'schueler/nachhilfe-versicherung',
        redirectTo: 'produkte/nachhilfe-plus',
        pathMatch: 'full',
    },
    // general redirects:
    {
        path: '',
        redirectTo: '/start',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/start',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

// All components routed via the (root) app router are declared here.
export const routingComponents = [];
