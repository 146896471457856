import { Pipe, PipeTransform } from '@angular/core';
import { products } from '../../config/products';

@Pipe({
    name: 'poolObjectDescription',
})
export class PoolObjectDescriptionPipe implements PipeTransform {
    transform(value: any, plural: boolean = false): any {
        if (typeof value !== 'string') return null;
        const product = products.find(item => item.insuranceTypeName.toUpperCase() == value.toUpperCase());
        if (product?.insuredObjectsForm) {
            return plural
                ? product.insuredObjectsForm.objectDescriptionPlural
                : product.insuredObjectsForm.objectDescriptionSingular;
        }
        return 'no matching product';
    }
}
