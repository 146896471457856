import { Pipe, PipeTransform } from '@angular/core';
/**
 *  @class ManglePipe
 *  @desc Use this pipe to obfuscate sensitive data on screen. Takes a string
 *    and replaces all but the first and last X letters by a specified symbol.
 *  @param {any} value the value to mangle. if not a string it will be returned as is.
 *  @param {number} clear how many letters at the beginning and end of the string shall be kept unobfuscated. dafaults to 4
 *  @param {string} replaceWith the symbol used to obfuscate the string. default is 'X'
 *
 *  @return {any} obfuscated string if input value was a string, else input value without changes.
 *
 *  @example
 *    {{ 'obfuscate' | mangle:2:'*' }} // output: ob*****te
 */
@Pipe({
    name: 'mangle'
})
export class ManglePipe implements PipeTransform {
    transform(value: any, clear: number = 4, replaceWith: string = 'X'): any {
        if (typeof value !== 'string') return value;
        if (value.length <= clear * 2) return value;
        let mangledValue: string = value.substr(0, clear);
        for (let i: number = clear; i < value.length - clear; i++, mangledValue += replaceWith) {}
        return (mangledValue += value.substr(-clear, clear));
    }
}
