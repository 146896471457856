import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { dtoToInsuranceType } from '../../mappers/insurance-type';
import { InsuranceTypeDto } from '../../models/insurance-type-dto';
import { InsuranceType } from '../../models/insurance-type';
import { HttpService } from '../http/http.service';
import { hex_md5 } from '../../utils/md5.function';
import { HttpParams } from '@angular/common/http';
import { UserDto } from '../../models/user-dto';
import { User } from '../../models/user';
import { dtoToUser } from '../../mappers/user';
import { Customer } from '../../models/customer';
import { customerToDto, dtoToCustomer } from '../../mappers/customer';
import { CustomerDto } from '../../models/customer-dto';
import { CityDto } from '../../models/city-dto';
import { TitleDto } from '../../models/title-dto';
import { Title } from '../../models/title';
import { Insurant } from '../../models/insurant';
import { insurantToDto } from '../../mappers/insurant';
import { InsurableObjectDto } from '../../models/insurable-object-dto';
import { OrderState } from '../../order/state/order.store';
import { orderStateToContractRequestDto } from '../../mappers/order';
import { InsuranceRank } from '../../models/insurance-rank';

const basePath = environment.basePath;

@Injectable({
    providedIn: 'root',
})
export class InsurenetInterfaceService {
    public constructor(private http: HttpService) {}

    //#region auth

    public login(email: string, password: string): Observable<User> {
        const params = new HttpParams().set('email', email).set('pw', hex_md5(password));
        return this.http
            .get<UserDto>(`${basePath}Login`, { params, notify: 'ERROR' })
            .pipe(map((userDto: UserDto) => dtoToUser(userDto)));
    }

    public requestPasswordReset(email: string, insuranceId: string): Observable<string> {
        const params = new HttpParams().set('email', email).set('InsuranceNo', insuranceId);
        return this.http.put<string>(`${basePath}ForgetPassword`, { params });
    }

    public confirmPasswordReset(token: string): Observable<void> {
        const params = new HttpParams().set('token', token);
        return this.http.put<void>(`${basePath}ConfirmForgetPassword`, { params });
    }

    //#endregion
    //#region customer

    // TODO: Check if this can be consolidated into one route
    public updateCustomer(customer: Customer): Observable<Customer> {
        const customerDto = customerToDto(customer);
        return this.http
            .put<CustomerDto>(`${basePath}CustomerPersonalData`, customerDto)
            .pipe(map(dto => dtoToCustomer(dto)));
    }

    public updateBankData(customer: Customer): Observable<Customer> {
        const customerDto = customerToDto(customer);
        return this.http
            .put<CustomerDto>(`${basePath}CustomerBankDetails`, customerDto)
            .pipe(map(dto => dtoToCustomer(dto)));
    }

    //#endregion

    public getInsuranceType(insuranceTypeName: string): Observable<InsuranceType> {
        return this.http
            .get<InsuranceTypeDto[]>(`${basePath}InsuranceTypes`, { notify: 'ERROR' })
            .pipe(
                switchMap(insuranceTypes => {
                    const insuranceType = insuranceTypes.find(item => item.Name === insuranceTypeName);
                    return insuranceType
                        ? of(insuranceType)
                        : throwError(new Error('insuranceType not found: ' + insuranceTypeName));
                }),
                map((insuranceType: InsuranceTypeDto) => dtoToInsuranceType(insuranceType))
            );
    }

    public getInsuranceTypes(): Observable<InsuranceType[]> {
        return this.http
            .get<InsuranceTypeDto[]>(`${basePath}InsuranceTypes`, { notify: 'ERROR' })
            .pipe(
                switchMap(insuranceTypes =>
                    from(insuranceTypes).pipe(
                        map(insuranceType => dtoToInsuranceType(insuranceType)),
                        toArray()
                    )
                )
            );
    }

    public getInsuranceRanks(insuranceTypeId: number): Observable<InsuranceRank[]> {
        return this.http.get<InsuranceRank[]>(`${basePath}InsuranceRanks/${insuranceTypeId}`, { notify: 'NONE' });
    }

    public getCity(zip: string): Observable<string> {
        const params = new HttpParams().set('zip', zip);
        return this.http
            .get<CityDto[]>(`${basePath}Cities`, { params, notify: 'NONE' })
            .pipe(
                map(cities => cities[0].City),
                catchError(err => EMPTY)
            );
    }

    public getTitles(): Observable<Title[]> {
        return this.http
            .get<TitleDto[]>(`${basePath}Titles`, { notify: 'ERROR' })
            .pipe(
                switchMap(titles =>
                    from(titles).pipe(
                        map(title => ({ id: title.Id, description: title.Description } as Title)),
                        toArray()
                    )
                )
            );
    }

    public getDiscount(discountCode: string, insuranceTypeId: number | string): Observable<number> {
        const params = new HttpParams().set('DiscountCode', discountCode);
        return this.http.get<number>(`${basePath}InsuranceTypes/${insuranceTypeId}/DiscountFactor`, {
            params,
            notify: 'NONE',
        });
    }

    public getPriceByInsurants(insuranceTypeId: number | string, insurants: Insurant[]): Observable<number> {
        return from(insurants).pipe(
            map(insurant => insurantToDto(insurant)),
            toArray(),
            switchMap(insurantDtos =>
                this.http.post<number>(
                    `${basePath}InsuranceTypes/${insuranceTypeId}/InsurancePriceByInsurants`,
                    insurantDtos,
                    {
                        notify: 'NONE',
                    }
                )
            )
        );
    }

    public getPrice(state: OrderState): Observable<number> {
        return this.http.post<number>(`${basePath}InsurancePrice`, orderStateToContractRequestDto(state), {
            notify: 'NONE',
            spin: false,
        });
    }

    public getInsurableObjects(insuranceTypeId: number | string): Observable<{ label: string | number; value: any }[]> {
        return this.http
            .get<InsurableObjectDto[]>(`${basePath}InsuranceObjects/${insuranceTypeId}`, { notify: 'ERROR' })
            .pipe(
                switchMap(insurableObjectDtos =>
                    from(insurableObjectDtos).pipe(
                        map(dto => {
                            return { label: dto.Description, value: dto.Id };
                        }),
                        toArray()
                    )
                )
            );
    }

    public submitContractRequest(state: OrderState): Observable<unknown> {
        return this.http.post<unknown>(`${basePath}ContractRequests`, orderStateToContractRequestDto(state));
    }
}
