export interface IInsurant {
    Id?: number;
    Firstname: string;
    Lastname: string;
    DateOfBirth: string;
    InsuranceRankId: number | null;
}

export class Insurant {
    public Id: number = 0;
    public Firstname: string;
    public Lastname: string;
    public DateOfBirth: string;
    public IsInsuree: boolean = false;
    public IsChild: boolean = false;
    public InsuranceRankId: number | null = null;
    public get fullName(): string {
        return `${this.Firstname ? this.Firstname + ' ' : ''}${this.Lastname ? this.Lastname : ''}`;
    }
}
