import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-person', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
};

const presentation: ProductPresentation = {
    description: `
        <p><strong></strong>Schüler-Musikinstrumenten-Versicherung</strong></p>
        <p>Versichert durch die astra Versicherung AG</p>
        <p><strong>Nur 9,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr! Die Schüler-Musikinstrumenten-Versicherung: Der Rundumschutz für wertvolle Instrumente bei Verlust sowie Schäden durch Transport, Brand, Blitz oder Wasser etc.</strong></p>
        <p>Instrumente, die Schüler mit zur Schule oder in den Musikunterricht nehmen, sind besonderen Gefahren ausgesetzt. Durch die Mitnahme in Bus oder Bahn oder durch den Transport zu Fuß können Beschädigungen entstehen.</p>
        <p>Diese Risiken deckt die Schüler-Musikinstrumenten-Versicherung komplett ab – ebenso Schäden durch Herunterfallen oder Umstoßen und selbstverständlich auch bei Schäden durch Dritte und Verlust durch Diebstahl.</p>
        <p><strong>Sichern auch Sie Ihr Instrument ab – der Rundum-Schutz für Instrumente der astra Versicherung AG für nur 9,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr</strong></p>
    `,
    bulletPoints: [
        'Der Rundumschutz für Schüler-Instrumente',
        'Schutz bei Diebstahl',
        'Schutz bei Transportschäden',
        'Schutz bei Brand-, Blitz- oder Wasserschäden',
    ],
    imageUrl: 'assets/img/product-headers/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schueler.jpg',
    imageAlt:
        'astra Musikinstrumentenversicherung: Der Schutz für Schüler-Instrumente inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!',
    imageHeadline:
        '<h3>Der Schutz für Schüler-Instrumente</h3><p class="no-margin-bottom">inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!</p>',
    docIPIDUrl: 'astra_IPID_Musikinstrumente.pdf',
    docAVBUrl: 'astra_AVB_Musikinstrumente.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente.pdf',
};

export const musikinstrumentenversicherungSchueler: Product = new Product({
    slug: 'musikinstrumentenversicherung-schueler',
    title: 'Musikinstrumentenversicherung für Schüler',
    fullTitle: 'Musikinstrumentenversicherung für Schüler',
    insuranceTypeName: 'Musik Schüler',
    config,
    presentation,
});
