import { Validators } from '@angular/forms';
import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation, Variants } from '../../models/product';
import { CommonValidators } from '../../shared/validators/common-validators';
import { regexes } from '../../utils/regexes.const';

const config: ProductConfig = {
    steps: ['', 'versicherte-objekte', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: true,
    showPriceFrom: true,
    showDiscountInput: true,
    showB2BInfo: true,
    naturalPersonsOnly: false,
    showBulkServiceInfo: true,
};

const presentation: ProductPresentation = {
    description: `
            <p><strong class="blue">Der umfassende Versicherungsschutz für Schulen, Lehrer und Schüler oder die ganze Klasse für einzelne bzw. beliebig viele elektronische Geräte – inkl. Leihgeräten und Geräten von Lehrern mit hoher Nutzungsfrequenz. Inkl. Plus-Schutz bei Schäden durch Transport oder Verwendung zuhause!</strong></p>
            <p>Ob Sie ein einzelnes Gerät versichern möchten, das in Ihrem Besitz ist, oder eines, das Ihnen leihweise von der Schule überlassen wurde, ob Sie Schulträger oder Förderverein sind und gleich mehrere Geräte gut geschützt wissen wollen – dann ist die speziell für digitale Schulen, Lehrer und Schüler konzipierte Tablet- und Notebook-Versicherung „Tablet PLUS“ genau das Richtige für Sie.</p>
            <p>Zum günstigen Tarif umfassend gegen alle denkbaren Schäden <strong>im schulischen Umfeld, beim Transport, zuhause und bei sonstiger privater Nutzung</strong> geschützt: Neben dem klassischen Versicherungsschutz bei Brand, Blitzschlag, Explosion oder Wasserschäden deckt die Tablet- und Notebook-Versicherung „Tablet PLUS“ von astra nämlich auch wahrscheinlichere Schäden wie z.B. bei Verlust, Diebstahl und Herunterfallen in der Schule, beim Transport und sogar zuhause ab.</p>
            <p><strong class="blue">Schnell und einfach zum Vertrag - ab nur 1,70&nbsp;€ im Monat pro Notebook oder Tablet.<br>
            Der Versicherungsschutz „Tablet Plus“ für Schulen, Lehrer, Schüler und digitale Klassen.</strong></p>
        `,
    bulletPoints: [
        'Alle Risiken innerhalb der Schule, bei häuslichen Lerngruppen und privater Nutzung sind abgedeckt',
        'Deckung bei Totalschaden, Transportschäden, Verlust, Diebstahl, Herunterfallen u.v.m.',
        'Schutz auch für Lehrergeräte oder Leihgeräte mit hoher Nutzungsfrequenz',
        'Versicherungsschutz für einzelne oder beliebig viele Endgeräte',
    ],
    imageUrl: 'assets/img/product-headers/astra-Versicherung_Tabletversicherung-PLUS.jpg',
    imageAlt:
        'astra Versicherung „Tablet PLUS“: Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND zuhause.',
    imageHeadline:
        '<h3>„Tablet PLUS“:</h3><p class="no-margin-bottom">Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND bei privater Nutzung.</p>',
    docIPIDUrl: 'astra_IPID_Tablet_Plus.pdf',
    docAVBUrl: 'astra_AVB_Tablet_Plus.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Tablet_Plus.pdf',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Angaben zu versicherten Geräten',
    description: `<p>Bitte machen Sie einige Angaben, zu dem/den Gerät/en, die Sie versichern möchten.
        Sie können jederzeit ein weiteres Gerät hinzufügen, in dem Sie auf die Schaltfläche "Weiteres Gerät hinzufügen" klicken.
        Der Beitrag Ihrer Versicherung bemisst sich nach Anzahl und Neupreis der versicherten Geräte.</p>`,
    objectDescriptionSingular: 'Gerät',
    objectDescriptionPlural: 'Geräte',
    max: 999,
    addButtonLabel: 'Weiteres Gerät hinzufügen',
    note: `<strong>Hinweis:</strong> Sollte Ihnen die Seriennummer noch nicht bekannt sein, haben Sie die
        Möglichkeit diese nach erfolgtem Abschluss im LogIn-Bereich jederzeit zu ergänzen. Bitte beachten
        Sie, dass der Versicherungsschutz erst ab Ergänzung der Seriennummer/n besteht.`,
    controls: [
        {
            name: 'description',
            label: 'Hersteller',
            type: 'text',
            required: true,
            validators: CommonValidators.something,
        },
        {
            name: 'value',
            label: 'Kaufpreis',
            type: 'value',
            required: true,
            validators: [Validators.required, Validators.pattern(regexes.valueTillThousandfivehundred)],
        },
        {
            name: 'detail',
            label: 'Seriennummer',
            type: 'text',
        },
    ],
};

const variants: Variants = {
    label: 'Wählen Sie den Abrechnungszeitraum:',
    options: [
        {
            productSlug: 'tablet-versicherung-plus',
            title: 'monatlich',
            isSelf: false,
        },
        {
            productSlug: 'tablet-versicherung-plus-j',
            title: 'jährlich',
            isSelf: true,
        },
    ],
    showOn: 'productInfo',
    keepOnToggle: [
        'billingAddress',
        'completedSteps',
        'currentStep',
        'contract',
        'payment',
        'insuredObjects',
        'resellerUrl',
        'additionalResellerData',
    ],
    removeCompletedSteps: [],
};

export const tabletVersicherungPlusJ: Product = new Product({
    slug: 'tablet-versicherung-plus-j',
    title: 'Tabletversicherung Plus',
    fullTitle: 'Tablet- und Notebook-Versicherung "Tablet PLUS"',
    insuranceTypeName: 'Tablet Plus (J)',
    config,
    presentation,
    insuredObjectsForm,
    variants,
});
