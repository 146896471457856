import { Injectable } from '@angular/core';
import { City, Country } from './asorted.model';
import { HttpService } from './http/http.service';
import { environment } from '../../environments/environment';
import { catchError, filter, first, map, tap } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { HttpParams } from '@angular/common/http';
import { from } from 'rxjs/internal/observable/from';
import { Observable } from 'rxjs/internal/Observable';
import { plainToClass } from 'class-transformer';
import { of } from 'rxjs/internal/observable/of';
import { InsuranceType } from './contract/insuranceType.model';
import { Title } from './../models/title';
import { TitleDto } from '../models/title-dto';

const CITY_URL = environment.basePath + environment.cities;
const INSURANCE_TYPES_URL = environment.basePath + environment.insuranceTypes;
const COUNTRIES_URL = environment.basePath + environment.countries;
const TITLES_URL = environment.basePath + environment.titles;
const DOCUMENT_TYPES_URL = environment.basePath + environment.documentTypes;

@Injectable()
export class HelperService {
    private _countries: Country[] = [];
    private _personTitles: Title[] = [];
    private _insuranceTypes: InsuranceType[] = [];
    private _documentTypes: any[] = [];
    private citiesCache: City[] = [];

    constructor(private http: HttpService, private log: LoggerService) {}

    public get countries(): Observable<Country[]> {
        if (this._countries.length === 0) {
            return this.getCountries();
        } else {
            return of(this._countries);
        }
    }

    public get personTitles(): Observable<Title[]> {
        if (this._personTitles.length === 0) {
            return this.getPersonTitles();
        } else {
            return of(this._personTitles);
        }
    }

    public get insuranceTypes(): Observable<InsuranceType[]> {
        if (this._insuranceTypes.length === 0) {
            return this.getInsuranceTypes();
        } else {
            return of(this._insuranceTypes);
        }
    }

    public get documentTypes(): Observable<any[]> {
        if (this._documentTypes.length === 0) {
            return this.getDocumentTypes();
        } else {
            return of(this._documentTypes);
        }
    }

    /**
     * Returns an observable containing a City from cache. If not found, XHRs to get it.
     * So in the component you can subscribe to this and get the value from cache or from
     * LockersNet transparently.
     *
     * @todo Untested, but the idea to use observables seemed so nice ...
     *
     * @param {string} zip
     * @returns {Observable<City>}
     */
    public getCity(zip: string): Observable<City> {
        // emits the City[] as a sequence of City-objects.
        return from(this.citiesCache).pipe(
            // returns the first matching City or throws Error, if stream completes.
            first(city => city.Zip === zip),
            // catches the Error and calls out to LockersNet to get the city and add it to the cache.
            catchError(() => {
                const params = new HttpParams().set('zip', zip);
                return this.http
                    .get<City[]>(CITY_URL, { params, retryCount: 2, notify: 'NONE', spin: false })
                    .pipe(
                        filter(res => res != null),
                        map((city: City[]) => city[0]),
                        tap((city: City) => this.citiesCache.push(plainToClass(City, <City>city)))
                    );
            })
        );
    }

    private getCountries(): Observable<Country[]> {
        return this.http
            .get<Country[]>(COUNTRIES_URL, { retryCount: 2, notify: 'NONE', spin: false })
            .pipe(
                filter(val => val !== null),
                map(countries => countries.map(country => plainToClass(Country, country))),
                tap(countries => (this._countries = countries))
            );
    }

    private getPersonTitles(): Observable<Title[]> {
        return this.http
            .get<TitleDto[]>(TITLES_URL, { retryCount: 2, notify: 'NONE', spin: false })
            .pipe(
                filter(val => val !== null),
                map(dtos =>
                    dtos.map(dto => {
                        return { id: dto.Id, description: dto.Description } as Title;
                    })
                ),
                tap(titles => (this._personTitles = titles))
            );
    }

    private getInsuranceTypes(): Observable<InsuranceType[]> {
        return this.http
            .get<InsuranceType[]>(INSURANCE_TYPES_URL, { retryCount: 2, notify: 'NONE', spin: false })
            .pipe(
                filter(val => val !== null),
                map(types => types.map(type => plainToClass(InsuranceType, type))),
                tap(types => (this._insuranceTypes = types))
            );
    }

    private getDocumentTypes(): Observable<any[]> {
        return this.http
            .get<any[]>(DOCUMENT_TYPES_URL, { retryCount: 2, notify: 'NONE', spin: false })
            .pipe(tap(val => (this._documentTypes = val)));
    }
}
