import { NavigationLink } from '../core/header/navigationLinks.model';
import { NavigationLinkCategory } from '../core/header/navigationLinkCategory.model';

export const navigation: NavigationLinkCategory[] = [
    new NavigationLinkCategory(1, 'Reise und Ausland', [
        new NavigationLink('Reise-Auslandskrankenversicherung Single', [
            '/',
            'produkte',
            'reise-auslandskrankenversicherung',
        ]),
        new NavigationLink('Reise-Auslandskrankenversicherung Paare', [
            '/',
            'produkte',
            'reise-auslandskrankenversicherung-paare',
        ]),
        new NavigationLink('Reise-Auslandskrankenversicherung Familie', [
            '/',
            'produkte',
            'reise-auslandskrankenversicherung-familie',
        ]),
        new NavigationLink('Schutzbrief Students', ['/', 'produkte', 'schutzbrief-students']),
    ]),
    new NavigationLinkCategory(3, 'Für die Familie', [
        new NavigationLink('Zahnzusatzversicherung', ['/', 'produkte', 'zahnzusatz2022']),
        new NavigationLink('Reise-Auslandskrankenversicherung Familie', [
            '/',
            'produkte',
            'reise-auslandskrankenversicherung-familie',
        ]),
    ]),
    new NavigationLinkCategory(5, 'Musikinstrumente', [
        new NavigationLink('Musikinstrumentenversicherung für Schüler', [
            '/',
            'produkte',
            'musikinstrumentenversicherung-schueler',
        ]),
        new NavigationLink('Musikinstrumentenversicherung für Schulen', [
            '/',
            'produkte',
            'musikinstrumentenversicherung-schulen-p',
        ]),
        new NavigationLink('Musikinstrumentenversicherung Premium', ['/', 'produkte', 'musikinstrumentenversicherung']),
    ]),
    new NavigationLinkCategory(7, 'Schüler-Versicherungen', [
        new NavigationLink('Schüler-Fahrradversicherung', ['/', 'produkte', 'schueler-fahrrad-versicherung']),
        new NavigationLink('Musikinstrumentenversicherung für Schüler', [
            '/',
            'produkte',
            'musikinstrumentenversicherung-schueler',
        ]),
        new NavigationLink('Musikinstrumentenversicherung für Schulen', [
            '/',
            'produkte',
            'musikinstrumentenversicherung-schulen-p',
        ]),
        new NavigationLink('Schüler-Unfallversicherung', ['/', 'produkte', 'schueler-unfallversicherung']),
        new NavigationLink('Schüler-Auslandsversicherung', ['/', 'produkte', 'schueler-auslandskrankenversicherung']),
        new NavigationLink('Nachhilfeversicherung Plus', ['/', 'produkte', 'nachhilfe-plus']),
        new NavigationLink('Tabletversicherung für die Schule', ['/', 'produkte', 'tablet-versicherung-plus']),
    ]),
    new NavigationLinkCategory(9, 'Für Elektronikgeräte', [
        new NavigationLink('Tabletversicherung für die Schule', ['/', 'produkte', 'tablet-versicherung-plus']),
        new NavigationLink('Elektronikversicherung', ['/', 'produkte', 'elektronikversicherung']),
        new NavigationLink('Handyversicherung', ['/', 'produkte', 'handyversicherung']),
    ]),
];
