import { InsuredObject } from '../models/insured-object';
import { InsuredObjectDto } from '../models/insured-object-dto';

export function insuredObjectToDto(object: InsuredObject): InsuredObjectDto {
    return {
        Id: object.id,
        ObjectDate: object.date,
        ObjectDescription: object.description,
        ObjectDetail: object.detail,
        ObjectValue: object.value,
        Person: object.person,
        ObjectModel: object.model,
        ObjectType: object.type,
    };
}
