import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

const allowedModes = ['default', 'conrad', 'none'] as const;
export type HeaderMode = typeof allowedModes[number];
type HeaderModeSetter = 'product' | 'queryParam' | 'none';

@Injectable({
    providedIn: 'root',
})
export class HeaderModeService {
    private _headerMode$ = new BehaviorSubject<{ mode: HeaderMode; setBy: HeaderModeSetter }>({
        mode: 'default',
        setBy: 'none',
    });
    public headerMode$: Observable<HeaderMode> = this._headerMode$.pipe(pluck('mode'));

    constructor() {}

    /**
     * @description get the current header mode
     */
    public get mode(): HeaderMode {
        return this._headerMode$.getValue().mode;
    }

    /**
     * @description set header mode. Use setBy to state the source of
     *  the mode change (product configuration or route query param).
     *  query params have priority and will not be overridden by product
     *  settings.
     * @param {HeaderMode} mode the header mode to set. 'default' shows
     *  normal header with navigation. 'conrad' will show conrad logo and
     *  hide navigation/portal button. 'none' will hide header completely.
     * @param {HeaderModeSetter} setBy the source of the header mode to be
     *  set. Modes set by queryParams have precedence over over those set
     *  by other sources.
     */
    public setHeaderMode(mode: HeaderMode, setBy: HeaderModeSetter): void {
        if (
            (setBy === 'queryParam' || this._headerMode$.getValue().setBy !== 'queryParam') &&
            allowedModes.includes(mode)
        ) {
            this._headerMode$.next({ mode, setBy });
        }
    }
}
