<app-hero
    imagePath="/assets/img/product-headers/astra-Versicherungen-Innovativ-und-unkompliziert.jpg"
    imageAlt="Innovativ und unkompliziert"
    imageAlign="top"
></app-hero>
<div class="content-container double-gutter-vertical">
    <div class="grid-container">
        <div class="grid-1-1 align-center double-gutter-bottom">
            <h1 class="align-center">
                Innovativ und unkompliziert -<br />mit nur wenigen Klicks zum idealen Versicherungsschutz für jeden
                Bereich
            </h1>
        </div>
        <div class="grid-2-3">
            <h3>
                Mit über 900.000 zufriedenen Kunden der astradirect Unternehmensgruppe ist die astra Versicherung AG der
                günstige Online-Spezialanbieter mit attraktiven Versicherungslösungen für Privatpersonen in nahezu allen
                Bereichen.
            </h3>
            <p>
                Durch innovative Versicherungsformen bieten wir auf die Bedürfnisse von Verbrauchern exakt
                zugeschnittene Versicherungskonzepte an: vom Rundum-Versicherungsschutz für Smartphones, Tablets,
                Notebooks und sonstige elektronischen Geräten, Garantieverlängerungs-Versicherungen oder individuelle
                Versicherungen für Produkte, Waren und Wertgegenstände bis hin zu Unfall- und Auslandskrankenschutz mit
                besonders attraktiven Prämien.
            </p>
            <p>
                Wenn Sie Interesse an einem optimalen Versicherungsschutz haben, freuen wir uns über Ihre
                Kontaktaufnahme!
            </p>
        </div>
        <div class="grid-1-3">
            <app-info-box headline="Ihr Kontakt zur astra Versicherung AG">
                <p class="big">0621 1247682-0</p>
                <a
                    href="mailto:email@astra-versicherung.de"
                    title="Mail an email@astra-versicherung.de"
                    class="button block margin-top small align-left"
                    >&raquo; Direkt zu Ihrem Ansprechpartner</a
                >
            </app-info-box>
        </div>
    </div>
</div>
