import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

// NOTE: Device types and lens manufacturers are definned in conrad-b2c!

const config: ProductConfig = {
    steps: ['', 'versichertes-geraet', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    requiresNoPriorDefect: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    hideSummarySidebarOnLandingPage: true,
    headerMode: 'default',
};

const presentation: ProductPresentation = {
    description: `
        <p class="semibold">
            Schützen Sie Ihren treuen Begleiter und Helfer im Alltag mit der astra Handyversicherung.
        </p>
        <p>
            Sie können sich Ihr Leben ohne Smartphone nicht mehr vorstellen und haben den Alleskönner in jeder Situation parat?
            Umso größer ist natürlich die Gefahr, dass das Display durch einen Sturz kaputt geht oder das Handy gestohlen wird. 
            Mit unserer Handyversicherung für Ihr iPhone, Samsung und andere Marken jeglicher Art sichern Sie sich ab und müssen 
            für kleines Geld im Schadensfall nicht wieder auf Ihr angespartes zurückgreifen.
        </p>
    `,
    bulletPoints: [],
    imageUrl: 'assets/img/product-headers/astra-Versicherung-Handyversicherung.jpg',
    imageAlt: 'Handy ohne Selbstbeteiligung versichern',
    imageHeadline: '<h3 class="no-margin-bottom">Handy inkl. Diebstahlschutz versichern</h3>',
    docIPIDUrl: 'astra_IPID_Elektronik_Endkunden_2022.pdf',
    docAVBUrl: 'astra_AVB_Elektronik_Endkunden_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Elektronik_Endkunden_2022.pdf',
    //pageTitle: 'Seitentitel (optional)',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Zu versicherndes Instrument',
    description: '',
    objectDescriptionSingular: 'Gerät',
    max: 1,
    controls: [
        {
            name: 'description',
            label: 'Hersteller',
            type: 'text',
        },
        {
            name: 'model',
            label: 'Modell',
            type: 'text',
        },
        {
            name: 'value',
            label: 'Kaufpreis',
            type: 'value',
        },
        {
            name: 'detail',
            label: 'IMEI',
            type: 'text',
        },
    ],
};

export const handyversicherung: Product = new Product({
    slug: 'handyversicherung',
    title: 'Handyversicherung',
    insuranceTypeName: 'Elektronikversicherung B2C',
    config,
    presentation,
    insuredObjectsForm,
});
