import { AbstractControl, ValidatorFn } from '@angular/forms';
import { endOfDay, isAfter, isBefore, isSameDay, isValid, startOfDay } from 'date-fns';
import { regexes } from '../../utils/regexes.const';

interface PickerDate {
    day: number;
    month: number;
    year: number;
}

export function validateBirthday(minAge: number = -1, maxAge: number = -1, delimiter: string = '.') {
    return (control: AbstractControl): null | { [key: string]: boolean } => {
        if (
            control.value === null ||
            typeof control.value === 'undefined' ||
            !/^[0-9]{2}.[0-9]{2}.[1,2][0-9]{3}$/.test(control.value)
        ) {
            return { noDate: true };
        }
        const date: PickerDate = {
            day: Number(control.value.split(delimiter)[0]),
            month: Number(control.value.split(delimiter)[1]),
            year: Number(control.value.split(delimiter)[2]),
        };
        if (!checkForMaxAge(date, maxAge)) {
            return { ageToHigh: true };
        } else if (!checkForMinAge(date, minAge)) {
            return { ageToLow: true };
        } else {
            return null;
        }
    };
}

function checkForMaxAge(date: PickerDate, maxAge: number): boolean {
    if (maxAge === -1) {
        return true;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (today.getFullYear() - date.year === maxAge) {
        return new Date(today.getFullYear(), date.month - 1, date.day).getTime() >= today.getTime();
    } else {
        return today.getFullYear() - date.year < maxAge;
    }
}

function checkForMinAge(date: PickerDate, minAge: number): boolean {
    if (minAge === -1) {
        return true;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (today.getFullYear() - date.year === minAge) {
        return new Date(today.getFullYear(), date.month - 1, date.day).getTime() <= today.getTime();
    } else {
        return today.getFullYear() - date.year > minAge;
    }
}

export function validateInsureNetDateFormat(date: string): null | { [key: string]: boolean } {
    return typeof date !== 'string' || !regexes.insureNetDateFormat.test(date) ? { invalidDateFormat: true } : null;
}

export function validateDaysInDate(date: string): null | { [key: string]: boolean } {
    const [day, month, year] = date.split('.').map(value => Number(value));
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
        return { dateComponentIsNaN: true };
    }
    if (day > 0) {
        if (month === 2) {
            if ((isLeapYear(year) && day < 30) || day < 29) {
                return null;
            }
        } else if (([1, 3, 5, 7, 8, 10, 12].includes(month) && day < 32) || day < 31) {
            return null;
        }
    }
    return { invalidDay: true };
}

export function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function dateBefore(endDate: Date, inclusive: boolean = true): ValidatorFn {
    return (control: AbstractControl): null | { [key: string]: boolean } => {
        const err = validateInsureNetDateFormat(control.value) || validateDaysInDate(control.value);
        if (err !== null) return err;
        const d = new Date(
            (control.value + '')
                .split('.')
                .reverse()
                .join('-')
        );
        if (isValid(d)) {
            if (inclusive) {
                return isBefore(d, endDate) || isSameDay(d, endDate) ? null : { dateToLate: true };
            } else {
                return isBefore(d, endDate) ? null : { dateToLate: true };
            }
        } else {
            return { invalidDateFormat: true };
        }
    };
}

export function dateAfter(startDate: Date, inclusive: boolean = true): ValidatorFn {
    return (control: AbstractControl): null | { [key: string]: boolean } => {
        const err = validateInsureNetDateFormat(control.value) || validateDaysInDate(control.value);
        if (err !== null) return err;
        const d = new Date(
            (control.value + '')
                .split('.')
                .reverse()
                .join('-')
        );
        if (isValid(d)) {
            if (inclusive) {
                return isAfter(d, startDate) || isSameDay(d, startDate) ? null : { dateToEarly: true };
            } else {
                return isAfter(d, startDate) ? null : { dateToLate: true };
            }
        } else {
            return { invalidDateFormat: true };
        }
    };
}

export function dateBetween(startDate: Date, endDate: Date): ValidatorFn {
    return (control: AbstractControl): null | { [key: string]: boolean } => {
        const err = validateInsureNetDateFormat(control.value) || validateDaysInDate(control.value);
        if (err !== null) return err;
        const d = new Date(
            (control.value + '')
                .split('.')
                .reverse()
                .join('-')
        );
        if (isValid(d)) {
            if (isAfter(d, endOfDay(endDate))) {
                return { dateToLate: true };
            } else if (isBefore(d, startOfDay(startDate))) {
                return { dateToEarly: true };
            }
            return null;
        } else {
            return { invalidDateFormat: true };
        }
    };
}
