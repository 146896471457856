import { Component, Input, TemplateRef } from '@angular/core';

/**
 * @description Expandable content container with headline.
 *  Use this component for an entry in a faq list.
 * @argument {TemplateRef<any>|string|undefined} headline Headline content. Can be either a string or TemplateRef.
 * @example usage with ng-template (use when html-entities or inline elements need to be rendered):
 * ```html
 *  <ng-template #faqHeadline>This is the headline</ng-template>
 *  <app-faq [headline]="faqHeadline">
 *      This is the content
 *  </app-faq>
 * ```
 * @example usage with string input
 * ```html
 *  <app-faq headline="This is the headline">
 *      This is the content
 *  </app-faq>
 * ```
 */
@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
    @Input()
    public headline: TemplateRef<any> | string | undefined;

    public open = false;

    public get isTemplate(): boolean {
        return typeof this.headline !== 'string' && typeof this.headline !== 'undefined';
    }
}
