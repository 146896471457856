import { InsurantsFormConfig } from '../../models/insurants-form-config';
import { Product, ProductConfig, ProductPresentation, Variants } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-personen', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Steuerfrei nach § 4 Abs. 1 Nr. 5 VersStG',
    requiresMedicalData: true,
    insurantMaxAge: 60,
};

const presentation: ProductPresentation = {
    description: `
            <p><strong>Reise-Auslandskrankenversicherung für die ganze Familie:</strong></p>
            <p>Die günstige Auslandskrankenversicherung von astra für bis zu zwei Erwachsene und 5 im Haushalt lebende Kinder unter 18 Jahre.</p>
            <p>Die astra-Auslandskrankenversicherung – gut versichert im Familienurlaub, gut versichert auf Bildungsreisen mit der Familie.</p>
            <p>Wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
            <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
            <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel.</p>
            <ul>
                <li>Weltweiter Versicherungsschutz vor unvorhergesehen Krankheitskosten inkl. USA und Kanada</li>
                <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
                <li>Kostenerstattung auch für Überführung im Todesfall</li>
            </ul>
            <p>Die günstige Reise-Krankenversicherung für nur 21,<sup>00</sup> <span class="currency-sign">€</span>.</p>
        `,
    bulletPoints: [
        'Weltweiter Versicherungsschutz im Ausland bei unvorhergesehener Krankheit oder Unfall',
        'Inkl. Schutz bei Corona-Infektion',
        'Freie Arztwahl und freie Auswahl eines Krankenhauses',
        'Inklusive zahnärztlicher Behandlung',
        'Inklusive Kostenerstattung für Rücktransport',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherung_Reise_und_Auslandskrankenversicherung.jpg',
    imageAlt: 'astra Auslandsschutz - Auf Reisen gut versichert - Top abgesichert im Ausland',
    imageHeadline: `<h3>Auslandsschutz</h3>
        <p class="no-margin-bottom">Auf Reisen gut versichert<br>
        Top abgesichert im Ausland</p>`,
    docIPIDUrl: 'astra_IPID_AKV_EF_2022.pdf',
    docAVBUrl: 'astra_AVB_AKV_EF_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_AKV_EF_2022.pdf',
};

const insurantsForm: InsurantsFormConfig = {
    headline: 'Versicherte Personen',
    description:
        '<p>Bitte geben Sie hier die Daten des volljährigen Antragstellers an. Im weiteren Schritt können Sie Ihren Partner / Ihre Partnerin und bis zu 5 Kinder mitversichern, die mit Ihnen in häuslicher Gemeinschaft leben.</p>',
    requiresInsurantsFamilyRelationship: 2,
    insurants: [
        {
            category: 'insuree',
            displayName: 'Antragsteller',
            label: 'Antragsteller',
            minAge: 18,
            maxAge: 60,
            maxCount: 1,
            startWith: 'insuree',
        },
        {
            category: 'partner',
            displayName: 'Partner',
            label: 'Partner',
            minAge: 18,
            maxAge: 60,
            maxCount: 1,
            firstOneRemovable: true,
            addButtonLabel: 'Partner hinzufügen',
        },
        {
            category: 'kid',
            displayName: 'Kind',
            displayNameSummary: 'Kinder',
            headline: 'Mitversicherte Kinder',
            description:
                'Sie können bis zu fünf Kinder mitversichern. Fügen Sie ein Kind zur Versicherung hinzu, in dem Sie auf die Schaltfläche "Kind hinzufügen" klicken.',
            minAge: 0,
            maxAge: 18,
            maxCount: 5,
            firstOneRemovable: true,
            addButtonLabel: 'Kind hinzufügen',
        },
    ],
};

const variants: Variants = {
    label: 'Wählen Sie den Tarif:',
    options: [
        {
            productSlug: 'reise-auslandskrankenversicherung-familie',
            title: 'Familie',
            isSelf: true,
        },
        {
            productSlug: 'reise-auslandskrankenversicherung-paare',
            title: 'Paare',
            isSelf: false,
        },
        {
            productSlug: 'reise-auslandskrankenversicherung',
            title: 'Single',
            isSelf: false,
        },
    ],
    showOn: 'productInfo',
    keepOnToggle: [
        'billingAddress',
        'completedSteps',
        'currentStep',
        'contract',
        'payment',
        'additionalResellerData',
        'resellerUrl',
    ],
    removeCompletedSteps: ['versicherte-personen', 'versicherte-person'],
};

export const auslandsKvFamilie: Product = new Product({
    slug: 'reise-auslandskrankenversicherung-familie',
    title: 'Reise-Auslandskrankenversicherung Familie',
    fullTitle: 'Reise-Auslandskrankenversicherung für Familien',
    insuranceTypeName: 'AKV',
    config,
    presentation,
    insurantsForm,
    variants,
});
