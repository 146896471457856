<div class="cookiebox-dse-container grid-2-3" [innerHTML]="privacyStatementHtml"></div>
<div class="grid-1-3">
    <app-info-box headline="Wichtige Informationen zum Datenschutz">
        <a href="assets/documents/Informationsverzeichnis.pdf" target="_blank" rel="noreferrer noopener"
            title="Informationsverzeichnis (PDF)" class="button block small align-left">&raquo;
            Informationsverzeichnis</a>
        <a href="assets/documents/Dienstleisterliste.pdf" target="_blank" rel="noreferrer noopener"
            title="Dienstleisterliste (PDF)" class="button margin-top block small align-left">&raquo;
            Dienstleisterliste</a>
        <a href="assets/documents/Datenschutzhinweise.pdf" target="_blank" rel="noreferrer noopener"
            title="Datenschutzhinweise (PDF)" class="button margin-top block small align-left">&raquo;
            Datenschutzhinweise</a>
    </app-info-box>
</div>