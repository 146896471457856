<app-hero
    imagePath="/assets/img/product-headers/astra_Versicherung-Kontakt.jpg"
    imageAlt="astra Versicherung AG - Nehmen Sie Kontakt auf!"
    imageAlign="top"
></app-hero>
<div class="content-container double-gutter-vertical">
    <div class="grid-container">
        <div class="grid-1-1 align-center double-gutter-bottom">
            <h1 class="align-center">Kontakt</h1>
        </div>
        <div class="grid-2-3">
            <h3 class="double-margin-bottom">
                Sie haben Fragen oder Anregungen? Sie wünschen eine unverbindliche Beratung zu unseren
                Versicherungs­lösungen? Dann freuen wir uns über Ihre Nachricht an die astra Versicherung AG.
            </h3>
            <h2 class="no-margin-bottom">Formular</h2>
            <hr class="double-margin-bottom" />
            <div class="bg-light-grey-b gutter border-radius-medium double-margin-bottom" *ngIf="showSuccessMessage">
                <h4 class="green">
                    Ihre Anfrage wurde versendet.
                </h4>
                <p class="dark no-margin">
                    Wir werden diese so schnell wie möglich bearbeiten.
                </p>
            </div>
            <form [formGroup]="contactForm" (submit)="onSubmit()">
                <div class="grid-container">
                    <div class="grid-1-3">
                        <label for="fullname">Name, Vorname:</label>
                    </div>
                    <div class="grid-2-3">
                        <input type="text" id="fullname" placeholder="Name, Vorname" required formControlName="name" />
                    </div>
                    <div class="grid-1-3">
                        <label for="fullname">Straße, Hausnr.:</label>
                    </div>
                    <div class="grid-2-3">
                        <input
                            type="text"
                            id="fullname"
                            placeholder="Straße, Hausnr."
                            required
                            formControlName="address"
                        />
                    </div>
                    <div class="grid-1-3">
                        <label for="fullname">PLZ, Ort:</label>
                    </div>
                    <div class="grid-2-3">
                        <input type="text" id="fullname" placeholder="PLZ, Ort" required formControlName="city" />
                    </div>
                    <div class="grid-1-3">
                        <label for="fullname">Telefon:</label>
                    </div>
                    <div class="grid-2-3">
                        <input type="text" id="fullname" placeholder="Telefon" required formControlName="phone" />
                    </div>
                    <div class="grid-1-3">
                        <label for="fullname">E-Mail:</label>
                    </div>
                    <div class="grid-2-3">
                        <input type="text" id="fullname" placeholder="E-Mail" required formControlName="mail" />
                    </div>
                    <div class="grid-1-3">
                        <label for="fullname">Ihre Nachricht:</label>
                    </div>
                    <div class="grid-2-3">
                        <textarea id="fullname" placeholder="Ihre Nachricht" required formControlName="msg"></textarea>
                    </div>
                    <div class="grid-1-1">
                        <p>
                            Wir sind uns bewusst, dass Ihnen der Schutz Ihrer Privatsphäre bei der Benutzung unserer
                            Internetseiten ein wichtiges Anliegen ist. Wir nehmen den Schutz Ihrer personenbezogenen
                            Daten sehr ernst und erheben, verarbeiten und nutzen Ihre personenbezogenen Daten, die Sie
                            uns über das Kontaktformular mitteilen, ausschließlich in Übereinstimmung mit unserer
                            Datenschutzerklärung.
                        </p>
                        <p>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                            Formular zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
                            gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verwendung des
                            Kontaktformulars ist freiwillig. Die Einwilligung stellt somit eine Rechtsgrundlage gem.
                            Art. 6 Abs. 1 S. 1 lit. a DSGVO für die Verarbeitung der Daten dar. Mit dem Absenden des
                            Kontaktformulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung
                            Ihres Anliegens von uns verwendet werden (Widerrufshinweise finden Sie in der
                            Datenschutzerklärung).
                        </p>
                        <input type="checkbox" id="confirm-privacy" required formControlName="ConfirmPrivacy" />
                        <label for="confirm-privacy"
                            >Ich stimme den Datenschutz­bestimmungen der astra Versicherung AG zu.</label
                        >
                    </div>
                    <div class="grid-2-3">&nbsp;</div>
                    <div class="grid-1-3">
                        <button class="double-margin-vertical next" type="submit" [disabled]="!valid">Absenden</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="grid-1-3">
            <app-info-box headline="astra Versicherung AG">
                <p>Dudenstr. 46<br />68167 Mannheim</p>
                <p class="no-margin-bottom">
                    Telefon: +49 621 1247682-0<br />
                    Telefax: +49 621 1247682-642<br />
                    <a href="mailto:email@astra-versicherung.de">email@astra-versicherung.de</a>
                </p>
            </app-info-box>
        </div>
    </div>
</div>
