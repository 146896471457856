import { Component, OnInit, Input, OnDestroy, OnChanges, forwardRef, Injector } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { MONTHS } from '../../config/months';

interface PickerDate {
    year: string;
    month: string;
    day: string;
}

@Component({
    selector: 'app-birthday-picker',
    templateUrl: './birthday-picker.component.html',
    styleUrls: ['./birthday-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BirthdayPickerComponent),
            multi: true,
        },
    ],
})
export class BirthdayPickerComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    @Input()
    public startYear = 1900;
    @Input()
    public endYear = new Date().getFullYear();
    @Input()
    public delimiter = '.';
    @Input()
    public order: 'asc' | 'desc' = 'desc';

    public picker: FormGroup;
    public days: string[] = [];
    public years: string[] = [];
    public months: string[] = MONTHS.split(' ');

    private unsubscribe$: Subject<void> = new Subject<void>();
    private onChange: Function;
    private onTouched: Function;
    private control: FormControl;
    private controlSubscription: Subscription;

    constructor(private formBuilder: FormBuilder, private injector: Injector) {
        this.initForm();
    }

    public ngOnInit(): void {
        for (let i = 1; i <= 31; i++) {
            this.days.push(i < 10 ? '0' + i : '' + i);
        }
        this.ngOnChanges();
        this.subscribeToPickerChange();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        if (this.controlSubscription) {
            this.controlSubscription.unsubscribe();
        }
    }

    public ngOnChanges(): void {
        this.buildYearArray();
        setTimeout(() => {
            const ngControl = this.injector.get(NgControl, null);
            try {
                this.control = ngControl.control as FormControl;
                if (this.controlSubscription) {
                    this.controlSubscription.unsubscribe();
                }
                this.controlSubscription = this.subscribeToValidationChanges();
            } catch (err) {
                throw new Error('birthday picker is missing form control binding');
            }
        });
    }

    public writeValue(value: string | null): void {
        if (value === null) {
            value = this.delimiter + this.delimiter + this.delimiter;
        }
        const dateComponents = value.split(this.delimiter);
        this.setPickerValues(dateComponents[0] || null, dateComponents[1] || null, dateComponents[2] || null);
    }

    private setPickerValues(day: string | null, month: string | null, year: string | null) {
        this.picker.setValue({
            day,
            month,
            year,
        });
        this.checkForInvalidDays(this.picker.value);
        this.ngOnChanges();
        if (typeof this.onChange === 'function') {
            this.onChange();
        }
    }

    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    private buildYearArray() {
        let startYear = this.startYear,
            endYear = this.endYear;
        if (this.endYear < 1900) {
            endYear = new Date().getFullYear() + this.endYear;
        }
        if (this.startYear < 1900) {
            startYear = new Date().getFullYear() + this.startYear;
        }
        const min = Math.min(startYear, endYear);
        const max = Math.max(startYear, endYear);
        this.years = [];
        for (let i = max; i >= min; i--) {
            this.years.push(i + '');
        }
        if (this.order === 'asc') {
            this.years.reverse();
        }
    }

    /**
     * @function subscribeToPickerChange
     * @desc handles changes to the picker inputs. If they are valid, update the parent form
     *      control and mark it as dirty.
     */
    private subscribeToPickerChange(): void {
        this.picker.valueChanges
            .pipe(
                filter((date: PickerDate) => this.checkForInvalidDays(date) && date.day !== null && date.year !== null),
                tap(() => {
                    if (typeof this.onTouched === 'function') {
                        this.onTouched();
                    }
                }),
                map(val => [val.day, val.month, val.year].join(this.delimiter)),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((pickedDate: string) => {
                if (typeof this.onChange === 'function') {
                    this.onChange(pickedDate);
                }
            });
    }

    /**
     * @function checkForInvalidDays
     * @desc check if date picker is set to a day that is not valid (i.e. 31st of Feb.). Also sets the number of days
     *      for the given year and month.
     * @param {any} date date picker values as an object in the format { days: string, month: string, year: string }
     * @return {boolean} true, if the picked day is valid, else false.
     */
    private checkForInvalidDays(date: PickerDate): boolean {
        if (date.month === null || date.day === null) {
            return false;
        }
        if ('01;03;05;07;08;10;12'.split(';').indexOf(date.month) !== -1) {
            this.setNumberOfDays(31);
        } else {
            if (date.month === '02') {
                // check for leap year:
                if ((Number(date.year) % 4 === 0 && Number(date.year) % 100 !== 0) || Number(date.year) % 400 === 0) {
                    this.setNumberOfDays(29);
                } else {
                    this.setNumberOfDays(28);
                }
            } else {
                this.setNumberOfDays(30);
            }
        }
        if (this.days.indexOf(date.day) === -1) {
            this.picker.get('day').setValue(this.days[this.days.length - 1]);
            return false;
        }
        return true;
    }

    /**
     * @function setNumberOfDays
     * @desc set the array of days to a certain length
     * @param {number} numberOfDays the length for the array of days
     */
    private setNumberOfDays(numberOfDays: number): void {
        this.days.splice(numberOfDays, 4);
        while (this.days.length < numberOfDays) {
            this.days.push(this.days.length + 1 + '');
        }
    }

    private initForm(): void {
        this.picker = this.formBuilder.group({
            day: null,
            month: null,
            year: null,
        });
    }

    private subscribeToValidationChanges(): Subscription {
        return this.control.statusChanges.subscribe(status => {
            if (status === 'INVALID') {
                const errors = { validation_failed: true };
                this.picker.get('year').setErrors(errors);
                this.picker.get('month').setErrors(errors);
                this.picker.get('day').setErrors(errors);
            } else {
                this.picker.get('year').setErrors(null);
                this.picker.get('month').setErrors(null);
                this.picker.get('day').setErrors(null);
            }
            if (this.control.touched) {
                this.picker.markAllAsTouched();
            }
        });
    }
}
