type CookieCollection = { [key: string]: string };

export default class CookieManager {
    private _cookies: CookieCollection = {};
    private _consentCookieName: string;

    constructor(consentCookieName: string = 'cookiesAllowed') {
        this._consentCookieName = consentCookieName;
        this.refresh();
    }

    //#region general cookie handling

    public refresh(): CookieManager {
        this._cookies = {};
        const rawCookies = document.cookie.split(';');
        rawCookies.forEach(item => {
            if (item.length && item.split('=')[1]) {
                this._cookies[item.split('=')[0].trim()] = item.split('=')[1].trim();
            }
        });
        return this;
    }

    get cookies(): CookieCollection {
        return this.refresh()._cookies;
    }

    public getValue(key: string): string | null {
        const currentCookies = this.refresh().cookies;
        if (key in currentCookies) {
            return currentCookies[key];
        } else {
            return null;
        }
    }

    public setCookie(key: string, value: string): CookieManager {
        document.cookie = `${key}=${value};`;
        return this.refresh();
    }

    public clearCookie(key: string, norefresh: boolean = false): CookieManager {
        const currentCookies = this.refresh().cookies;
        if (key in currentCookies) {
            document.cookie = `${key}=${currentCookies[key]};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        }
        if (norefresh) {
            return this;
        }
        return this.refresh();
    }

    public clearAll(): CookieManager {
        const currentCookies = this.refresh().cookies;
        for (let key in currentCookies) {
            if (!currentCookies.hasOwnProperty(key)) continue;
            this.clearCookie(key, true);
        }
        return this.refresh();
    }

    //#endregion

    //#region cookie allowance cookie handling

    public getAllAllowances(): { [key: string]: boolean } {
        const allowances: { [key: string]: boolean } = {},
            cookiesAllowed = this.getValue(this._consentCookieName);
        if (cookiesAllowed !== null) {
            cookiesAllowed.split('&').forEach(item => {
                if (item.length) {
                    allowances[item.replace('!', '').trim()] = !/[!]+/.test(item);
                }
            });
        }
        return allowances;
    }

    public setAllAllowances(params: { [key: string]: boolean }): CookieManager {
        let cookieString: string = '';
        for (let key in params) {
            if (!params.hasOwnProperty(key)) continue;
            cookieString += params[key] === true ? `${key}&` : `!${key}&`;
        }
        return this.setCookie(this._consentCookieName, cookieString.replace(/&$/, ''));
    }

    public setAllowance(key: string, value: boolean): CookieManager {
        const allowances: { [key: string]: boolean } = {};
        allowances[key] = !!value;
        this.setAllAllowances(allowances);
        return this.refresh();
    }

    public getAllowance(key: string): boolean {
        return !!this.getAllAllowances()[key];
    }

    //#endregion
}
