import { Product } from '../../models/product';

export const fahhradSchuelerOld: Product = new Product({
    slug: 'schueler-fahrrad-versicherung-old',
    title: 'Schüler-Fahrrad-Versicherung',
    insuranceTypeName: 'Fahrrad Schüler',
    config: { steps: [] },
    legacy: true,
});

export const schuelerSachschadenUndHaftpflicht: Product = new Product({
    slug: 'schueler-haftpflicht-und-sachschadenversicherung',
    title: 'Schüler-Haftpflicht- und Sachschadenversicherung',
    insuranceTypeName: 'Schüler-Sachschaden-Haftpflicht',
    config: { steps: [] },
    legacy: true,
});

export const schuelerKombischutzJ: Product = new Product({
    slug: 'schueler-kombischutz-j',
    title: 'Schüler-Kombischutz',
    insuranceTypeName: 'Schüler-Kombischutz (J)',
    config: { steps: [] },
    legacy: true,
});

export const schuelerKombischutzM: Product = new Product({
    slug: 'schueler-kombischutz-m',
    title: 'Schüler-Kombischutz',
    insuranceTypeName: 'Schüler-Kombischutz (M)',
    config: { steps: [] },
    legacy: true,
});

export const tabletSchueler: Product = new Product({
    slug: 'tablet-schueler',
    title: 'Tablet- und Notebook-Versicherung',
    insuranceTypeName: 'Tablet Schüler',
    config: { steps: [] },
    legacy: true,
});

export const nachhilfeOld: Product = new Product({
    slug: 'nachhilfe-old',
    title: 'Nachhilfeversicherung',
    insuranceTypeName: 'Nachhilfe',
    config: { steps: [] },
    legacy: true,
});

export const akvPaare: Product = new Product({
    slug: 'akv-paare',
    title: 'Auslandskrankenversicherung für Paare',
    insuranceTypeName: 'AKV Paare',
    config: { steps: [] },
    legacy: true,
});

export const xxlKombischutzJ: Product = new Product({
    slug: 'xxl-kombischutz-j',
    title: 'XXL-Schüler-Kombischutz',
    insuranceTypeName: 'XXL Schüler Kombischutz (J)',
    config: { steps: [] },
    legacy: true,
});

export const xxlKombischutzM: Product = new Product({
    slug: 'xxl-kombischutz-m',
    title: 'XXL-Schüler-Kombischutz',
    insuranceTypeName: 'XXL Schüler Kombischutz (M)',
    config: { steps: [] },
    legacy: true,
});
