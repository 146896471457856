<div class="notification-container">
    <div 
        *ngFor="let notification of notificationService.notificationsSubject | async"
        class="notification"
        [@fadeUp]
        [ngClass]="{
            '-dismissible': notification.dismissible,
            '-success': notification.type == 'success',
            '-warning': notification.type == 'warning',
            '-critical': notification.type == 'critical',
            '-info': notification.type == 'info'
        }"
    >
        <button type="button" (click)="notificationService.removeNotification(notification)" *ngIf="notification.dismissible">
            <span>&times;</span>
        </button>
        <span [innerHtml]="notification.message"></span>
    </div>
</div>
