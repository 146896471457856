<ng-container *ngIf="headerModeService.headerMode$ | async as mode">
    <header id="page-header" *ngIf="mode !== 'none'">
        <div class="content-container no-padding">
            <div class="grid-container topbar">
                <div class="grid-sm-1-2 grid-1-4">
                    <a [routerLink]="['/', 'start']">
                        <img
                            class="gutter-left img-responsive"
                            src="/assets/img/logo_astra_versicherung.svg"
                            alt="astra Versicherung AG"
                        />
                    </a>
                </div>
                <!-- #region default header mode -->
                <ng-container *ngIf="mode === 'default'">
                    <div class="grid-sm-1-2 grid-1-4 hidden-xs hidden-sm claim"><h4>Einfach gut versichert.</h4></div>
                    <div class="grid-sm-1-2 grid-1-4 hidden-xs">
                        <div>
                            <small
                                >Telefonisch erreichbar<br />
                                <span class="text-muted">Mo – Fr, 09:00 bis 16:00 Uhr</span></small
                            >
                            <p class="lead">0621 1247682-0</p>
                        </div>
                    </div>
                    <div class="grid-sm-1-2 grid-1-4 hidden-sm hidden-xs hidden-xxs">
                        <div class="topbar-text-left">
                            <small
                                >Service<br />
                                <span class="text-muted">Rund um die Uhr</span></small
                            >
                            <p class="lead"><a [routerLink]="['/', 'portal', 'schaden-melden']">Schaden melden</a></p>
                        </div>
                    </div>
                </ng-container>
                <!-- #endregion -->
                <!-- #region header for conrad b2c -->
                <ng-container *ngIf="mode === 'conrad'">
                    <div class="grid-sm-1-2 grid-1-4 float-right">
                        <img
                            class="img-responsive float-right margin-right"
                            src="/assets/icons/conrad/Conrad-Electronic-Logo.svg"
                            alt="Conrad Electronic"
                            width="220px"
                            height="75px"
                        />
                    </div>
                </ng-container>
                <!-- #endregion -->
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="blue-line" *ngIf="mode !== 'default'"></div>
        <nav *ngIf="mode === 'default'">
            <div class="wrapper">
                <label for="drop" class="toggle ignore-toggle-events"
                    ><img src="assets/img/icon-menu-white.png"
                /></label>
                <input type="checkbox" id="drop" class="ignore-toggle-events" />
                <ul class="main-navigation__menu ignore-toggle-events" id="main-nav">
                    <li
                        *ngFor="let linkGroup of navigation; let i = index"
                        [ngClass]="{ checked: i == navLinkCheckedIndex }"
                        [attr.data-index]="i"
                        class="main-nav-top-level"
                    >
                        <a>{{ linkGroup.description }}</a>
                        <ul class="main-navigation__submenu level1">
                            <li *ngFor="let link of linkGroup.links">
                                <a [routerLink]="link.routerLink" [title]="link.title" class="nav-link">{{
                                    link.description
                                }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="portal-nav-wrapper ignore-toggle-events" data-index="999">
                    <button class="portal hidden-md hidden-lg" id="portal-nav-toggle" data-index="999">
                        <span [ngClass]="{ loggedIn: isLoggedIn, loggedOut: !isLoggedIn }">Portal</span>
                    </button>
                    <button class="portal hidden-xs hidden-sm" routerLink="./portal/dashboard" data-index="999">
                        <span [ngClass]="{ loggedIn: isLoggedIn, loggedOut: !isLoggedIn }">Portal</span>
                    </button>
                    <ul id="portal-nav" class="portal-nav" [ngClass]="{ '-open': navLinkCheckedIndex == 999 }">
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/dashboard">Mein Dashboard</a>
                            <a *ngIf="!isLoggedIn" routerLink="./portal/dashboard">Login</a>
                        </li>
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/meine-daten" routerLinkActive="active"
                                >Meine Daten</a
                            >
                            <a *ngIf="!isLoggedIn" class="disabled">Meine Daten</a>
                        </li>
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/meine-bankdaten" routerLinkActive="active"
                                >Meine Bankverbindung</a
                            >
                            <a *ngIf="!isLoggedIn" class="disabled">Meine Bankverbindung</a>
                        </li>
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/meine-vertraege" routerLinkActive="active"
                                >Meine Verträge</a
                            >
                            <a *ngIf="!isLoggedIn" class="disabled">Meine Verträge</a>
                        </li>
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/neuer-vertrag" routerLinkActive="active"
                                >Neuer Vertrag</a
                            >
                            <a *ngIf="!isLoggedIn" class="disabled">Neuer Vertrag</a>
                        </li>
                        <li>
                            <a *ngIf="isLoggedIn" routerLink="./portal/schaden-melden" routerLinkActive="active"
                                >Schadensmeldung</a
                            >
                            <a *ngIf="!isLoggedIn" class="disabled">Schadensmeldung</a>
                        </li>
                        <li *ngIf="isLoggedIn">
                            <a (click)="logout()">Abmelden</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="clearfix"></div>
        <a href="#main-menu-toggle" class="backdrop" tabindex="-1" aria-hidden="true" hidden></a>
    </header>
</ng-container>
