import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoBoxComponent } from './info-box/info-box.component';
import { BirthdayPickerComponent } from './birthday-picker/birthday-picker.component';
import { ManglePipe } from './pipes/mangle.pipe';
import { HeroComponent } from './hero/hero.component';
import { InsuranceTypePipe } from './pipes/insurance-type.pipe';
import { RequiredInfoComponent } from './required-info/required-info.component';
import { ModalComponent } from './modal/modal.component';
import { SplitCurrencyPipe } from './pipes/split-currency.pipe';
import { PoolObjectMemberNamePipe } from './pipes/poolObjectMemberName.pipe';
import { PoolObjectDescriptionPipe } from './pipes/poolObjectDescription.pipe';
import { TarifFromIdPipe } from './pipes/tarifFromId.pipe';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FileUploadDirective } from './directives/file-upload.directive';
import { JsonLdComponent } from './json-ld/json-ld.component';
import { SelectWithOtherOptionComponent } from './select-with-other-option/select-with-other-option.component';
import { DateInputDirective } from './directives/date-input.directive';
import { CommaToDotPipe } from './pipes/comma-to-dot.pipe';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { FaqComponent } from './components/faq/faq.component';

@NgModule({
    declarations: [
        // components:
        InfoBoxComponent,
        BirthdayPickerComponent,
        HeroComponent,
        RequiredInfoComponent,
        ModalComponent,
        JsonLdComponent,
        SelectWithOtherOptionComponent,
        DataProtectionComponent,
        FaqComponent,
        // pipes:
        ManglePipe,
        InsuranceTypePipe,
        SplitCurrencyPipe,
        PoolObjectMemberNamePipe,
        PoolObjectDescriptionPipe,
        TarifFromIdPipe,
        CommaToDotPipe,
        // directives:
        DropZoneDirective,
        FileUploadDirective,
        DateInputDirective,
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [
        // modules:
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // components:
        InfoBoxComponent,
        BirthdayPickerComponent,
        HeroComponent,
        RequiredInfoComponent,
        ModalComponent,
        JsonLdComponent,
        SelectWithOtherOptionComponent,
        DataProtectionComponent,
        FaqComponent,
        // pipes:
        ManglePipe,
        InsuranceTypePipe,
        SplitCurrencyPipe,
        PoolObjectMemberNamePipe,
        PoolObjectDescriptionPipe,
        TarifFromIdPipe,
        CommaToDotPipe,
        // directives:
        DropZoneDirective,
        FileUploadDirective,
        DateInputDirective,
    ],
})
export class SharedModule {}
