// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    debug: true,
    production: false,
    apiToken: '0CC0834B-73E5-4662-80A3-4675C802B64F',
    lockersNetToken: '0CC0834B-73E5-4662-80A3-4675C802B64F',
    basePath: 'https://insuretest.astradirect.de:8445/RestService.svc/json/',
    lockersNet: 'https://lockersnet-gw1.astradirect.de/AstraPortalService/AstraPortalService.svc/json/',
    login: 'Login',
    changeLogin: 'ChangeLogin',
    forgotPassword: 'ForgetPassword',
    confirmForgotPassword: 'ConfirmForgetPassword',
    createNewPassword: 'CreateNewPassword',
    insuranceTypes: 'InsuranceTypes',
    cities: 'Cities',
    countries: 'Countries',
    titles: 'Titles',
    documentTypes: 'DocumentTypes',
    checkDiscount: 'InsuranceTypes/%!/DiscountFactor',
    contractRequest: 'ContractRequests',
    checkContractRequest: 'CheckContractRequest',
    contracts: 'Contracts',
    insuranceDoc: 'InsuranceDoc',
    customerPersonalData: 'CustomerPersonalData',
    customerBankDetails: 'CustomerBankDetails',
    damage: 'Damages',
    damageDocument: 'DamageDocuments',
    damageDocumentFile: 'DamageDocuments',
    customer: 'Customer',
    googleAnalyticsId: 'UA-113651050-1',
    googleConversionId: 'AW-871389258',
    sendGAEvents: false,
    contactMail: 'assets/mailer.php',
    customerTransfer: 'OnlineRequest/CustomerData/',
    getInsuranceObjects: 'InsuranceObjects/',
    getInsuranceObjectPrice: 'InsuranceObjectPrice/',
    getPoolObjectPrice: 'InsuranceTypes/%!/InsurancePrice',
    updatePoolObjects: 'Contracts/%!/ObjectPool',
    getInsurantsPrice: 'InsuranceTypes/%!/InsurancePriceByInsurants',
    updateInsurants: 'Contracts/%!/Insurants',
    titleCompanyId: 80127,
    insuranceRanks: [
        { id: 80124, description: 'Basis' },
        { id: 80125, description: 'Plus' },
        { id: 80126, description: 'Premium' },
    ],
    gtagUrl: 'https://www.googletagmanager.com/gtag/js?id=UA-113651050-1',
    dataProtectionUrl: 'https://dse.cortina-consult.com/privacy/617bfa360e427/de',
};
