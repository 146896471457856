import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

const INSURANCE_RANKS = environment.insuranceRanks;

@Pipe({
    name: 'tarifFromId',
})
export class TarifFromIdPipe implements PipeTransform {
    transform(value: any): string {
        const tarif = INSURANCE_RANKS.find(item => item.id == value);
        return tarif ? tarif.description : 'unbekannt';
    }
}
