<div class="hero">
    <div class="hero-image-container" >
        <img [src]="imagePath" [ngClass]="'image-align-' + imageAlign" [alt]="imageAlt" [title]="imageAlt">
    </div>
    <div class="hero-text-box-wrapper" *ngIf="headline">
        <div class="hero-text-box">
            <div class="hero-text-box-content" [innerHtml]="headline"></div>
        </div>
    </div>
</div>
