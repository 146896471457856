import { Product } from '../models/product';
import {
    akvPaare,
    fahhradSchuelerOld,
    schuelerKombischutzJ,
    schuelerKombischutzM,
    schuelerSachschadenUndHaftpflicht,
    tabletSchueler,
    xxlKombischutzJ,
    xxlKombischutzM,
} from './legacy-products/legacy-products';
import { auslandsKvFamilie } from './products/auslands-kv-familie';
import { auslandsKvPaare } from './products/auslands-kv-paare';
import { auslandsKvSchueler } from './products/auslands-kv-schueler';
import { auslandsKvSingle } from './products/auslands-kv-single';
import { conradB2B } from './products/conrad-b2b';
import { conradB2C } from './products/conrad-b2c';
import { elektronikversicherung } from './products/elektronikversicherung';
import { fahrradversicherungSchueler } from './products/fahhrad-schueler';
import { musikinstrumentenversicherungPremium } from './products/musikinstrumente-premium';
import { musikinstrumentenversicherungSchueler } from './products/musikinstrumente-schueler';
import { musikinstrumentenversicherungSchulenKlasse } from './products/musikinstrumente-schulen-klasse';
import { musikinstrumentenversicherungSchulenPool } from './products/musikinstrumente-schulen-pool';
import { nachhilfePlus } from './products/nachhilfe-plus';
import { schutzbriefStudents } from './products/schutzbrief-students';
import { tabletVersicherungPlusJ } from './products/tablet-versicherung-plus-j';
import { tabletVersicherungPlusM } from './products/tablet-versicherung-plus-m';
import { unfallversicherungSchueler } from './products/unfall-schueler';
import { zahnzusatzversicherung } from './legacy-products/zahnzusatz';
import { zahnzusatzversicherung2022 } from './products/zahnzusatz-2022';
import { handyversicherung } from './products/handyversicherung';

export const products: Product[] = [
    schutzbriefStudents,
    auslandsKvFamilie,
    auslandsKvPaare,
    auslandsKvSingle,
    auslandsKvSchueler,
    tabletVersicherungPlusM,
    tabletVersicherungPlusJ,
    musikinstrumentenversicherungPremium,
    musikinstrumentenversicherungSchueler,
    musikinstrumentenversicherungSchulenPool,
    musikinstrumentenversicherungSchulenKlasse,
    zahnzusatzversicherung2022,
    fahrradversicherungSchueler,
    unfallversicherungSchueler,
    nachhilfePlus,
    conradB2C,
    conradB2B,
    elektronikversicherung,
    handyversicherung,

    // legacy products:
    fahhradSchuelerOld,
    schuelerSachschadenUndHaftpflicht,
    schuelerKombischutzJ,
    schuelerKombischutzM,
    tabletSchueler,
    akvPaare,
    xxlKombischutzJ,
    xxlKombischutzM,
    zahnzusatzversicherung,
];
