import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../../models/user';
import { Auth } from '../../models/auth';
import { InsurenetInterfaceService } from '../insurenet-interface/insurenet-interface.service';
import { JSONToUser, userToJSON } from '../../mappers/user';
import { CustomerService } from '../customer/customer.service';
import { UserSessionStorage } from '../../utils/user-session-storage';

@Injectable()
export class AuthService {
    private authState$ = new BehaviorSubject<Auth | null>(null);
    public auth$ = this.authState$.asObservable();

    constructor(private insurenetInterface: InsurenetInterfaceService, private customerService: CustomerService) {
        const user = UserSessionStorage.user;
        if (user !== null) {
            this.authState$.next(user.auth);
            this.customerService.customer = user.customer;
        }
    }

    //#region getters

    public get isLoggedIn$(): Observable<boolean> {
        return this.authState$.asObservable().pipe(map(user => user !== null));
    }

    public get isLoggedIn(): boolean {
        return this.authState$.value !== null;
    }

    public get isLoggedInStatus(): boolean {
        return this.isLoggedIn;
    }

    public get token(): string | null {
        return this.authState$.value !== null ? this.authState$.value.accessToken : null;
    }

    //#endregion
    //#region public methods

    public login(email: string, password: string): Observable<User> {
        return this.insurenetInterface.login(email, password).pipe(
            tap(user => {
                this.authState$.next(user.auth);
                this.customerService.customer = user.customer;
                UserSessionStorage.user = user;
            })
        );
    }

    public logout(): void {
        UserSessionStorage.clear();
        this.authState$.next(null);
        this.customerService.customer = null;
    }

    //#endregion
}
