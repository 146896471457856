<ng-container *ngIf="showCookieSettings">
    <div class="cookie-settings-container bg-light-grey-a">
        <div class="content-container">
            <form [formGroup]="form" (submit)="submit()">
                <h2>Cookies</h2>
                <p class="no-margin">
                    Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen Cookies, die
                    für den Betrieb der Seite notwendig sind, sowie solche, die zur Medienwiedergabe bzw.
                    Komfortfunktionen verwendet werden. Sie können selbst entscheiden, welche Kategorien Sie zulassen
                    möchten. Bitte beachten Sie, dass auf Basis Ihrer Auswahl womöglich nicht mehr alle Funktionalitäten
                    der Seite zur Verfügung stehen. Weitere Informationen finden Sie in unserer
                    <a routerLink="./datenschutz" target="_blank">Datenschutzerklärung</a>.
                </p>
                <ng-container *ngIf="settingsMode === 'general'">
                    <div class="grid-container gutter-top">
                        <div class="grid-1-4">
                            <button type="button" class="cancel" (click)="settingsMode = 'detailed'">
                                Einstellungen
                            </button>
                        </div>
                        <div class="grid-1-4">
                            <button type="button" (click)="acceptAll()">
                                Alle erlauben
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="settingsMode === 'detailed'">
                    <div class="grid-container">
                        <div class="grid-1-1 gutter-bottom gutter-half-top">
                            <input type="checkbox" id="cookies-necessary" formControlName="necessary" />
                            <label for="cookies-necessary">Notwendig</label>
                            <input type="checkbox" id="cookies-google" formControlName="google" />
                            <label for="cookies-google">Google Analytics</label>
                        </div>
                        <div class="grid-1-4">
                            <button type="submit" class="cancel">
                                Speichern
                            </button>
                        </div>
                        <div class="grid-1-4">
                            <button type="button" (click)="acceptAll()">
                                Alle erlauben
                            </button>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>
</ng-container>
