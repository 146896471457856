import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileDropEvent } from '../models/file-drop-event.model';

// this is an ugly patch to fix compiler error:
// ERROR in src/app/shared/directives/file-upload.directive.ts(12,60): error TS 2304: Cannot find name 'InputEvent'.
// TODO: check if this is still needed after update
interface InputEvent {
    target: HTMLInputElement;
    preventDefault(): void;
    stopPropagation(): void;
}

@Directive({
    selector: '[appFileUpload]',
})
export class FileUploadDirective {
    @Input() public allowedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
    @Input() public maxSize = 2048;
    @Output() public fileSelected: EventEmitter<FileDropEvent> = new EventEmitter<FileDropEvent>();

    @HostListener('change', ['$event']) public onChange(e: InputEvent) {
        e.preventDefault();
        e.stopPropagation();
        const files = (e.target as HTMLInputElement).files;
        if (files.length) {
            if (this.allowedFileTypes.includes(files[0].type)) {
                if (files[0].size / 1024 < this.maxSize) {
                    this.fileSelected.emit(new FileDropEvent('selected', files[0]));
                } else {
                    this.fileSelected.emit(new FileDropEvent('rejected-size'));
                }
            } else {
                this.fileSelected.emit(new FileDropEvent('rejected-type'));
            }
        } else {
            this.fileSelected.emit(new FileDropEvent('empty'));
        }
    }
    public constructor() {}
}
