import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

// NOTE: Device types and lens manufacturers are definned in conrad-b2c!

const config: ProductConfig = {
    steps: ['', 'versichertes-geraet', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    requiresNoPriorDefect: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    hideSummarySidebarOnLandingPage: true,
    headerMode: 'default',
};

const presentation: ProductPresentation = {
    description: `
        <p class="semibold">
            Unsere Elektronikversicherung schützt nahezu all Ihre privaten Geräte vor möglichen 
            Risiken und Schäden.
        </p>
        <p>
            Ganz gleich ob Schäden am Display, eine Beschädigung durch Dritte oder Bedienungsfehler: 
            Die astra Versicherung für Elektronikgeräte greift im Notfall ein und schützt Sie vor 
            unerwarteten hohen Kosten. 
        </p>
    `,
    bulletPoints: [],
    imageUrl: 'assets/img/product-headers/astra_versicherung_conrad.jpg',
    imageAlt: 'Elektrogerät ohne Selbstbeteiligung versichern',
    imageHeadline: '<h3 class="no-margin-bottom">Elektrogerät ohne Selbstbeteiligung versichern</h3>',
    docIPIDUrl: 'astra_IPID_Elektronik_Endkunden_2022.pdf',
    docAVBUrl: 'astra_AVB_Elektronik_Endkunden_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Elektronik_Endkunden_2022.pdf',
    //pageTitle: 'Seitentitel (optional)',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Zu versicherndes Instrument',
    description: '',
    objectDescriptionSingular: 'Gerät',
    max: 1,
    controls: [
        {
            name: 'type',
            label: 'Art',
            type: 'text',
        },
        {
            name: 'description',
            label: 'Hersteller',
            type: 'text',
        },
        {
            name: 'model',
            label: 'Modell',
            type: 'text',
        },
        {
            name: 'value',
            label: 'Kaufpreis',
            type: 'value',
        },
        {
            name: 'detail',
            label: 'Seriennummer',
            type: 'text',
        },
    ],
};

export const elektronikversicherung: Product = new Product({
    slug: 'elektronikversicherung',
    title: 'Elektronikversicherung',
    insuranceTypeName: 'Elektronikversicherung B2C',
    config,
    presentation,
    insuredObjectsForm,
});
