import { differenceInYears, parse } from 'date-fns';
import { Customer } from '../models/customer';
import { CustomerDto } from '../models/customer-dto';
import { LockersNetCustomerDto } from '../models/ln-customer-dto';
import { OrderState } from '../order/state/order.store';

export function dtoToCustomer(dto: CustomerDto): Customer {
    return {
        id: dto.Id,
        title: {
            id: dto.Title.Id,
            description: dto.Title.Description,
        },
        firstName: dto.Firstname,
        lastName: dto.Lastname,
        fullName: `${dto.Firstname ? dto.Firstname + ' ' : ''}${dto.Lastname}`,
        company: dto.Company,
        street: dto.Street,
        zip: dto.ZIP,
        city: dto.City,
        phone: dto.Phone,
        mail: dto.Email,
        dateOfBirth: dto.DateOfBirth,
        accountHolder: dto.AccountHolder,
        iban: dto.IBAN,
        nameOfBank: dto.NameOfBank,
    };
}

export function customerToOrderState(customer: Customer): Pick<OrderState, 'billingAddress' | 'payment'> {
    return {
        billingAddress: {
            title: customer.title,
            firstName: customer.firstName,
            lastName: customer.lastName,
            company: customer.company,
            street: customer.street,
            zip: customer.zip,
            city: customer.city,
            phone: customer.phone,
            mail: customer.mail,
            dateOfBirth: customer.dateOfBirth,
        },
        payment: {
            iban: customer.iban,
            accountHolder: customer.accountHolder,
            nameOfBank: customer.nameOfBank,
        },
    };
}

export function lnDtoToOrderState(dto: LockersNetCustomerDto): Partial<OrderState> {
    const [lastName, firstName] = dto.Name.split(',').map(item => item.trim());
    const over18: boolean = differenceInYears(new Date(), parse(dto.DateOfBirth, 'dd.MM.yyyy', new Date())) >= 18;

    const state = {
        billingAddress: {
            firstName: over18 ? firstName : '',
            lastName: over18 ? lastName : '',
            dateOfBirth: over18 ? dto.DateOfBirth : null,
            street: dto.Street,
            zip: dto.ZIP,
            city: dto.City,
            phone: dto.Phone,
            mail: dto.Email,
        },
        payment: {
            iban: dto.IBAN,
            accountHolder: dto.AccountHolder,
            nameOfBank: dto.NameOfBank,
        },
        insurants: over18
            ? []
            : [
                  {
                      firstName,
                      lastName,
                      dateOfBirth: dto.DateOfBirth,
                      isInsuree: false,
                      isChild: true,
                  },
              ],
    } as OrderState;

    return state;
}

export function customerToDto(customer: Customer): CustomerDto {
    return {
        Id: customer.id,
        Title: {
            Id: customer.title.id,
            Description: customer.title.description,
        },
        Company: customer.company,
        Firstname: customer.firstName,
        Lastname: customer.lastName,
        Street: customer.street,
        ZIP: customer.zip,
        City: customer.city,
        Phone: customer.phone,
        DateOfBirth: customer.dateOfBirth,
        AccountHolder: customer.accountHolder,
        IBAN: customer.iban,
        NameOfBank: customer.nameOfBank,
        Email: customer.mail,
    };
}
