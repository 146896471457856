export class NavigationLink {
    description: string;
    routerLink: any[] = [];
    title: string;

    constructor(description: string, routerLink: any[], title: string = description) {
        this.description = description;
        this.routerLink = routerLink;
        this.title = this.description;
    }
}
