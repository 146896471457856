<div class="content-container">
    <div class="grid-container triple-gutter-vertical">
        <div class="grid-1-1 gutter-bottom">
            <h2 class="no-margin-bottom">Bitte melden Sie sich an</h2>
            <hr />
        </div>
        <form class="gutter-top" [formGroup]="form" (submit)="submit()">
            <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 gutter-bottom">
                <label>Email</label>
                <input type="email" formControlName="email" placeholder="E-Mailadresse" required />
                <label>Passwort</label>
                <input type="password" formControlName="password" placeholder="Passwort" required />
                <div class="grid-container">
                    <div class="grid-1-2 grid-sm-1-2 hidden-xxs">&nbsp;</div>
                    <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 gutter">
                        <button type="submit" [disabled]="form.pristine || form.invalid">Senden</button>
                    </div>
                </div>
            </div>
            <div class="grid-1-2 grid-sm-1-2 grid-xs-1-1 half-gutter-top">
                <app-info-box headline="Passwort vergessen?">
                    <p>
                        Wenn Sie Ihr Passwort vergessen haben, können Sie hier ein neues anfordern. Bitte halten Sie
                        Ihre E-Mailadresse und Ihre Versicherungsnummer bereit.
                    </p>
                    <a routerLink="/passwort-vergessen">&raquo; Neues Passwort anfordern</a>
                </app-info-box>
            </div>
            <div class="grid-1-1"></div>
        </form>
    </div>
</div>
