import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NotificationService } from '../notification/notification.service';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.service';
import { NotificationType } from '../notification/notification-type.enum';
import { LoggerService } from '../logger/logger.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';

type Notify = 'ALL' | 'NONE' | 'ERROR' | 'SUCCESS';
type ResponseType = 'json' | 'blob';

interface Params {
    params?: HttpParams;
    headers?: HttpHeaders;
    responseType?: ResponseType;
    retryCount?: number;
    notify?: Notify;
    timeout?: number;
    spin?: boolean;
    successMessage?: string;
    errorMessage?: string;
}

@Injectable()
export class HttpService {
    constructor(
        private http: HttpClient,
        private notification: NotificationService,
        private spinner: SpinnerService,
        private log: LoggerService,
        private router: Router
    ) {}

    public get<T>(
        url: string,
        {
            params = new HttpParams(),
            headers = new HttpHeaders(),
            responseType = 'json',
            retryCount = 0,
            notify = 'ALL',
            timeout = 5000,
            spin = true,
        }: Params = {}
    ): Observable<T> {
        return this.requestPipe<T>(
            this.http.get<T>(url, { params, headers, responseType: responseType as 'json' }),
            retryCount,
            notify,
            timeout,
            spin
        );
    }

    public put<T>(
        url: string,
        data: any,
        {
            params = new HttpParams(),
            headers = new HttpHeaders(),
            responseType = 'json',
            retryCount = 0,
            notify = 'ALL',
            timeout = 5000,
            spin = true,
        }: Params = {}
    ): Observable<T> {
        return this.requestPipe<T>(
            this.http.put<T>(url, data, { params }),
            retryCount,
            notify,
            timeout,
            spin
        );
    }

    public post<T>(
        url: string,
        data: any,
        {
            params = new HttpParams(),
            headers = new HttpHeaders(),
            responseType = 'json',
            retryCount = 0,
            notify = 'ALL',
            timeout = 5000,
            spin = true,
        }: Params = {}
    ): Observable<T> {
        return this.requestPipe<T>(
            this.http.post<T>(url, data, { params }),
            retryCount,
            notify,
            timeout,
            spin
        );
    }

    public upload(
        url: string,
        data: FormData,
        {
            params = new HttpParams(),
            headers = new HttpHeaders(),
            responseType = 'json',
            retryCount = 0,
            notify = 'ALL',
            timeout = 5000,
            spin = true,
        }: Params = {}
    ): Observable<unknown> {
        return this.requestPipe<unknown>(this.http.post(url, data, { params }), retryCount, notify, timeout, spin);
    }

    private requestPipe<T>(
        req: Observable<T>,
        retryCount: number,
        notify: Notify,
        timeout: number,
        spin: boolean,
        successMessage: string = 'Ihre Daten wurden erfolgreich gespeichert',
        errorMessage: string = 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut'
    ): Observable<T> {
        if (spin === true) {
            this.spinner.start();
        }
        return req.pipe(
            finalize(() => {
                if (spin === true) {
                    this.spinner.stop();
                }
            }),
            tap((res: any) => {
                this.log.info('Response: ', res);
                if (notify === 'ALL' || notify === 'SUCCESS') {
                    this.notification.notify(successMessage, NotificationType.SUCCESS, timeout);
                }
            }),
            // retryWhen(err => {
            //     if (retryCount > 0) {
            //         return err.pipe(
            //             delay(1000),
            //             take(retryCount),
            //             catchError(err => this.handleError(err, notify, timeout))
            //         );
            //     } else {
            //         return throwError('Should never be displayed, as it is caught downstream.');
            //     }
            // }),
            catchError((err, caught) => this.handleError(err, caught, retryCount, notify, timeout, errorMessage))
        );
    }

    private handleError(
        err: HttpErrorResponse,
        caught,
        retryCount: number = 0,
        notify: Notify,
        timeout: number,
        errorMessage: string
    ) {
        if (err.error instanceof ErrorEvent) {
            // client side error
            this.log.warn('Client side error:');
            this.log.warn(err.error);
            if (notify === 'ALL' || notify === 'ERROR') {
                this.notification.notify(errorMessage, NotificationType.CRITICAL, timeout);
            }
        } else {
            // TODO: Match error response and notify user
            this.log.info(`Backend error: ${err.status}`);
            this.log.info(err.error);
            if (err.error.ErrorCode) {
                switch (err.error.ErrorCode) {
                    case 72: // email address already in use (response to contract request)
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(
                                'Für diese E-Mailadresse besteht bereits ein Kundenkonto',
                                NotificationType.WARNING,
                                timeout
                            );
                        }
                        break;
                    // 22 unknown User
                    case 22:
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(
                                'Unbekannter Benutzer oder falsches Kennwort.',
                                NotificationType.WARNING,
                                timeout
                            );
                        }
                        break;
                    case 9: // Session abgelaufen
                    case 10: // Session unbekannt
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(
                                'Session ungültig. Bitte loggen Sie sich ein.',
                                NotificationType.WARNING,
                                timeout
                            );
                        }
                        // TODO: find an alternative for this:
                        // this.authService.logout();
                        this.router.navigate(['/login', { redirect: this.router.routerState.snapshot.url }]);
                        break;
                    case 46: // user for pw-reset not found
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(
                                'Benutzer nicht gefunden. Bitte überprüfen Sie die eingegebene E-Mailadresse und Versicherungsnummer.',
                                NotificationType.WARNING,
                                timeout
                            );
                        }
                        break;
                    case 47: // pw reset token not valid/expired
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(
                                'Dieser Link ist abgelaufen oder ungültig.',
                                NotificationType.WARNING,
                                timeout
                            );
                        }
                        return throwError('token invalid or expired');
                    default:
                        if (notify === 'ALL' || notify === 'ERROR') {
                            this.notification.notify(errorMessage, NotificationType.CRITICAL, timeout);
                        }
                        break;
                }
            } else {
                if (notify === 'ALL' || notify === 'ERROR') {
                    this.notification.notify(errorMessage, NotificationType.CRITICAL, timeout);
                }
            }
        }

        // this would be returned as error message and might be handled by the caller, e.g. shown to the user.
        return throwError(err.error);
    }
}
