import { Component, OnInit } from '@angular/core';
import { NotificationService } from './core/notification/notification.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import CookieManager from './utils/cookie-manager';
import { HeaderMode, HeaderModeService } from './core/header-mode/header-mode.service';
import { combineLatest } from 'rxjs';
import { pluck, filter, map } from 'rxjs/operators';
import { OrderQuery } from './order/state/order.query';

const ANALYTICS_ID = environment.googleAnalyticsId;

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private cookies = new CookieManager('cookiesAllowed');

    constructor(
        public notificationService: NotificationService,
        private router: Router,
        private headerModeService: HeaderModeService,
        private route: ActivatedRoute,
        private orderQuery: OrderQuery
    ) {
        this.subscribeToHeaderModeQueryParam();
        this.subscribeToProductChange();
    }

    ngOnInit(): void {
        this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd && typeof gtag !== 'undefined' && this.cookies.getAllowance('google')) {
                gtag('event', 'page_view');
                //gtag('config', ANALYTICS_ID, { page_path: e.urlAfterRedirects });
            }
        });
    }

    private subscribeToProductChange(): void {
        combineLatest([
            this.orderQuery.product$.pipe(
                pluck('config', 'headerMode'),
                filter(mode => typeof mode !== 'undefined'),
                map(mode => mode as HeaderMode)
            ),
            this.router.events.pipe(
                filter(e => e instanceof NavigationEnd),
                map(e => e as NavigationEnd)
            ),
        ]).subscribe(([mode, route]: [HeaderMode, NavigationEnd]) => {
            this.headerModeService.setHeaderMode(
                route.urlAfterRedirects.indexOf('/produkte/') === -1 ? 'default' : mode,
                'product'
            );
        });
    }

    private subscribeToHeaderModeQueryParam(): void {
        this.route.queryParams
            .pipe(
                pluck('hm'),
                filter(mode => typeof mode !== 'undefined'),
                map(mode => mode as HeaderMode)
            )
            .subscribe(mode => this.headerModeService.setHeaderMode(mode, 'queryParam'));
    }
}
