import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function matchOtherValidator(otherControlName: string, unsubscribe$: Subject<any> | null = null) {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return (control: FormControl): { matchOther?: true } | null => {
        if (!control.parent) {
            return null;
        }

        if (!thisControl) {
            thisControl = control;
            otherControl = control.parent.get(otherControlName) as FormControl;
            if (!otherControl) {
                throw new Error('matchOtherValidator(): other control is not found in parent group');
            }
            if (unsubscribe$ !== null) {
                otherControl.valueChanges.pipe(takeUntil(unsubscribe$)).subscribe(() => {
                    thisControl.updateValueAndValidity();
                });
            } else {
                otherControl.valueChanges.subscribe(() => {
                    thisControl.updateValueAndValidity();
                });
            }
        }

        if (!otherControl) {
            return null;
        }

        if (otherControl.value !== thisControl.value) {
            return {
                matchOther: true,
            };
        }
        return null;
    };
}
