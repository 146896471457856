import { JSONToUser, userToJSON } from '../mappers/user';
import { User } from '../models/user';

export class UserSessionStorage {
    public static set user(_user: User) {
        sessionStorage.setItem('user', userToJSON(_user));
    }

    public static get user(): User | null {
        const userJSON = sessionStorage.getItem('user');
        if (userJSON) {
            return JSONToUser(userJSON);
        }
        return null;
    }

    public static update(user: Partial<User>): User | null {
        const _user = this.user;
        if (_user !== null) {
            this.user = { ..._user, ...user };
        }
        return this.user;
    }

    public static clear(): void {
        sessionStorage.clear();
    }
}
