import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-json-ld',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonLdComponent {
    @Input() public set json(currentValue: {}) {
        this.jsonLD = this.getSafeHtml(currentValue);
    }
    @HostBinding('innerHTML') jsonLD?: SafeHtml;
    public constructor(private sanitizer: DomSanitizer) {}

    private getSafeHtml(value: {}): SafeHtml {
        const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
