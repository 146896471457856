import { environment } from '../../../environments/environment';
import { InsuranceRank } from '../../models/insurance-rank';
import { InsurantsFormConfig } from '../../models/insurants-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

export const insuranceRanks: InsuranceRank[] = environment.production
    ? [
          // production ranks:
          {
              description: 'ZahnPerfekt 75%',
              id: 80211,
          },
          {
              description: 'ZahnPlus 80%',
              id: 80212,
          },
          {
              description: 'ZahnMega 90%',
              id: 80213,
          },
          {
              description: 'ZahnSieger 100%',
              id: 80214,
          },
      ]
    : [
          // dev ranks:
          {
              description: 'ZahnPerfekt 75%',
              id: 80165,
          },
          {
              description: 'ZahnPlus 80%',
              id: 80166,
          },
          {
              description: 'ZahnMega 90%',
              id: 80167,
          },
          {
              description: 'ZahnSieger 100%',
              id: 80168,
          },
      ];

const config: ProductConfig = {
    steps: ['', 'versicherte-personen', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
    hasFlexPrice: true,
    requiresMedicalData: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    insurantMaxAge: 99,
    contractStartMode: 'month',
    monthsInAdvance: 5,
    hideSummarySidebarOnLandingPage: true,
};

const presentation: ProductPresentation = {
    description: `
        <p>
            <strong class="blue semibold">
                Die Zahnzusatzversicherung für gesetzlich Versicherte ab 9,<sup>52</sup>&nbsp;<span class="currency-sign">€</span> pro Monat.
            </strong>
        </p>
        <p>
            Diese Zahnzusatzversicherung ergänzt sinnvoll die unvollständigen Leistungen gesetzlicher Krankenversicherungen 
            und reduziert Ihren Eigenanteil auf ein Minimum. So bleiben gesunde Zähne und notwendige Behandlungen oder 
            Zahnersatz einfacher bezahlbar.
        </p>
    `,
    bulletPoints: [
        'Umfassende Zahnzusatzversicherung für gesetzlich Versicherte',
        'Wählen Sie aus vier Tarifen Ihre individuelle Absicherung',
        'Sofortleistungen ohne Wartezeit',
        'Weltweiter Schutz',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherungen_Zahnzusatzversicherung.jpg',
    imageAlt: 'Ihr Zusatzschutz für gesunde und schöne Zähne von astra Versicherung AG',
    imageHeadline: '<h3 class="no-margin-bottom">Ihr Zusatzschutz für gesunde und schöne Zähne</h3>',
    docIPIDUrl: 'astra_IPID_AstraZahn_2022.pdf',
    docAVBUrl: 'astra_AVB_AstraZahn_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_AstraZahn_2022.pdf',
    docAnzeigepflichtUrl: 'astra_Anzeigepflicht_AstraZahn_2022.pdf',
    pageTitle: 'Zahnzusatzversicherung | ab 9,52 € / Monat | astra Versicherung',
};

const insurantsForm: InsurantsFormConfig = {
    headline: 'Versicherte Personen',
    priceFromInsurants: true,
    requiresInsurantsFamilyRelationship: 1,
    // TODO: remove this when a cleaner solution has been found:
    meOrOther: true,
    insurants: [
        {
            category: 'person',
            displayName: 'versicherte Person',
            displayNameSummary: 'Versicherte Personen',
            label: '',
            minAge: 0,
            maxAge: 99,
            maxCount: 7,
            firstOneRemovable: true,
            addButtonLabel: 'Weitere Person hinzufügen',
            rankIdMandatory: true,
            insuranceRanks: insuranceRanks,
            askForMissingTeeth: true,
            note:
                '<small>*) Die Abfrage umfasst die Anzahl bereits fehlender Zähne, welche noch nicht ersetzt wurden. Nicht anzugeben sind Milch- und Weisheitszähne oder vollständige Lückenschlüsse durch benachbarte Zähne sowie Zahnersatz (Kronen, Implantate, Veneers, Prothesen, Füllungen und Brücken).</small>',
        },
    ],
};

export const zahnzusatz2022TarifsTable: { lable: string; cells: string[] | boolean[] }[] = [
    {
        lable: '',
        cells: ['ZahnPerfekt', 'ZahnPlus', 'ZahnMega', 'ZahnSieger'],
    },
    {
        lable: 'Zahnersatz wie z.B. Implantate',
        cells: ['75%', '80%', '90%', '100%'],
    },
    {
        lable: 'Zahnbehandlung wie z.B. hochwertige Kunststofffüllungen oder Wurzelbehandlungen',
        cells: ['100%', '100%', '100%', '100%'],
    },
    {
        lable: 'Professionelle Zahnreinigung',
        cells: ['bis zu 160 € p.a', 'bis zu 160 € p.a', 'bis zu 180 € p.a.', 'bis zu 200 € p.a.'],
    },
    {
        lable: 'Umfangreiche Sonderleistungen**',
        cells: ['75%', '80%', '90%', '100%'],
    },
    {
        lable: 'Keine Wartezeit',
        cells: [true, true, true, true],
    },
];

export const zahnzusatz2022SavingsTable: { lable: string; cells: string[] }[] = [
    {
        lable: 'Beispielrechnung',
        cells: ['ZahnPerfekt<br/>75%', 'ZahnPlus<br/>80%', 'ZahnMega<br/>90%', 'ZahnSieger<br/>100%'],
    },
    {
        lable: 'Kosten Implantat mit Keramikkrone',
        cells: ['4.000&nbsp;€', '4.000&nbsp;€', '4.000&nbsp;€', '4.000&nbsp;€'],
    },
    {
        lable: 'Eigenanteil <strong>ohne</strong> Zahnzusatzversicherung',
        cells: ['3.750&nbsp;€', '3.750&nbsp;€', '3.750&nbsp;€', '3.750&nbsp;€'],
    },
    {
        lable: 'Eigenanteil <strong>mit</strong> Zahnzusatzversicherung',
        cells: ['1.000&nbsp;€', '800&nbsp;€', '400&nbsp;€', '0&nbsp;€'],
    },
    {
        lable: 'Ihre Ersparnis',
        cells: ['2.750&nbsp;€', '2.950&nbsp;€', '3.350&nbsp;€', '3.750&nbsp;€'],
    },
];

export const basePrices: number[] = [9.52, 10.68, 12.64, 15.12];

export const zahnzusatzversicherung2022: Product = new Product({
    slug: 'zahnzusatz2022',
    title: 'Zahnzusatzversicherung',
    fullTitle: 'Zahnzusatzversicherung',
    insuranceTypeName: 'Zahnzusatz 2022',
    config,
    presentation,
    insurantsForm,
});
