import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { environment } from '../../../environments/environment';
import { Contract } from '../contract/contract.model';
import { Observable } from 'rxjs/internal/Observable';
import { first, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Damage, NewDamageDto } from './damage.model';
import { DamageDocument } from './damageDocument.model';
import { from, of } from 'rxjs';

const DAMAGE_URL = environment.basePath + environment.damage;
const DAMAGE_DOCUMENT_URL = environment.basePath + environment.damageDocument;

@Injectable()
export class DamageService {
    private damagesCache = new Map<number, Damage[]>();
    private damageDocumentsCache = new Map<number, DamageDocument[]>();

    constructor(private http: HttpService, private log: LoggerService) {}

    public reportDamage(damage: NewDamageDto): Observable<Damage> {
        return this.http.post<Damage>(DAMAGE_URL, damage).pipe(
            map(val => plainToClass(Damage, val)),
            tap(val => this.cacheSingleDamage(val))
        );
    }

    public uploadDamageDocument(
        damageId: number,
        file: File,
        description: string,
        documentTypeId: string | number
    ): Observable<unknown> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', description);
        formData.append('documentTypeId', documentTypeId + '');
        return this.http.upload(`${DAMAGE_URL}/${damageId}/DamageDocuments`, formData);
    }

    public getDamages(contract: Contract, cached = true): Observable<Damage[]> {
        const fromCache = this.damagesCache.get(contract.Id);
        if (fromCache && cached) {
            this.log.info('Returning damages from cache.');
            return of(fromCache);
        } else {
            this.log.info('Cache miss. Fetching ..');
            return this.http
                .get<Damage[]>(`${DAMAGE_URL}/${contract.Id}`, { notify: 'ERROR' })
                .pipe(
                    // TODO: Check what is returned if there are no damages, maybe this can be simplified.
                    map(val => (!Array.isArray(val) ? [] : val)),
                    map(damages => damages.map(damage => plainToClass(Damage, damage))),
                    tap(val => {
                        this.damagesCache.set(contract.Id, val);
                    })
                );
        }
    }

    public getAllDamages(contracts: Contract[], cached = true): Observable<Damage[]> {
        return from(contracts).pipe(flatMap(contract => this.getDamages(contract, cached)));
    }

    public updateDamageDocument(document: DamageDocument): Observable<DamageDocument> {
        return this.http
            .put<DamageDocument>(`${DAMAGE_URL}/${document.DamageId}/DamageDocuments/${document.Id}`, { notify: 'ALL' })
            .pipe(
                map(val => plainToClass(DamageDocument, val)),
                tap(val => this.cacheSingleDamageDocument(val))
            );
    }

    public getDamageDocumentsMeta(damage: Damage, cached = true): Observable<DamageDocument[]> {
        const fromCache = this.damageDocumentsCache.get(damage.Id);
        if (fromCache && cached) {
            this.log.info('Returning damageDocuments from cache.');
            return of(fromCache);
        } else {
            this.log.info('Cache miss. Fetching ..');
            return this.http
                .get<DamageDocument[]>(`${DAMAGE_URL}/${damage.Id}/DamageDocuments`, { notify: 'ERROR' })
                .pipe(
                    map(val => (!Array.isArray(val) ? [] : val)),
                    map(damageDocuments => damageDocuments.map(document => plainToClass(DamageDocument, document))),
                    tap(val => this.damageDocumentsCache.set(damage.Id, val))
                );
        }
    }

    public getDamageDocumentBlob(document: DamageDocument): Observable<Blob> {
        return this.http.get<Blob>(`${DAMAGE_URL}/${document.DamageId}/DamageDocuments/${document.Id}`, {
            responseType: 'blob',
            notify: 'ERROR',
        });
    }

    private cacheSingleDamage(d: Damage): void {
        const cachedContract = this.damagesCache.get(d.ContractId);
        if (cachedContract) {
            const i = cachedContract.findIndex(elm => elm.Id === d.Id);
            if (i === -1) {
                cachedContract.push(d);
            } else {
                cachedContract.splice(i, 1, d);
            }
        } else {
            this.damagesCache.set(d.ContractId, [d]);
        }
    }
    // TODO: this can be abstracted with cacheSingleDamage by inspection.
    private cacheSingleDamageDocument(d: DamageDocument): void {
        const cachedDocument = this.damageDocumentsCache.get(d.DamageId);
        if (cachedDocument) {
            const i = cachedDocument.findIndex(elm => elm.Id === d.Id);
            if (i === -1) {
                cachedDocument.push(d);
            } else {
                cachedDocument.splice(i, 1, d);
            }
        } else {
            // TODO: If we get here, we must reload all documents for the damage from !LN
            this.damageDocumentsCache.set(d.DamageId, [d]);
        }
    }

    public getDamageDocumentMetaById(d: Damage, id: number): Observable<DamageDocument> {
        return this.getDamageDocumentsMeta(d).pipe(
            switchMap(val => from(val)),
            first(val => val.Id === id)
        );
    }

    public postDamageNote(d: Damage, note: string): Observable<any> {
        return this.http.post(`${DAMAGE_URL}/${d.Id}/AddNote`, { Note: note });
    }

    public getDamageNotes(d: Damage): Observable<any> {
        return this.http.get<any>(`${DAMAGE_URL}/${d.Id}/Notes`, { notify: 'ERROR' });
    }
}
