import { Insurant } from '../models/insurant';
import { InsurantDto } from '../models/insurant-dto';

export function insurantToDto(insurant: Insurant): InsurantDto {
    return {
        Id: insurant.id,
        Firstname: insurant.firstName,
        Lastname: insurant.lastName,
        DateOfBirth: insurant.dateOfBirth,
        IsInsuree: insurant.isInsuree,
        InsuranceRankId: insurant.insuranceRankId ?? null,
        MissingTeeth: insurant.missingTeeth ?? null,
    };
}
