export class AlternateProduct {
    slug: string;
    label: string;
    isCurrent: boolean;

    constructor(slug: string, label: string) {
        this.slug = slug;
        this.label = label;
    }
}

export const enum PoolObjectKeys {
    Person = 'Person',
    ObjectDescription = 'ObjectDescription',
    ObjectDetail = 'ObjectDetail',
    ObjectValue = 'ObjectValue',
}

export class PoolObjectMandatoryData {
    public key: PoolObjectKeys;
    public label: String;
    public formErrorNote: String;

    constructor(key: PoolObjectKeys, label: String, formErrorNote: String = '') {
        this.key = key;
        this.label = label;
        this.formErrorNote = formErrorNote;
    }
}

export class InsuredObjectsPool {
    headline: string;
    description: string;
    objectNameSingular: string;
    objectNamePlural: string;
    addObjectButtonName: string;
    mandatoryData: PoolObjectMandatoryData[];
    showBulkServiceInfo: boolean;

    constructor(poolSettings: IInsuredObjectsPool) {
        this.headline = poolSettings.headline;
        this.description = poolSettings.description;
        this.objectNameSingular = poolSettings.objectNameSingular;
        this.objectNamePlural = poolSettings.objectNamePlural || poolSettings.objectNameSingular;
        this.addObjectButtonName = poolSettings.addObjectButtonName;
        this.mandatoryData = poolSettings.mandatoryData;
        this.showBulkServiceInfo = poolSettings.showBulkServiceInfo || false;
    }
}

export interface IInsuredObjectsPool {
    headline: string;
    description: string;
    objectNameSingular: string;
    objectNamePlural?: string;
    addObjectButtonName: string;
    mandatoryData: PoolObjectMandatoryData[];
    showBulkServiceInfo?: boolean;
}

export class Product {
    slug: string;
    title: string;
    fullTitle: string;
    insuranceTypeName: string;
    details: string;
    imageUrl: string;
    imageAlt: string;
    imageHeadline: string;
    taxInfo: string;
    requiresMedicalData: boolean;
    requiresAgentConfirmation: boolean;
    bulletPoints: string[] = [];
    wizardSteps: string[] = [];
    stepsCompleted: string[] = [];
    alternateSettlementPeriodProduct: string | null; // THIS
    docIPIDUrl: string;
    docAVBUrl: string;
    docAddInfoUrl: string;
    insurantMaxAge: number;
    hasFlexPrice: boolean = false;
    showPriceFrom: boolean = false;
    showDiscountInput: boolean = false;
    alternateProducts: AlternateProduct[] | null; // THIS
    insuredObjectsPool: InsuredObjectsPool | null; // THIS
    keepAfterCustomerChange: string[] = [];
    titleString: string | null = null;
    showB2BInfo: boolean = false;
    keepStepsOnVariantSwitch: boolean = false;

    constructor(productSettings: IProduct) {
        this.slug = productSettings.slug;
        this.title = productSettings.title;
        this.fullTitle = productSettings.fullTitle ? productSettings.fullTitle : productSettings.title;
        this.insuranceTypeName = productSettings.insuranceTypeName;
        this.details = productSettings.details ? productSettings.details : '';
        this.imageUrl = productSettings.imageUrl || '';
        this.imageAlt = productSettings.imageAlt || '';
        this.imageHeadline = productSettings.imageHeadline || '';
        this.taxInfo = productSettings.taxInfo || '';
        this.bulletPoints = Array.isArray(productSettings.bulletPoints) ? productSettings.bulletPoints : [];
        this.wizardSteps = Array.isArray(productSettings.wizardSteps) ? productSettings.wizardSteps : [];
        this.requiresMedicalData = !!productSettings.requiresMedicalData;
        this.requiresAgentConfirmation = !!productSettings.requiresAgentConfirmation;
        this.alternateSettlementPeriodProduct = productSettings.alternateSettlementPeriodProduct || null;
        this.docIPIDUrl = productSettings.docIPIDUrl || '';
        this.docAVBUrl = productSettings.docAVBUrl || '';
        this.docAddInfoUrl = productSettings.docAddInfoUrl || '';
        this.insurantMaxAge = productSettings.insurantMaxAge || 0;
        this.hasFlexPrice = productSettings.hasFlexPrice || false;
        this.showPriceFrom = productSettings.showPriceFrom || false;
        this.showDiscountInput = productSettings.showDiscountInput || false;
        this.alternateProducts = productSettings.alternateProducts || null;
        this.insuredObjectsPool = productSettings.insuredObjectsPool || null;
        this.keepAfterCustomerChange = productSettings.keepAfterCustomerChange || ['Insurants'];
        this.titleString = productSettings.titleString || null;
        this.showB2BInfo = productSettings.showB2BInfo || false;
        this.keepStepsOnVariantSwitch = productSettings.keepStepsOnVariantChange || false;
    }
}

export interface IProduct {
    slug: string;
    title: string;
    insuranceTypeName: string;

    fullTitle?: string;
    details?: string;
    imageUrl?: string;
    imageAlt?: string;
    imageHeadline?: string;
    taxInfo?: string;
    bulletPoints?: string[];
    wizardSteps?: string[];
    requiresMedicalData?: boolean;
    requiresAgentConfirmation?: boolean;
    alternateSettlementPeriodProduct?: string;
    docIPIDUrl?: string;
    docAVBUrl?: string;
    docAddInfoUrl?: string;
    insurantMaxAge?: number;
    hasFlexPrice?: boolean;
    showPriceFrom?: boolean;
    showDiscountInput?: boolean;
    alternateProducts?: AlternateProduct[];
    insuredObjectsPool?: InsuredObjectsPool;
    keepAfterCustomerChange?: string[];
    titleString?: string;
    showB2BInfo?: boolean;
    keepStepsOnVariantChange?: boolean;
}
