import { BillingAddress } from '../models/billing-address';
import { ContractRequestDto } from '../models/contract-request-dto';
import { Insurant } from '../models/insurant';
import { OrderState } from '../order/state/order.store';
import { dateToInsurenetDateString } from './date';
import { insuranceTypeToDto } from './insurance-type';
import { insurantToDto } from './insurant';
import { insuredObjectToDto } from './insured-object';

export function orderStateToContractRequestDto(state: OrderState): ContractRequestDto {
    return {
        // => BillingAddress
        Title:
            state.billingAddress && state.billingAddress.title
                ? { Id: state.billingAddress.title.id, Description: state.billingAddress.title.description }
                : null,
        Company: state.billingAddress?.company || null,
        Firstname: state.billingAddress?.firstName || '',
        Lastname: state.billingAddress?.lastName || '',
        Street: state.billingAddress?.street || '',
        ZIP: state.billingAddress?.zip || '',
        City: state.billingAddress?.city || '',
        Country: { Country: 'Deutschland', Id: 1 }, // TODO: what about this?
        Phone: state.billingAddress?.phone || '',
        Email: state.billingAddress?.mail || '',
        DateOfBirth: state.billingAddress?.dateOfBirth || '',

        // => PaymentData
        AccountHolder: state.payment?.accountHolder || '',
        IBAN: state.payment?.iban || '',
        // TODO: ask sinovo to remove this:
        AccountNo: '',
        Bankcode: '',
        NameOfBank: state.payment?.nameOfBank || '',

        // contract data
        InsuranceType: insuranceTypeToDto(state.insuranceType),
        ContractStart: state.contract?.contractStart || dateToInsurenetDateString(new Date()),
        ContractPeriod: state.contract?.contractPeriod || 0,
        LegalHealthInsurance: state.contract?.legalHealthInsurance || '',
        Newsletter: false, // TODO: add newsletter option or ask sinovo to remove this
        DiscountCode: state.contract?.discountCode || '',

        // TODO: can we scrap that and make it a pool object? dunno
        ...getInstrumentPremiumData(state),

        // insurants:
        Insurants: (state.insurants && state.insurants.length
            ? state.insurants
            : [insurantFromBillingAddress(state.billingAddress)]
        ).map(insurant => insurantToDto(insurant)),
        InsurantsFamilyRelationship: !!state.insurantsFamilyRelationship,

        // object pool:
        ObjectPool: (state.insuredObjects || []).map(insuredObject => insuredObjectToDto(insuredObject)),

        // reseller data:
        ResellerUrl: state.resellerUrl ?? '',
        AdditionalResellerData: state.additionalResellerData ?? '',
    };
}

function getInstrumentPremiumData(state: OrderState): { InsuranceObjectId: number | null; ObjectValue: number | null } {
    if (
        state.insuranceType.name === 'Musik' &&
        state.insuredObjects &&
        state.insuredObjects[0] &&
        typeof state.insuredObjects[0].description === 'number' &&
        typeof state.insuredObjects[0].value === 'number'
    ) {
        return {
            InsuranceObjectId: state.insuredObjects[0].description,
            ObjectValue: state.insuredObjects[0].value,
        };
    }
    return {
        InsuranceObjectId: null,
        ObjectValue: null,
    };
}

function insurantFromBillingAddress(
    billingAddress: Partial<BillingAddress> = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
    }
): Insurant {
    return {
        firstName: billingAddress.firstName || '',
        lastName: billingAddress.lastName || '',
        dateOfBirth: billingAddress.dateOfBirth || '',
        isInsuree: true,
        isChild: false,
    };
}
