import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versichertes-geraet', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    requiresNoPriorDefect: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    hideSummarySidebarOnLandingPage: true,
    headerMode: 'conrad',
};

const presentation: ProductPresentation = {
    description: `
        <p class="semibold">
            Unsere Elektronikversicherung schützt nahezu all Ihre privaten Geräte vor möglichen 
            Risiken und Schäden.
        </p>
        <p>
            Ganz gleich ob Schäden am Display, eine Beschädigung durch Dritte oder Bedienungsfehler: 
            Die astra Versicherung für Elektronikgeräte greift im Notfall ein und schützt Sie vor 
            unerwarteten hohen Kosten. 
        </p>
    `,
    bulletPoints: [],
    imageUrl: 'assets/img/product-headers/astra_versicherung_conrad.jpg',
    imageAlt: 'Conrad-Elektronikversicherung by astra',
    imageHeadline: '<h3 class="no-margin-bottom">Elektrogerät ohne Selbstbeteiligung versichern</h3>',
    docIPIDUrl: 'astra_IPID_Elektronik_Endkunden_2022.pdf',
    docAVBUrl: 'astra_AVB_Elektronik_Endkunden_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Elektronik_Endkunden_2022.pdf',
    //pageTitle: 'Seitentitel (optional)',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Zu versicherndes Gerät',
    description: '',
    objectDescriptionSingular: 'Gerät',
    max: 1,
    controls: [
        {
            name: 'type',
            label: 'Art',
            type: 'text',
        },
        {
            name: 'description',
            label: 'Hersteller',
            type: 'text',
        },
        {
            name: 'model',
            label: 'Modell',
            type: 'text',
        },
        {
            name: 'value',
            label: 'Kaufpreis',
            type: 'value',
        },
        {
            name: 'detail',
            label: 'Seriennummer',
            type: 'text',
        },
    ],
};

export const conradB2C: Product = new Product({
    slug: 'cnrdb2c',
    title: 'Conrad-Elektronikversicherung',
    fullTitle: 'Conrad-Elektronikversicherung by astra',
    insuranceTypeName: 'Elektronikversicherung B2C',
    config,
    presentation,
    insuredObjectsForm,
});

export interface ConradDevice {
    name: string;
    icon: string;
    displayName: string;
    displayNameDative: string;
    manufacturers: string[];
}

export const lensManufacturers: string[] = [
    'Canon',
    'Sony',
    'Olympus',
    'Walimex',
    'Samyang',
    'Nikon',
    'Fujifilm',
    'Panasonic',
];

export const conradDevices: ConradDevice[] = [
    {
        name: 'Smartphone',
        icon: '/assets/icons/conrad/icon_handy.svg',
        displayName: 'Smartphone',
        displayNameDative: 'des Smartphones',
        manufacturers: ['Apple', 'Google', 'HUAWEI', 'Motorola', 'Realme', 'Samsung', 'Sony', 'Xiaomi'],
    },
    {
        name: 'Smartwatch',
        icon: '/assets/icons/conrad/icon_smartwatch.svg',
        displayName: 'Smartwatch',
        displayNameDative: 'der Smartwatch',
        manufacturers: ['Apple', 'FitBit', 'Garmin', 'HUAWEI', 'Polar', 'Samsung', 'Xplora', 'X-WATCH'],
    },
    {
        name: 'Kamera',
        icon: '/assets/icons/conrad/icon_kamera.svg',
        displayName: 'Kamera',
        displayNameDative: 'der Kamera',
        manufacturers: ['Canon', 'Fujifilm', 'GoPro', 'Lamax', 'Nikon', 'Olympus', 'Panasonic', 'Sony'],
    },
    {
        name: 'Notebook',
        icon: '/assets/icons/conrad/icon_notebook.svg',
        displayName: 'Notebook',
        displayNameDative: 'des Notebooks',
        manufacturers: ['Acer', 'Apple', 'Asus', 'Dell', 'Fujitsu', 'HP', 'Lenovo', 'Samsung'],
    },
    {
        name: 'Beamer',
        icon: '/assets/icons/conrad/icon_beamer.svg',
        displayName: 'Beamer',
        displayNameDative: 'des Beamers',
        manufacturers: ['Acer', 'Asus', 'BenQ', 'Epson', 'NEC', 'Panasonic', 'Philips', 'Viewsonic'],
    },
    {
        name: 'Haushaltsgerät',
        icon: '/assets/icons/conrad/icon_haushalt.svg',
        displayName: 'Haushaltsgerät',
        displayNameDative: 'des Haushaltsgerätes',
        manufacturers: ['Waschmaschine', 'Kühlschrank', 'Kochfeld', 'Kaffeemaschine', 'Trockner'],
    },
    {
        name: 'Fernseher',
        icon: '/assets/icons/conrad/icon_tv.svg',
        displayName: 'Fernseher',
        displayNameDative: 'des Fernsehers',
        manufacturers: ['Dyon', 'JayTech', 'JTC', 'LG', 'Panasonic', 'Samsung', 'Sony', 'Telefunken'],
    },
    {
        name: 'PC / Monitor',
        icon: '/assets/icons/conrad/icon_pc_system.svg',
        displayName: 'PC / Monitor',
        displayNameDative: 'des PCs / Monitors',
        manufacturers: [
            'Acer',
            'Apple',
            'Asus',
            'BenQ',
            'Dell',
            'Fujitsu',
            'HP',
            'Joy it',
            'Lenovo',
            'LG',
            'Samsung',
            'Viewsonic',
        ],
    },
    {
        name: 'Konsole',
        icon: '/assets/icons/conrad/icon_controller.svg',
        displayName: 'Konsole',
        displayNameDative: 'der Konsole',
        manufacturers: ['Aerosoft', 'Hama', 'Microsoft', 'Nintendo', 'Sony', 'Steelplay', 'Steelseries', 'Logitech'],
    },
    {
        name: 'VR-Brille',
        icon: '/assets/icons/conrad/icon_vr_brille.svg',
        displayName: 'VR-Brille',
        displayNameDative: 'der VR-Brille',
        manufacturers: ['Basetech', 'Celexon', 'HP', 'HTC', 'Renkforce'],
    },
    {
        name: 'Kopfhörer',
        icon: '/assets/icons/conrad/icon_kopfhoerer.svg',
        displayName: 'Kopfhörer',
        displayNameDative: 'des Kopfhörers',
        manufacturers: ['AKG', 'Apple', 'Beats', 'Hama', 'JBL', 'Logitech', 'Sennheiser', 'Sony'],
    },
    {
        name: 'Tablet',
        icon: '/assets/icons/conrad/icon_tablet.svg',
        displayName: 'Tablet',
        displayNameDative: 'des Tablets',
        manufacturers: ['Acer', 'Apple', 'Hannspree', 'HUAWEI', 'Lenovo', 'Microsoft', 'Odys', 'Samsung'],
    },
];
