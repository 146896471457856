import { Product, ProductConfig, ProductPresentation, Variants } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-person', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Steuerfrei nach § 4 Abs. 1 Nr. 5 VersStG',
    hasFlexPrice: false,
    requiresMedicalData: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    insurantMaxAge: 60,
};

const presentation: ProductPresentation = {
    description: `
        <p><strong>Reise-Auslandskrankenversicherung</strong></p>
        <p>Versichert durch astra Versicherung AG</p>
        <p>Die astra-Auslandskrankenversicherung – gut versichert im Urlaub, gut versichert auf Geschäfts- und Bildungsreisen.</p>
        <p>Wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
        <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
        <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel.</p>
        <ul>
            <li>Weltweiter Versicherungsschutz vor unvorhergesehen Krankheitskosten inkl. USA und Kanada</li>
            <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
            <li>Kostenerstattung auch für Überführung im Todesfall</li>
        </ul>
        <p>Die günstige Reise-Krankenversicherung für nur 8,<sup>90</sup> <span class="currency-sign">€</span>.</p>
    `,
    bulletPoints: [
        'Weltweiter Versicherungsschutz im Ausland bei unvorhergesehener Krankheit oder Unfall',
        'Inkl. Schutz bei Corona-Infektion',
        'Freie Arztwahl und freie Auswahl eines Krankenhauses',
        'Inklusive zahnärztlicher Behandlung',
        'Inklusive Kostenerstattung für Rücktransport',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherung_Reise_und_Auslandskrankenversicherung.jpg',
    imageAlt: 'astra Auslandsschutz - Auf Reisen gut versichert - Top abgesichert im Ausland',
    imageHeadline:
        '<h3>Auslandsschutz</h3><p class="no-margin-bottom">Auf Reisen gut versichert<br>Top abgesichert im Ausland</p>',
    docIPIDUrl: 'astra_IPID_AKV_EF_2022.pdf',
    docAVBUrl: 'astra_AVB_AKV_EF_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_AKV_EF_2022.pdf',
};

const variants: Variants = {
    label: 'Wählen Sie den Tarif:',
    options: [
        {
            productSlug: 'reise-auslandskrankenversicherung-familie',
            title: 'Familie',
            isSelf: false,
        },
        {
            productSlug: 'reise-auslandskrankenversicherung-paare',
            title: 'Paare',
            isSelf: false,
        },
        {
            productSlug: 'reise-auslandskrankenversicherung',
            title: 'Single',
            isSelf: true,
        },
    ],
    showOn: 'productInfo',
    keepOnToggle: [
        'billingAddress',
        'completedSteps',
        'currentStep',
        'contract',
        'payment',
        'additionalResellerData',
        'resellerUrl',
    ],
    removeCompletedSteps: ['versicherte-personen', 'versicherte-person'],
};

export const auslandsKvSingle: Product = new Product({
    slug: 'reise-auslandskrankenversicherung',
    title: 'Reise-Auslandskrankenversicherung',
    fullTitle: 'Reise-Auslandskrankenversicherung',
    insuranceTypeName: 'AR',
    config,
    presentation,
    variants,
});
