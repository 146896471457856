import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { errorMessages } from './errorMessage.const';
import { ErrorMessage } from './errorMessage.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-error-view',
    templateUrl: './error-view.component.html',
    styleUrls: ['./error-view.component.scss'],
})
export class ErrorViewComponent implements OnDestroy {
    public errorMessage: ErrorMessage;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private route: ActivatedRoute) {
        this.route.params
            .pipe(
                takeUntil(this.unsubscribe$),
                map(val => errorMessages.find((item: ErrorMessage) => item.errCode == val['errCode']))
            )
            .subscribe(val => (this.errorMessage = val));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
