import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';
import { Contract } from './contract.model';
import { FullContract } from './fullContract.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { first, map, switchMap, tap, filter } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { from } from 'rxjs/internal/observable/from';
import { DamageService } from '../damage/damage.service';
import { AuthService } from '../auth/auth.service';

const GET_CONTRACTS_URL = environment.basePath + environment.contracts;
const INSURANCE_DOC_URL_PART = environment.insuranceDoc;

@Injectable()
export class ContractService {
    private _contracts: Contract[] = [];

    constructor(
        private http: HttpService,
        private damage: DamageService,
        private log: LoggerService,
        private auth: AuthService
    ) {
        this.auth.isLoggedIn$.pipe(filter(val => !val)).subscribe(val => {
            this.log.info('User logged out!');
            this._contracts = [];
        });
    }

    public get contracts(): Observable<Contract[]> {
        if (this._contracts.length < 2) {
            this.log.info('no cached contracts, fetching ..');
            return this.getContracts();
        } else {
            this.log.info('Returning contracts from cache.');
            return of(this._contracts);
        }
    }

    public getContract(id: number, forceFetch = false): Observable<Contract> {
        if (this._contracts.length === 0 || forceFetch === true) {
            return this.getContracts().pipe(
                switchMap(contracts => from(contracts)),
                first(contract => contract.Id === id)
            );
        } else {
            return from(this._contracts).pipe(first(contract => contract.Id === id));
        }
    }

    public getFullContract(contractId: number, damageId: number): Observable<FullContract> {
        const getDamage = (contract: Contract, damageId: number) =>
            this.damage.getDamages(contract).pipe(
                switchMap(damages => from(damages)),
                first(damage => damage.Id === damageId)
            );
        return this.getContract(contractId).pipe(
            switchMap(contract => getDamage(contract, damageId).pipe(map(val => ({ contract, damage: val })))),
            switchMap(val =>
                this.damage
                    .getDamageDocumentsMeta(val.damage)
                    .pipe(map(res => ({ contract: val.contract, damage: val.damage, documents: res })))
            )
        );
    }

    public getInsuranceDocument(contract: Contract): Observable<Blob> {
        const url = `${GET_CONTRACTS_URL}/${contract.Id}/${INSURANCE_DOC_URL_PART}`;
        const headers = new HttpHeaders().set('Accept', 'application/pdf');
        const responseType = 'blob';
        return this.http.get<Blob>(url, { headers, responseType, retryCount: 2 });
    }

    public refresh(): void {
        if (this.auth.isLoggedInStatus) this.getContracts().subscribe();
    }

    private getContracts(): Observable<Contract[]> {
        return this.http
            .get<Contract[]>(GET_CONTRACTS_URL, { retryCount: 2, notify: 'ERROR' })
            .pipe(
                map(contracts => contracts.map(contract => plainToClass(Contract, contract))),
                tap(res => (this._contracts = res))
            );
    }
}
