<div class="content-container clearfix double-gutter">
  <div class="grid-container double-gutter-bottom">
      <div class="grid-1-1">
          <h3>{{ errorMessage.headline }}</h3>
          <hr>
      </div>
  </div>
  <div class="grid-container">
      <div class="grid-1-1" [innerHtml]="errorMessage.message">
          
      </div>
  </div>
</div>
