import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { pluck, filter, map } from 'rxjs/operators';
import { OrderQuery } from '../../order/state/order.query';
import { HeaderModeService } from '../header-mode/header-mode.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    constructor(public headerModeService: HeaderModeService) {}
}
