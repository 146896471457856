import { Pipe, PipeTransform } from '@angular/core';
import { products } from '../../config/products';

@Pipe({
    name: 'poolObjectMemberName',
})
export class PoolObjectMemberNamePipe implements PipeTransform {
    transform(value: any, insuranceType?: string): any {
        if (typeof value !== 'string' || typeof insuranceType !== 'string') return null;
        const product = products.find(item => item.insuranceTypeName.toUpperCase() === insuranceType.toUpperCase());
        if (product && product.insuredObjectsForm && product.insuredObjectsForm.controls.length) {
            const member = product.insuredObjectsForm.controls.find(
                // TODO: get rid of that ugly hack after mapping dto to actual model:
                item =>
                    'OBJECT' + item.name.toUpperCase() == value.toUpperCase() ||
                    item.name.toUpperCase() === value.toUpperCase()
            );
            return member ? member.label : 'missing';
        }
        return 'no matching product';
    }
}
