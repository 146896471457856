import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../core/notification/notification.service';
import { NotificationType } from '../../core/notification/notification-type.enum';

@Component({
    selector: 'app-data-protection',
    templateUrl: './data-protection.component.html',
    styleUrls: ['./data-protection.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DataProtectionComponent implements OnInit {
    @Input()
    public privacyStatementHtml: string = '<p>wird geladen...</p>';

    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        fetch(environment.dataProtectionUrl)
            .then(resp => {
                if (resp.status === 200 && resp.ok === true) {
                    return resp.json();
                } else {
                    throw new Error('unable to get privacy statement from script');
                }
            })
            .then((data: { html: string; css: string; langjs: string }) => {
                this.privacyStatementHtml = data.html;
            })
            .catch(() => {
                this.notificationService.notify('Fehler bei Aufruf des DSE', NotificationType.CRITICAL);
            });
    }
}
