import {
    Product,
    AlternateProduct,
    InsuredObjectsPool,
    PoolObjectMandatoryData,
    PoolObjectKeys,
} from '../core/products/product.model';

// TODO: remove alternateSettlementPeriodProduct!

export const productsAvailableForCompanies: string[] = [
    'musikinstrumentenversicherung-schulen-p',
    'musikinstrumentenversicherung-schulen-k',
    'tablet-schueler',
    'tablet-versicherung-plus',
];

const tabletPoolData: InsuredObjectsPool = new InsuredObjectsPool({
    headline: 'Angaben zu versicherten Geräten',
    description: `
        <p>Bitte machen Sie einige Angaben, zu dem/den Gerät/en, die Sie versichern möchten.
        Sie können jederzeit ein weiteres Gerät hinzufügen, in dem Sie auf die Schaltfläche "Weiteres Gerät hinzufügen" klicken.
        Der Beitrag Ihrer Versicherung bemisst sich nach der Anzahl versicherter Geräte.</p>
    `,
    objectNameSingular: 'Gerät',
    objectNamePlural: 'Geräte',
    addObjectButtonName: 'Weiteres Gerät hinzufügen',
    showBulkServiceInfo: true,
    mandatoryData: [
        new PoolObjectMandatoryData(PoolObjectKeys.ObjectDescription, 'Hersteller'),
        new PoolObjectMandatoryData(PoolObjectKeys.ObjectDetail, 'Seriennummer'),
        new PoolObjectMandatoryData(PoolObjectKeys.Person, 'Hauptnutzer'),
    ],
});

const tabletPlusPoolData: InsuredObjectsPool = new InsuredObjectsPool({
    headline: 'Angaben zu versicherten Geräten',
    description: `
        <p>Bitte machen Sie einige Angaben, zu dem/den Gerät/en, die Sie versichern möchten.
        Sie können jederzeit ein weiteres Gerät hinzufügen, in dem Sie auf die Schaltfläche "Weiteres Gerät hinzufügen" klicken.
        Der Beitrag Ihrer Versicherung bemisst sich nach Anzahl und Neupreis der versicherten Geräte.</p>
    `,
    objectNameSingular: 'Gerät',
    objectNamePlural: 'Geräte',
    addObjectButtonName: 'Weiteres Gerät hinzufügen',
    showBulkServiceInfo: true,
    mandatoryData: [
        new PoolObjectMandatoryData(
            PoolObjectKeys.ObjectDescription,
            'Hersteller',
            'Bitte geben Sie einen Hersteller an.'
        ),
        new PoolObjectMandatoryData(
            PoolObjectKeys.ObjectValue,
            'Kaufpreis',
            'Es können nur Geräte bis zu einem Kaufpreis von 1.500 € versichert werden.'
        ),
        new PoolObjectMandatoryData(PoolObjectKeys.ObjectDetail, 'Seriennummer'),
    ],
});

const musicInstrumentPoolData: InsuredObjectsPool = new InsuredObjectsPool({
    headline: 'Angaben zu versicherten Instrumenten',
    description: `
        <p><strong>Bitte wählen Sie zunächst die gewünschte Vertragsart.</strong></p>
        <p>Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne
        Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten.
        Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest
        an einen einzigen Schüler verleihen oder ausgeben.</p>
    `,
    objectNameSingular: 'Instrument',
    objectNamePlural: 'Instrumente',
    addObjectButtonName: 'Weiteres Instrument hinzufügen',
    mandatoryData: [
        new PoolObjectMandatoryData(PoolObjectKeys.ObjectDescription, 'Instrumentenart'),
        new PoolObjectMandatoryData(PoolObjectKeys.ObjectDetail, 'Hersteller'),
    ],
});

const musicInstrumentPupilData: InsuredObjectsPool = new InsuredObjectsPool({
    headline: 'Angaben zu versicherten Schülern',
    description: `
        <p><strong>Bitte wählen Sie zunächst die gewünschte Vertragsart.</strong></p>
        <p>Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne
        Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten.
        Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest
        an einen einzigen Schüler verleihen oder ausgeben.</p>
    `,
    objectNameSingular: 'Schüler',
    addObjectButtonName: 'Weiteren Schüler hinzufügen',
    mandatoryData: [new PoolObjectMandatoryData(PoolObjectKeys.Person, 'Nachname, Vorname')],
});

export const products: Product[] = [
    //#region Schutzbrief Students
    new Product({
        slug: 'schutzbrief-students',
        title: 'Schutzbrief Students',
        fullTitle: 'Schutzbrief Students',
        insuranceTypeName: 'Schutzbrief Students',
        details: `
            <p><strong>Auslandskrankenversicherung für Auslandsaufenthalte zur schulischen und universitären Weiterbildung</strong></p>
            <p>Versichert durch astra Versicherung AG</p>
            <p>Die flexible Auslandskrankenversicherung mit weltweitem Geltungsbereich speziell für Schüler, Studenten, Praktikanten, Doktoranten sowie Au Pair- und Work ’n’ Travel-Aufenthalte für nur 38,<sup>99</sup> <span class="currency-sign">€</span> pro Monat.</p>
            <p>Wer sich längerfristig im Ausland aufhält, ist mit dieser speziellen Versicherung gut beraten. Denn wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
            <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
            <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel. Dabei ist die Vertragslaufzeit ab 1 Monat bis zu 12 Monaten frei wählbar.</p>
            <ul>
                <li>Weltweiter Schutz vor Krankheitskosten bei unvorhergesehener Krankheit oder Unfall im Ausland</li>
                <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
                <li>Kostenerstattung auch für Überführung im Todesfall</li>
            </ul>
            <p>Die astra-Auslandskrankenversicherung - mit individuell wählbarer Vertragslaufzeit - speziell für Auslandsaufenthalte zur schulischen und universitären Weiterbildung sowie Au Pair- und Work ’n’ Travel-Aufenthalte.</p>
        `,
        bulletPoints: [
            'Der Versicherungsschutz für Auslandsaufenthalte bei unvorhergesehener Krankheit oder Unfall',
            'Inklusive zahnärztlicher Behandlungen',
            'Freie Arztwahl und freie Auswahl eines Krankenhauses, inklusive Kostenerstattung für Rücktransport',
            'Flexible Vertragslaufzeiten: Ab 1 Monat bis zu 12 Monaten',
        ],
        wizardSteps: [
            '',
            'laufzeit',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherung_Schutzbrief_Students.jpg',
        imageAlt: 'astra Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
        imageHeadline: 'Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
        taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_Auslandskrankenschutz.pdf',
        docAVBUrl: 'astra_AVB_Auslandskrankenschutz.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Auslandskrankenschutz.pdf',
        keepAfterCustomerChange: ['Insurants', 'ContractPeriod'],
        showDiscountInput: true,
    }),
    //#endregion
    //#region Tablet- und Notebook-Versicherung
    new Product({
        slug: 'tablet-schueler',
        title: 'Tablet- und Notebook-Versicherung',
        fullTitle: 'Tablet- und Notebook-Versicherung für Schüler und Schulen',
        insuranceTypeName: 'Tablet Schüler',
        details: `
            <p><strong class="blue">Die Tablet- und Notebook-Versicherung für Schüler und digitale Klassen:</strong></p>
            <p>Ob Sie ein einzelnes Gerät versichern möchten, das in Ihrem Besitz ist, oder eines, das Ihnen leihweise von der Schule überlassen wurde, ob Sie Schulträger oder Förderverein sind und gleich mehrere Geräte gut geschützt wissen wollen – dann ist die speziell für Schüler konzipierte Tablet- und Notebook-Versicherung genau das Richtige für Sie.</p>
            <p>Zum günstigen Tarif bestens gegen alle denkbaren Schäden im schulischen Umfeld geschützt: Neben dem klassischen Versicherungsschutz bei Brand, Blitzschlag, Explosion oder Wasserschäden deckt die Tablet- und Notebook-Versicherung von astra nämlich auch wahrscheinlichere Schäden wie z.B. Verlust, Diebstahl und sogar versehentliches Liegenlassen oder Herunterfallen ab.</p>
            <p><strong class="blue">Der umfassende Versicherungsschutz für Schüler oder die ganze Klasse für beliebig viele elektronische Leihgeräte. Schnell und einfach zum Vertrag - für nur 7,<sup>99</sup> <span class="currency-sign">€</span> im Jahr pro Notebook oder Tablet.</strong></p>
        `,
        bulletPoints: [
            'Ein Vertrag für beliebig viele Endgeräte',
            'Alle Risiken innerhalb der Schule sind abgedeckt',
            'Deckung bei Totalschaden, Verlust, Diebstahl, Liegenlassen, Herunterfallen u.v.m.',
        ],
        wizardSteps: [
            '',
            'geraete-details',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/header_tablet.jpg',
        imageAlt: 'Die astra Tablet- und Notebook-Versicherung für Schüler und Schulen',
        imageHeadline:
            '<h3 class="no-margin-bottom">Die Tablet- und Notebook-Versicherung für Schüler und Schulen</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Tablet.pdf',
        docAVBUrl: 'astra_AVB_Tablet.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Tablet.pdf',
        hasFlexPrice: true,
        showPriceFrom: true,
        insuredObjectsPool: tabletPoolData,
        keepAfterCustomerChange: ['ObjectPool'],
    }),
    //#endregion
    //#region Schüler-AKV
    new Product({
        // same product as 'Schutzbrief Students', but different name.
        slug: 'schueler-auslandskrankenversicherung',
        title: 'Schüler-Auslandskrankenversicherung',
        fullTitle:
            'Auslandskrankenversicherung für Auslandsaufenthalte zur schulischen und universitären Weiterbildung',
        insuranceTypeName: 'Schutzbrief Students',
        details: `
            <p><strong>Auslandskrankenversicherung für Auslandsaufenthalte zur schulischen und universitären Weiterbildung</strong></p>
            <p>Versichert durch astra Versicherung AG</p>
            <p>Die flexible Auslandskrankenversicherung mit weltweitem Geltungsbereich speziell für Schüler, Studenten, Praktikanten, Doktoranten sowie Au Pair- und Work ’n’ Travel-Aufenthalte für nur 38,<sup>99</sup> <span class="currency-sign">€</span> pro Monat.</p>
            <p>Wer sich längerfristig im Ausland aufhält, ist mit dieser speziellen Versicherung gut beraten. Denn wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
            <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
            <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel. Dabei ist die Vertragslaufzeit ab 1 Monat bis zu 12 Monaten frei wählbar.</p>
            <ul>
                <li>Weltweiter Schutz vor Krankheitskosten bei unvorhergesehener Krankheit oder Unfall im Ausland</li>
                <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
                <li>Kostenerstattung auch für Überführung im Todesfall</li>
            </ul>
            <p>Die astra-Auslandskrankenversicherung - mit individuell wählbarer Vertragslaufzeit - speziell für Auslandsaufenthalte zur schulischen und universitären Weiterbildung sowie Au Pair- und Work ’n’ Travel-Aufenthalte.</p>
        `,
        bulletPoints: [
            'Der Versicherungsschutz für Auslandsaufenthalte bei unvorhergesehener Krankheit oder Unfall',
            'Inklusive zahnärztlicher Behandlungen',
            'Freie Arztwahl und freie Auswahl eines Krankenhauses, inklusive Kostenerstattung für Rücktransport',
            'Flexible Vertragslaufzeiten: Ab 1 Monat bis zu 12 Monaten',
        ],
        wizardSteps: [
            '',
            'laufzeit',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherung_Schutzbrief_Students.jpg',
        imageAlt: 'astra Versicherung Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
        imageHeadline: 'Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
        taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_Auslandskrankenschutz.pdf',
        docAVBUrl: 'astra_AVB_Auslandskrankenschutz.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Auslandskrankenschutz.pdf',
        keepAfterCustomerChange: ['Insurants', 'ContractPeriod'],
        showDiscountInput: true,
    }),
    //#endregion
    //#region Auslandskrankenversicherung Single
    new Product({
        slug: 'reise-auslandskrankenversicherung',
        title: 'Reise-Auslandskrankenversicherung',
        fullTitle: 'Reise-Auslandskrankenversicherung',
        insuranceTypeName: 'AR',
        details: `
            <p><strong>Reise-Auslandskrankenversicherung</strong></p>
            <p>Versichert durch astra Versicherung AG</p>
            <p>Die astra-Auslandskrankenversicherung – gut versichert im Urlaub, gut versichert auf Geschäfts- und Bildungsreisen.</p>
            <p>Wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
            <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
            <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel.</p>
            <ul>
                <li>Weltweiter Versicherungsschutz vor unvorhergesehen Krankheitskosten inkl. USA und Kanada</li>
                <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
                <li>Kostenerstattung auch für Überführung im Todesfall</li>
            </ul>
            <p>Die günstige Reise-Krankenversicherung für nur 8,<sup>90</sup> <span class="currency-sign">€</span>.</p>
        `,
        bulletPoints: [
            'Weltweiter Versicherungsschutz im Ausland bei unvorhergesehener Krankheit oder Unfall',
            'Inkl. Schutz bei Corona-Infektion',
            'Freie Arztwahl und freie Auswahl eines Krankenhauses',
            'Inklusive zahnärztlicher Behandlung',
            'Inklusive Kostenerstattung für Rücktransport',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherung_Reise_und_Auslandskrankenversicherung.jpg',
        imageAlt: 'astra Auslandsschutz - Auf Reisen gut versichert - Top abgesichert im Ausland',
        imageHeadline:
            '<h3>Auslandsschutz</h3><p class="no-margin-bottom">Auf Reisen gut versichert<br>Top abgesichert im Ausland</p>',
        taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_AKV_EF_2021.pdf',
        docAVBUrl: 'astra_AVB_AKV_EF_2021.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_AKV_EF_2021.pdf',
        alternateProducts: [
            new AlternateProduct('reise-auslandskrankenversicherung-familie', 'Familie'),
            new AlternateProduct('reise-auslandskrankenversicherung', 'Single'),
        ],
        insurantMaxAge: 60,
    }),
    //#endregion
    //#region Auslandskrankenversicherung Familie
    new Product({
        slug: 'reise-auslandskrankenversicherung-familie',
        title: 'Reise-Auslandskrankenversicherung Familie',
        fullTitle: 'Reise-Auslandskrankenversicherung für Familien',
        insuranceTypeName: 'AKV',
        details: `
            <p><strong>Reise-Auslandskrankenversicherung für die ganze Familie:</strong></p>
            <p>Die günstige Auslandskrankenversicherung von astra für bis zu zwei Erwachsene und 5 im Haushalt lebende Kinder unter 18 Jahre.</p>
            <p>Die astra-Auslandskrankenversicherung – gut versichert im Familienurlaub, gut versichert auf Bildungsreisen mit der Familie.</p>
            <p>Wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
            <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
            <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel.</p>
            <ul>
                <li>Weltweiter Versicherungsschutz vor unvorhergesehen Krankheitskosten inkl. USA und Kanada</li>
                <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
                <li>Kostenerstattung auch für Überführung im Todesfall</li>
            </ul>
            <p>Die günstige Reise-Krankenversicherung für nur 21,<sup>00</sup> <span class="currency-sign">€</span>.</p>
        `,
        bulletPoints: [
            'Weltweiter Versicherungsschutz im Ausland bei unvorhergesehener Krankheit oder Unfall',
            'Inkl. Schutz bei Corona-Infektion',
            'Freie Arztwahl und freie Auswahl eines Krankenhauses',
            'Inklusive zahnärztlicher Behandlung',
            'Inklusive Kostenerstattung für Rücktransport',
        ],
        wizardSteps: [
            '',
            'akv-versicherte-personen',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherung_Reise_und_Auslandskrankenversicherung.jpg',
        imageAlt: 'astra Auslandsschutz - Auf Reisen gut versichert - Top abgesichert im Ausland',
        imageHeadline:
            '<h3>Auslandsschutz</h3><p class="no-margin-bottom">Auf Reisen gut versichert<br>Top abgesichert im Ausland</p>',
        taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_AKV_EF_2021.pdf',
        docAVBUrl: 'astra_AVB_AKV_EF_2021.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_AKV_EF_2021.pdf',
        alternateProducts: [
            new AlternateProduct('reise-auslandskrankenversicherung-familie', 'Familie'),
            new AlternateProduct('reise-auslandskrankenversicherung', 'Single'),
        ],
        insurantMaxAge: 60,
    }),
    //#endregion
    //#region Legacy Bike Insurance
    new Product({
        slug: 'schueler-fahrrad-versicherung-old',
        title: 'Schüler-Fahrrad-Versicherung',
        fullTitle: 'Schüler-Fahrrad-Versicherung',
        insuranceTypeName: 'Fahrrad Schüler',
        details: `
            <p><strong>Schüler-Fahrrad-Versicherung</strong></p>
            <p>Versichert durch die astra Versicherung AG</p>
            <p><strong>Bike, Roller, Kickboards: der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung für nur 9,<sup>99</sup> <span class="currency-sign">€</span> im Jahr.</strong></p>
            <p>Wer kennt die Geschichte nicht – ewig Taschengeld gespart und noch mit finanzieller Hilfe von Eltern und Verwandtschaft endlich das Traumbike gekauft… ein Desaster, wenn dann das neue Fahrrad trotz Schloss vom Schulhof geklaut wird oder beschädigt wird.</p>
            <p>Dafür gibt es nun speziell für Schüler den erschwinglichen Diebstahl- und Reparatur-Schutz für die Fahrten zur Schule, während des Unterrichts und sonstigen schulischen Veranstaltung wie Fahrten zum Sportunterricht, Kirche oder Schwimmbad.</p>
            <p>Diese Schüler-Fahrrad-Versicherung, die auch für Roller und Kickboards gilt, ist ideal um seinen mobilen Untersatz auf dem Weg zur Schule und nach Hause sowie während des Unterricht zu schützen.</p>
            <p><strong>Der Schutz für Bikes, Roller und Kickboards für nur 9,<sup>99</sup> <span class="currency-sign">€</span> im Jahr</strong></p>
        `,
        bulletPoints: [
            'Die Schüler-Fahrrad-Versicherung mit Reparatur- und Diebstahlschutz',
            'Plus Schutz bei Beschädigung und Zerstörung',
            'Inkl. Versicherungsschutz für Roller',
            'Inkl. Versicherungsschutz für Kickboards',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherungen_Schüler-Fahrrad-Versicherung.jpg',
        imageAlt: 'Die Fahrrad-Versicherung speziell für Schüler. Von astra Versicherungen',
        imageHeadline: '<h3 class="no-margin-bottom">Die Fahrrad-Versicherung speziell für Schüler</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer ',
        requiresMedicalData: false,
        docIPIDUrl: 'astra_IPID_Fahrrad.pdf',
        docAVBUrl: 'astra_AVB_Fahrrad.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Fahrrad.pdf',
    }),
    //#endregion
    //#region Schülerfahrradversicherung
    new Product({
        slug: 'schueler-fahrrad-versicherung',
        title: 'Schüler-Fahrrad-Versicherung',
        fullTitle: 'Schüler-Fahrrad-Versicherung',
        insuranceTypeName: 'Schüler Fahrrad 2020',
        details: `
            <p><strong>Schüler-Fahrrad-Versicherung</strong></p>
            <p>Versichert durch die astra Versicherung AG</p>
            <p><strong>Bike, Roller, Kickboards: der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung für nur 14,<sup>99</sup> <span class="currency-sign">€</span> im Jahr.</strong></p>
            <p>Wer kennt die Geschichte nicht – ewig Taschengeld gespart und noch mit finanzieller Hilfe von Eltern und Verwandtschaft endlich das Traumbike gekauft… ein Desaster, wenn dann das neue Fahrrad trotz Schloss vom Schulhof geklaut wird oder beschädigt wird.</p>
            <p>Dafür gibt es nun speziell für Schüler den erschwinglichen Diebstahl- und Reparatur-Schutz für die Fahrten zur Schule, während des Unterrichts und sonstigen schulischen Veranstaltung wie Fahrten zum Sportunterricht, Kirche oder Schwimmbad.</p>
            <p>Diese Schüler-Fahrrad-Versicherung, die auch für Roller und Kickboards gilt, ist ideal um seinen mobilen Untersatz auf dem Weg zur Schule und nach Hause sowie während des Unterricht zu schützen.</p>
            <p><strong>Der Schutz für Bikes, Roller und Kickboards für nur 14,<sup>99</sup> <span class="currency-sign">€</span> im Jahr</strong></p>
        `,
        bulletPoints: [
            'Die Schüler-Fahrrad-Versicherung mit Reparatur- und Diebstahlschutz',
            'Plus Schutz bei Beschädigung und Zerstörung',
            'Inkl. Versicherungsschutz für Roller',
            'Inkl. Versicherungsschutz für Kickboards',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherungen_Schüler-Fahrrad-Versicherung.jpg',
        imageAlt: 'Die Fahrrad-Versicherung speziell für Schüler. Von astra Versicherungen',
        imageHeadline: '<h3 class="no-margin-bottom">Die Fahrrad-Versicherung speziell für Schüler</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer ',
        requiresMedicalData: false,
        docIPIDUrl: 'astra_IPID_Fahrrad.pdf',
        docAVBUrl: 'astra_AVB_Fahrrad.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Fahrrad.pdf',
    }),
    //#endregion
    //#region Musikinstrumente Schüler
    new Product({
        slug: 'musikinstrumentenversicherung-schueler',
        title: 'Musikinstrumentenversicherung für Schüler',
        fullTitle: 'Musikinstrumentenversicherung für Schüler',
        insuranceTypeName: 'Musik Schüler',
        details: `
            <p><strong></strong>Schüler-Musikinstrumenten-Versicherung</strong></p>
            <p>Versichert durch die astra Versicherung AG</p>
            <p><strong>Nur 9,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr! Die Schüler-Musikinstrumenten-Versicherung: Der Rundumschutz für wertvolle Instrumente bei Verlust sowie Schäden durch Transport, Brand, Blitz oder Wasser etc.</strong></p>
            <p>Instrumente, die Schüler mit zur Schule oder in den Musikunterricht nehmen, sind besonderen Gefahren ausgesetzt. Durch die Mitnahme in Bus oder Bahn oder durch den Transport zu Fuß können Beschädigungen entstehen oder Instrumente durch Unachtsamkeit oder Vergesslichkeit ganz verloren gehen.</p>
            <p>Diese Risiken deckt die Schüler-Musikinstrumenten-Versicherung komplett ab – ebenso Schäden durch Herunterfallen oder Umstoßen und selbstverständlich auch bei Schäden durch Dritte und Verlust durch Diebstahl.</p>
            <p><strong>Sichern auch Sie Ihr Instrument ab – der Rundum-Schutz für Instrumente der astra Versicherung AG für nur 9,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr</strong></p>
        `,
        bulletPoints: [
            'Der Rundumschutz für Schüler-Instrumente',
            'Schutz bei Diebstahl oder Liegenlassen',
            'Schutz bei Transportschäden',
            'Schutz bei Brand, Blitz oder Wasserschäden',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schueler.jpg',
        imageAlt:
            'astra Musikinstrumentenversicherung: Der Schutz für Schüler-Instrumente inkl. Schutz bei Transport, Diebstahl und Liegenlassen!',
        imageHeadline:
            '<h3>Der Schutz für Schüler-Instrumente</h3><p class="no-margin-bottom">inkl. Schutz bei Transport, Diebstahl und Liegenlassen!</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer ',
        requiresMedicalData: false,
        docIPIDUrl: 'astra_IPID_Musikinstrumente.pdf',
        docAVBUrl: 'astra_AVB_Musikinstrumente.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente.pdf',
    }),
    //#endregion
    //#region Unfall Schüler
    new Product({
        slug: 'schueler-unfallversicherung',
        title: 'Schüler-Unfallversicherung',
        fullTitle: 'Schüler-Unfallversicherung',
        insuranceTypeName: 'Unfall Schüler',
        details: `
            <p><strong class="blue">Schüler-Unfallschutz für nur 18,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr!</strong></p>
            <p>Unfälle ereignen sich bei Kindern und Jugendlichen meist auf dem Weg zur Schule oder zurück, während der Schulzeit oder den Pausen, bzw. während außerunterrichtlichen Veranstaltungen oder Freistunden, Mittagspausen und Schulausflügen. Genau diese Risiken deckt die Schüler-Unfallversicherung zum attraktiven Tarif ab. Der Schutz erstreckt zudem auf schulische Praktika und Arbeitsplatzerkundungen.</p>
            <p>Insbesondere Fälle, wie kosmetische Operationen, die durch einen Unfall erforderlich werden, oder Bergungs- und Transportkosten, deren finanzieller Aufwand von den meisten Eltern selbst nicht getragen werden kann, werden von diesem Schutz abgedeckt. Aber auch Leistungen bei Invalidität oder im Todesfall sind im Schüler-Unfallschutz enthalten.</p>
            <p><strong class="blue">Die Schüler-Unfallversicherung – Schutz bei Invalidität und kosmetischen Operationen sowie Erstattung von Bergungskosten, Krankenhaustagegeld und Leistungen im Todesfall.</strong></p>
        `,
        bulletPoints: [
            'Umfassender Schüler-Unfallschutz bei Krankenhaus-Aufenthalt, Invalidität oder Bergungskosten, etc.',
            'Versicherungsschutz bei allen schulischen Veranstaltungen wie Schulausflüge, Schülergottesdiensten oder Betriebspraktika sowie Freistunden und Mittagspausen',
            'Versicherungsschutz auf dem Schulweg und dem Heimweg',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Schueler-Unfallversicherung.jpg',
        imageAlt: 'Die astra Unfall- und Sach-Versicherung zum attraktiven Schüler-Tarif',
        imageHeadline: '<h3>Die Unfallversicherung</h3><p class="no-margin-bottom">zum attraktiven Schüler-Tarif</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer ',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_Unfall_Sach.pdf',
        docAVBUrl: 'astra_AVB_Unfall_Sach.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Unfall_Sach.pdf',
    }),
    //#endregion
    //#region Schüler Haft- und Sach
    new Product({
        slug: 'schueler-haftpflicht-und-sachschadenversicherung',
        title: 'Schüler-Haftpflicht- und Sachschadenversicherung',
        fullTitle: 'Schüler-Haftpflicht- und Sachschadenversicherung',
        insuranceTypeName: 'Schüler-Sachschaden-Haftpflicht',
        details: `
            <p><strong class="blue">Schüler-Haftpflicht- und Sachschaden-Versicherung - der Komplettschutz für Schüler für nur 3,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr!</strong></p>
            <p>Dieser speziell für Schüler/innen entwickelte Versicherungsschutz deckt im schulischen Umfeld nicht nur Personen-, Sach- und Vermögensschäden ab, die der Schüler oder die Schülerin anderen Personen oder der Schule zufügen könnte, sondern auch Schäden am eigenen Eigentum, die zum Beispiel beim Sportunterricht oder bei unfallähnlichen Vorfällen vorkommen. So sind beispielsweise etwa bei einem Zusammenstoß beim Sport, im Klassenzimmer oder auf dem Schulhof auch Schäden an der eigenen Bekleidung oder an der Brille gleich mitversichert.</p>
            <p>Der Versicherungsschutz deckt alle Aktivitäten während der Schulzeit oder den Pausen, bzw. während außerunterrichtlichen Veranstaltungen oder Freistunden, Mittagspausen und Schulausflügen ab - inklusive schulischen Praktika und Arbeitsplatzerkundungen.</p>
            <p><strong class="blue">Die Schüler-Haftpflicht- und Sachschadenversicherung – umfassender Schutz bei Forderungen nach Personen- und Sachschäden sowie Vermögensschäden. Inkl. Sachschaden-Schutz bei Beschädigung des eigenen Eigentums durch Dritte.</strong></p>
        `,
        bulletPoints: [
            'Schüler-Haftpflicht-Schutz bei Personen-, Sach-, und Vermögensschaden im schulischen Umfeld und auf dem Schulweg',
            'Deckungssumme 2 Mio. EUR bei Personen- und Sachschäden und 50.000,00 EUR bei Vermögensschäden',
            'Inkl. Sach-Schutz z.B. bei Unfällen in der Schule oder beim Sportunterricht',
            'Von Brille bis Zahnspange – von Handy bis Bekleidung: Der Schutz bei Schäden durch Dritte',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astraversicherung_Schueler-Haftplficht_und_Sachschadenversicherung.jpg',
        imageAlt: 'Schüler-Haftpflicht- und Sach-Versicherung der astra Versicherung AG zum attraktiven Schüler-Tarif ',
        imageHeadline:
            '<h3 class="no-margin-bottom">Nur 3,<sup>99</sup>&nbsp;<span class="currency-sign">€</span>/Jahr:<br>Schüler-Haftpflicht- und Sach-Versicherung</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer ',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_Haftpflicht_Sach.pdf',
        docAVBUrl: 'astra_Besondere_Bedingungen_AVB_Haftpflicht_Sach.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Haftpflicht_Sach.pdf',
    }),
    //#endregion
    //#region Nachhilfe Plus
    new Product({
        slug: 'nachhilfe-plus',
        title: 'Nachhilfe-Versicherung "Plus"',
        fullTitle: 'Nachhilfe-Versicherung "Plus"',
        insuranceTypeName: 'Nachhilfe Plus',
        details: `
            <p><strong class="blue">Die Nachhilfe-Versicherung „Plus“: Bei längerer Krankheit zu Hause oder im Krankenhaus gut betreut – inklusive Rooming-In-Leistungen für eine Begleitperson.</strong></p>
            <p>Wenn ein Kind wegen einer längeren Krankheit zu Hause bleiben muss oder gar ein Krankenhaus-Aufenthalt notwendig wird, ist das für jedes Kind und seine Familie eine außerordentliche Belastung in mehrfacher Hinsicht.</p>
            <p>Zieht sich die Krankheit über einen längeren Zeitraum, kommen durch verpassten Unterrichtsstoff Probleme in der Schule hinzu. Mehrere Wochen Unterrichtsausfall lassen sich kaum noch aufholen – die Folgen sind meist schlechte Noten und Zeugnisse – im schlimmsten Fall ist die Versetzung gefährdet.</p>
            <p>Mit der Nachhilfe-Versicherung „Plus“ schützen Sie Ihr Kind davor, dass es nach einer Krankheit nicht noch zusätzlich durch schulische Probleme belastet wird: Wir übernehmen die Kosten für pädagogische Betreuung und Nachhilfe ab dem 7. Schultag Abwesenheit bis zu einer Höhe von 400 <span class="currency-sign">€</span>, ab dem 14. Schultag sogar bis zu 600 <span class="currency-sign">€</span>.</p>
            <p>Bei Aufenthalt im Krankenhaus bereits ab dem 5. und 10. Schultag (bis zu 400 <span class="currency-sign">€</span>/600<span class="currency-sign">€</span>).</p>
            <p>Bei Kindern bis 14 Jahre werden zudem die Unterbringungs- und Verpflegungskosten („Rooming-In“) einer Begleitperson im Krankenhaus bis zu 50 <span class="currency-sign">€</span> erstattet – und dies schon ab dem ersten Tag – egal wie lange der Aufenthalt dauert.</p>
            <p>Und: Sollten Sie eine Nachhilfeleistung einmal nicht in Anspruch nehmen müssen, werden einmalig 100,- Euro als Pauschalleistung erstattet.</p>
            <p><strong class="blue">Nur 29,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr: Der Versicherungsschutz bei Schulausfall durch längere Krankheit oder Krankenhausaufenthalt, damit schulische Folgeprobleme erst gar nicht entstehen – bei Kindern bis 14 Jahren inkl. Rooming-in-Leistungen für die Eltern ab dem ersten Tag!</strong></p>
        `,
        bulletPoints: [
            'Pädagogische Betreuung durch professionelle Nachhilfe bei längerer Krankheit zu Hause',
            'Nachhilfe bei längerem Aufenthalt im Krankenhaus sowie danach im häuslichen Umfeld.',
            'Unterstützt das Wohlbefinden der Kinder durch Rooming-in von Eltern im Krankenhaus ab dem ersten Tag.',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherungen_Nachhilfeversicherung-PLUS.jpg',
        imageAlt:
            'Die Nachhilfe-Versicherung der astra Versicherung AG - Pädagogische Betreuung bei Krankenhaus-Aufenthalt inkl. Rooming-In',
        imageHeadline:
            '<h3>Die Nachhilfe-Versicherung „Plus“</h3><p class="no-margin-bottom">Nachhilfe bei Krankheit – zu Hause und im Krankenhaus inkl. Rooming-In</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        requiresMedicalData: true,
        docIPIDUrl: 'astra_IPID_Nachhilfe_Plus.pdf',
        docAVBUrl: 'astra_AVB_Nachhilfe_Plus.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Nachhilfe_Plus.pdf',
        insurantMaxAge: 18,
    }),
    //#endregion
    //#region Kombischutz Jährlich
    new Product({
        slug: 'schueler-kombischutz-j',
        title: 'Schüler-Kombischutz',
        fullTitle: 'Schüler-Kombischutz',
        insuranceTypeName: 'Schüler-Kombischutz (J)',
        details: `
            <p><strong class="blue">Schüler-Kombischutz: Haftpflicht, Sachschaden, Unfall und Nachhilfe-Versicherung</strong></p>
            <p>Versichert durch astra Versicherung AG</p>
            <p><strong class="blue">Alle wichtigen Versicherungen in einem Paket: der Schüler-Kombischutz bietet einen speziell für Schüler konzipierten umfassenden Komplettschutz für beinahe jeden erdenklichen Fall. Und das für nur 49,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr.</strong></p>
            <p>Die häufigsten Schadensfälle während der Schulzeit und der Ausbildung betreffen die Bereiche Haftpflicht, Sachschäden und Unfall. Ohne großen bürokratischen Aufwand bietet der Schüler-Kombischutz hier umfassende Sicherheit für den Fall der Fälle, wenn Personen- oder Sachschäden Mitschülern oder der Schule zugefügt werden, wenn Schülern/innen selbst ein Sachschaden durch Dritte entstanden ist – oder Schüler einen Unfall erleiden.</p>
            <p>Mit im Paket die innovative Unterrichtsausfall-Versicherung, die während längerem Krankenhaus-Aufenthalt oder längerer Krankheit zuhause ab dem 5. Tag eine pädagogische Nachhilfe-Betreuung garantiert und bei Kindern bis 14 Jahren sogar die Rooming-In-Kosten eines Elternteils übernimmt.</p>
            <ul>
                <li><strong>Schüler-Haftpflicht-Versicherung:</strong> Schutz bei Personen-, Sach-, und Vermögensschaden im schulischen Umfeld und auf dem Schulweg</li>
                <li><strong>Schüler-Sachschaden-Versicherung:</strong> Von Bekleidung bis Handy, von Brille bis Zahnspange - der Sach-Schutz bei Unfällen in der Schule oder beim Sportunterricht</li>
                <li><strong>Schüler-Unfall-Versicherung:</strong> Umfassender Unfallschutz bei Krankenhaus-Aufenthalt oder Invalidität, etc.</li>
                <li><strong>Schüler-Nachhilfe-Versicherung</strong> mit Rooming-In-Kostenübernahme. Professionelle Nachhilfe-Betreuung während längerem Krankenhaus-Aufenthalt oder längerer Krankheit zuhause.</li>
            </ul>
            <p><strong class="blue">Der Rundum-Schutz zum fairen Schüler-Tarif - speziell auf die Bedürfnisse von Schülern und Schülerinnen zugeschnitten.</strong></p>
        `,
        bulletPoints: [
            'Schüler-Haftpflicht-Versicherung mit umfassendem Schutz',
            'Schüler-Sachschaden-Versicherung bei Schäden durch Dritte',
            'Schüler-Unfall-Versicherung – der Komplettschutz für Schüler!',
            'Inkl. Nachhilfe-Versicherung mit Rooming-In-Kostenübernahme',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Schueler-Kombischutz.jpg',
        imageAlt: 'Der astra Versicherung Schüler-Kombischutz. Rundum gut versichert zum fairen Tarif.',
        imageHeadline:
            '<h3>Der Schüler-Kombischutz.</h3><p class="no-margin-bottom">Rundum gut versichert zum fairen Tarif.</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        requiresMedicalData: true,
        alternateSettlementPeriodProduct: 'schueler-kombischutz-m',
        alternateProducts: [
            new AlternateProduct('schueler-kombischutz-m', 'monatlich'),
            new AlternateProduct('schueler-kombischutz-j', 'jährlich'),
        ],
        docIPIDUrl: 'astra_IPID_Kombischutz.pdf',
        docAVBUrl: 'astra_AVB_Kombischutz.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Kombischutz.pdf',
    }),
    //#endregion
    //#region Kombischutz Monatlich
    new Product({
        slug: 'schueler-kombischutz-m',
        title: 'Schüler-Kombischutz',
        fullTitle: 'Schüler-Kombischutz',
        insuranceTypeName: 'Schüler-Kombischutz (M)',
        details: `
            <p><strong class="blue">Schüler-Kombischutz: Haftpflicht, Sachschaden, Unfall und Nachhilfe-Versicherung</strong></p>
            <p>Versichert durch astra Versicherung AG</p>
            <p><strong class="blue">Alle wichtigen Versicherungen in einem Paket: der Schüler-Kombischutz bietet einen speziell für Schüler konzipierten umfassenden Komplettschutz für beinahe jeden erdenklichen Fall. Und das für nur 4,<sup>49</sup> <span class="currency-sign">€</span> pro Monat.</strong></p>
            <p>Die häufigsten Schadensfälle während der Schulzeit und der Ausbildung betreffen die Bereiche Haftpflicht, Sachschäden und Unfall. Ohne großen bürokratischen Aufwand bietet der Schüler-Kombischutz hier umfassende Sicherheit für den Fall der Fälle, wenn Personen- oder Sachschäden Mitschülern oder der Schule zugefügt werden, wenn Schülern/innen selbst ein Sachschaden durch Dritte entstanden ist – oder Schüler einen Unfall erleiden.</p>
            <p>Mit im Paket die innovative Unterrichtsausfall-Versicherung, die während längerem Krankenhaus-Aufenthalt oder längerer Krankheit zuhause ab dem 5. Tag eine pädagogische Nachhilfe-Betreuung garantiert und bei Kindern bis 14 Jahren sogar die Rooming-In-Kosten eines Elternteils übernimmt.</p>
            <ul>
                <li><strong>Schüler-Haftpflicht-Versicherung:</strong> Schutz bei Personen-, Sach-, und Vermögensschaden im schulischen Umfeld und auf dem Schulweg</li>
                <li><strong>Schüler-Sachschaden-Versicherung:</strong> Von Bekleidung bis Handy, von Brille bis Zahnspange - der Sach-Schutz bei Unfällen in der Schule oder beim Sportunterricht</li>
                <li><strong>Schüler-Unfall-Versicherung:</strong> Umfassender Unfallschutz bei Krankenhaus-Aufenthalt oder Invalidität, etc.</li>
                <li><strong>Schüler-Nachhilfe-Versicherung</strong> mit Rooming-In-Kostenübernahme. Professionelle Nachhilfe-Betreuung während längerem Krankenhaus-Aufenthalt oder längerer Krankheit zuhause.</li>
            </ul>
            <p><strong class="blue">Der Rundum-Schutz zum fairen Schüler-Tarif - speziell auf die Bedürfnisse von Schülern und Schülerinnen zugeschnitten.</strong></p>
        `,
        bulletPoints: [
            'Schüler-Haftpflicht-Versicherung mit umfassendem Schutz',
            'Schüler-Sachschaden-Versicherung bei Schäden durch Dritte',
            'Schüler-Unfall-Versicherung – der Komplettschutz für Schüler!',
            'Inkl. Nachhilfe-Versicherung mit Rooming-In-Kostenübernahme',
        ],
        wizardSteps: [
            '',
            'versicherte-person',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Schueler-Kombischutz.jpg',
        imageAlt: 'Der astra Schüler-Kombischutz. Rundum gut versichert zum fairen Tarif.',
        imageHeadline:
            '<h3>Der Schüler-Kombischutz.</h3><p class="no-margin-bottom">Rundum gut versichert zum fairen Tarif.</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        requiresMedicalData: true,
        alternateSettlementPeriodProduct: 'schueler-kombischutz-j',
        alternateProducts: [
            new AlternateProduct('schueler-kombischutz-m', 'monatlich'),
            new AlternateProduct('schueler-kombischutz-j', 'jährlich'),
        ],
        docIPIDUrl: 'astra_IPID_Kombischutz.pdf',
        docAVBUrl: 'astra_AVB_Kombischutz.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Kombischutz.pdf',
    }),
    //#endregion
    //#region Musik Premium
    new Product({
        slug: 'musikinstrumentenversicherung',
        title: 'Musikinstrumentenversicherung Premium',
        fullTitle: 'Musikinstrumentenversicherung Premium',
        insuranceTypeName: 'Musik',
        details: `
            <p><strong>Der umfassende Premium-Schutz für Instrumente im Wert bis zu 10.000 <span class="currency-sign">€</span> inkl. Schutz bei Verlust sowie Schäden durch Transport, Brand, Blitz oder Wasser etc.</strong></p>
            <p>Hochwertige Instrumente, die Berufsmusiker oder angehende Musik-Virtuosen während den Proben und bei Konzerten einsetzen, sind besonderen Gefahren ausgesetzt: Ob Transport im Auto, per Bahn oder Flugzeug, Fahrten zu Proben und Konzerten sowie Schäden durch Dritte bei Auftritten. Diese weltweit geltende Spezial-Versicherung deckt all diese Gefahren ab. Selbstverständlich versichert sind auch Schäden durch Dritte, Verlust durch Raub und Diebstahl sowie Schäden durch Brand, Blitz, Explosion und Elementarschäden. Sogar versehentliches Umstoßen oder Herunterfallen sind versichert.</p>
            <p>Nicht versicherbar sind Instrumente von Rockbands oder vergleichbaren Bands.</p>
            <p><strong class="blue">Der weltweit geltende Schutz der astra Versicherung AG für wertvolle Instrumente.</strong></p>
        `,
        bulletPoints: [
            'Der weltweite Premium-Instrumenten-Schutz bis 10.000 € Wert',
            'Inklusive Schutz für Zubehör und Noten',
            'Schutz bei Diebstahl, Raub, Veruntreuung',
            'Schutz bei Umstoßen oder Herunterfallen',
            'Schutz bei Transportschäden und -Unfällen',
            'Schutz bei Brand, Blitz, Wasser- und Elementarschäden',
        ],
        wizardSteps: [
            '',
            'versichertes-objekt',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astraversicherung_Musikinstrumentenversicherung_Premium.jpg',
        imageAlt: 'astra Musikinstrumentenversicherung Premium: Der umfassende Schutz für hochwertige Instrumente.',
        imageHeadline:
            '<h3>Der umfassende<br>Schutz für hochwertige Instrumente</h3><p class="no-margin-bottom">inkl. umfassendem Schutz bei Transport.</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Musikinstrumente_Premium.pdf',
        docAVBUrl: 'astra_AVB_Musikinstrumente_Premium.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente_Premium.pdf',
        hasFlexPrice: true,
        titleString: 'Musikinstrumentenversicherung Premium | ab 37,00 € / Jahr | astra Versicherung',
    }),
    //#endregion
    //#region Musik Schulen Klasse
    new Product({
        slug: 'musikinstrumentenversicherung-schulen-k',
        title: 'Musikinstrumentenversicherung für Schulen',
        fullTitle: 'Musikinstrumentenversicherung für Schulen',
        insuranceTypeName: 'Musik Klasse',
        details: `
            <p><strong class="blue">Nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument! Die Musikinstrumenten-Versicherung für Schulen, Musikklassen oder Instrumenten-Pools: Der Rundumschutz für wertvolle Instrumente bei Verlust sowie Schäden durch Transport, Brand, Blitz,  Wasser sowie Schäden durch Unachtsamkeit, etc.</strong></p>
            <p>Instrumente, die von der Schule an Schüler und Schülerinnen für den Musikunterricht, zum Üben oder schulische Veranstaltungen zur Verfügung gestellt werden, sind meist viel häufiger in Gebrauch und im Schulbetrieb ganz anderen Gefahren ausgesetzt als ein Schülerinstrument, das ausschließlich privat und zuhause benutzt wird.</p>
            <p>Ob Beschädigungen durch Unachtsamkeit oder beim Transport, oder Schäden durch Brand, Blitz oder Wasserschäden - diese Risiken deckt diese Musikinstrumenten-Versicherung für Schulen komplett ab – ebenso wie Schäden durch Herunterfallen oder Umstoßen sowie Schäden durch Dritte und Verlust durch Diebstahl.</p>
            <p>Sie haben zudem – bei identischen Tarifen – die Wahl zwischen zwei Vertragsmodellen: Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten. Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest an einen einzigen Schüler verleihen oder ausgeben.</p>
            <p><strong class="blue">Sichern auch Sie Ihre Schul-Instrumente ab – der Rundum-Schutz für Instrumente der astra Versicherung AG für nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument.</strong></p>
        `,
        bulletPoints: [
            'Ein Vertrag für beliebig viele Schul-Instrumente',
            'Der Rundumschutz für Musikinstrumente',
            'Schutz bei Diebstahl, Liegenlassen und Herunterfallen',
            'Schutz bei Transportschäden',
            'Schutz bei Brand, Blitz oder Wasserschäden',
        ],
        wizardSteps: [
            '',
            'geraete-details',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schulen.jpg',
        imageAlt: 'Der Instrumentenschutz für Schulen und Musikklassen von astra',
        imageHeadline: '<h3>Der<br>Instrumentenschutz<br>für Schulen und Musikklassen</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Musikinstrumente_Schulen.pdf',
        docAVBUrl: 'astra_AVB_Musikinstrumente_Schulen.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente_Schulen.pdf',
        hasFlexPrice: true,
        showPriceFrom: true,
        alternateProducts: [
            new AlternateProduct('musikinstrumentenversicherung-schulen-p', 'Vertragsart Instrumentenpool'),
            new AlternateProduct('musikinstrumentenversicherung-schulen-k', 'Vertragsart Schülerinstrumente'),
        ],
        insuredObjectsPool: musicInstrumentPupilData,
        keepAfterCustomerChange: ['Insurants', 'ObjectPool'],
        keepStepsOnVariantChange: true,
    }),
    //#endregion
    //#region Musik Schulen Pool
    new Product({
        slug: 'musikinstrumentenversicherung-schulen-p',
        title: 'Musikinstrumentenversicherung für Schulen',
        fullTitle: 'Musikinstrumentenversicherung für Schulen',
        insuranceTypeName: 'Musik Instrumente',
        details: `
            <p><strong class="blue">Nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument! Die Musikinstrumenten-Versicherung für Schulen, Musikklassen oder Instrumenten-Pools: Der Rundumschutz für wertvolle Instrumente bei Verlust sowie Schäden durch Transport, Brand, Blitz,  Wasser sowie Schäden durch Unachtsamkeit, etc.</strong></p>
            <p>Instrumente, die von der Schule an Schüler und Schülerinnen für den Musikunterricht, zum Üben oder schulische Veranstaltungen zur Verfügung gestellt werden, sind meist viel häufiger in Gebrauch und im Schulbetrieb ganz anderen Gefahren ausgesetzt als ein Schülerinstrument, das ausschließlich privat und zuhause benutzt wird.</p>
            <p>Ob Beschädigungen durch Unachtsamkeit oder beim Transport, oder Schäden durch Brand, Blitz oder Wasserschäden - diese Risiken deckt diese Musikinstrumenten-Versicherung für Schulen komplett ab – ebenso wie Schäden durch Herunterfallen oder Umstoßen sowie Schäden durch Dritte und Verlust durch Diebstahl.</p>
            <p>Sie haben zudem – bei identischen Tarifen – die Wahl zwischen zwei Vertragsmodellen: Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten. Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest an einen einzigen Schüler verleihen oder ausgeben.</p>
            <p><strong class="blue">Sichern auch Sie Ihre Schul-Instrumente ab – der Rundum-Schutz für Instrumente der astra Versicherung AG für nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument.</strong></p>
        `,
        bulletPoints: [
            'Ein Vertrag für beliebig viele Schul-Instrumente',
            'Der Rundumschutz für Musikinstrumente',
            'Schutz bei Diebstahl, Liegenlassen und Herunterfallen',
            'Schutz bei Transportschäden',
            'Schutz bei Brand, Blitz oder Wasserschäden',
        ],
        wizardSteps: [
            '',
            'geraete-details',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schulen.jpg',
        imageAlt: 'Der Instrumentenschutz für Schulen und Musikklassen von astra',
        imageHeadline: '<h3>Der<br>Instrumentenschutz<br>für Schulen und Musikklassen</h3>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Musikinstrumente_Schulen.pdf',
        docAVBUrl: 'astra_AVB_Musikinstrumente_Schulen.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente_Schulen.pdf',
        hasFlexPrice: true,
        showPriceFrom: true,
        alternateProducts: [
            new AlternateProduct('musikinstrumentenversicherung-schulen-p', 'Vertragsart Instrumentenpool'),
            new AlternateProduct('musikinstrumentenversicherung-schulen-k', 'Vertragsart Schülerinstrumente'),
        ],
        insuredObjectsPool: musicInstrumentPoolData,
        keepAfterCustomerChange: ['Insurants', 'ObjectPool'],
        keepStepsOnVariantChange: true,
    }),
    //#endregion
    //#region Tablet PLUS monatlich
    new Product({
        slug: 'tablet-versicherung-plus',
        title: 'Tabletversicherung Plus',
        fullTitle: 'Tablet- und Notebook-Versicherung "Tablet PLUS"',
        insuranceTypeName: 'Tablet Plus (M)',
        details: `
            <p><strong class="blue">Der umfassende Versicherungsschutz für Schulen, Lehrer und Schüler oder die ganze Klasse für einzelne bzw. beliebig viele elektronische Geräte – inkl. Leihgeräten und Geräten von Lehrern mit hoher Nutzungsfrequenz. Inkl. Plus-Schutz bei Schäden durch Transport oder Verwendung zuhause!</strong></p>
            <p>Ob Sie ein einzelnes Gerät versichern möchten, das in Ihrem Besitz ist, oder eines, das Ihnen leihweise von der Schule überlassen wurde, ob Sie Schulträger oder Förderverein sind und gleich mehrere Geräte gut geschützt wissen wollen – dann ist die speziell für digitale Schulen, Lehrer und Schüler konzipierte Tablet- und Notebook-Versicherung „Tablet PLUS“ genau das Richtige für Sie.</p>
            <p>Zum günstigen Tarif umfassend gegen alle denkbaren Schäden <strong>im schulischen Umfeld, beim Transport, zuhause und bei sonstiger privater Nutzung</strong> geschützt: Neben dem klassischen Versicherungsschutz bei Brand, Blitzschlag, Explosion oder Wasserschäden deckt die Tablet- und Notebook-Versicherung „Tablet PLUS“ von astra nämlich auch wahrscheinlichere Schäden wie z.B. bei Verlust, Diebstahl und sogar versehentliches Liegenlassen oder Herunterfallen in der Schule, beim Transport und sogar zuhause ab.</p>
            <p><strong class="blue">Schnell und einfach zum Vertrag - ab nur 1,70&nbsp;€ im Monat pro Notebook oder Tablet.<br>
            Der Versicherungsschutz „Tablet Plus“ für Schulen, Lehrer, Schüler und digitale Klassen.</strong></p>
        `,
        bulletPoints: [
            'Alle Risiken innerhalb der Schule, bei häuslichen Lerngruppen und privater Nutzung sind abgedeckt',
            'Deckung bei Totalschaden, Transportschäden, Verlust, Diebstahl, Liegenlassen, Herunterfallen u.v.m.',
            'Schutz auch für Lehrergeräte oder Leihgeräte mit hoher Nutzungsfrequenz',
            'Versicherungsschutz für einzelne oder beliebig viele Endgeräte',
        ],
        wizardSteps: ['', 'geraete-details-plus', 'persoenliche-angaben', 'zahlungsdaten', 'vertragserklaerungen'],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Tabletversicherung-PLUS.jpg',
        imageAlt:
            'astra Versicherung „Tablet PLUS“: Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND zuhause.',
        imageHeadline:
            '<h3>„Tablet PLUS“:</h3><p class="no-margin-bottom">Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND bei privater Nutzung.</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Tablet_Plus.pdf',
        docAVBUrl: 'astra_AVB_Tablet_Plus.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Tablet_Plus.pdf',
        requiresAgentConfirmation: true,
        hasFlexPrice: true,
        showPriceFrom: true,
        showDiscountInput: true,
        showB2BInfo: true,
        insuredObjectsPool: tabletPlusPoolData,
        alternateSettlementPeriodProduct: 'tablet-versicherung-plus-j',
        alternateProducts: [
            new AlternateProduct('tablet-versicherung-plus', 'monatlich'),
            new AlternateProduct('tablet-versicherung-plus-j', 'jährlich'),
        ],
        keepAfterCustomerChange: ['ObjectPool', 'ResellerUrl'],
    }),
    //#endregion
    //#region Tablet PLUS jährlich
    new Product({
        slug: 'tablet-versicherung-plus-j',
        title: 'Tabletversicherung Plus',
        fullTitle: 'Tablet- und Notebook-Versicherung "Tablet PLUS"',
        insuranceTypeName: 'Tablet Plus (J)',
        details: `
            <p><strong class="blue">Der umfassende Versicherungsschutz für Schulen, Lehrer und Schüler oder die ganze Klasse für einzelne bzw. beliebig viele elektronische Geräte – inkl. Leihgeräten und Geräten von Lehrern mit hoher Nutzungsfrequenz. Inkl. Plus-Schutz bei Schäden durch Transport oder Verwendung zuhause!</strong></p>
            <p>Ob Sie ein einzelnes Gerät versichern möchten, das in Ihrem Besitz ist, oder eines, das Ihnen leihweise von der Schule überlassen wurde, ob Sie Schulträger oder Förderverein sind und gleich mehrere Geräte gut geschützt wissen wollen – dann ist die speziell für digitale Schulen, Lehrer und Schüler konzipierte Tablet- und Notebook-Versicherung „Tablet PLUS“ genau das Richtige für Sie.</p>
            <p>Zum günstigen Tarif umfassend gegen alle denkbaren Schäden <strong>im schulischen Umfeld, beim Transport, zuhause und bei sonstiger privater Nutzung</strong> geschützt: Neben dem klassischen Versicherungsschutz bei Brand, Blitzschlag, Explosion oder Wasserschäden deckt die Tablet- und Notebook-Versicherung „Tablet PLUS“ von astra nämlich auch wahrscheinlichere Schäden wie z.B. bei Verlust, Diebstahl und sogar versehentliches Liegenlassen oder Herunterfallen in der Schule, beim Transport und sogar zuhause ab.</p>
            <p><strong class="blue">Schnell und einfach zum Vertrag - ab nur 1,70&nbsp;€ im Monat pro Notebook oder Tablet.<br>
            Der Versicherungsschutz „Tablet Plus“ für Schulen, Lehrer, Schüler und digitale Klassen.</strong></p>
        `,
        bulletPoints: [
            'Alle Risiken innerhalb der Schule, bei häuslichen Lerngruppen und privater Nutzung sind abgedeckt',
            'Deckung bei Totalschaden, Transportschäden, Verlust, Diebstahl, Liegenlassen, Herunterfallen u.v.m.',
            'Schutz auch für Lehrergeräte oder Leihgeräte mit hoher Nutzungsfrequenz',
            'Versicherungsschutz für einzelne oder beliebig viele Endgeräte',
        ],
        wizardSteps: ['', 'geraete-details-plus', 'persoenliche-angaben', 'zahlungsdaten', 'vertragserklaerungen'],
        imageUrl: 'assets/img/product-headers/astra-Versicherung_Tabletversicherung-PLUS.jpg',
        imageAlt:
            'astra Versicherung „Tablet PLUS“: Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND zuhause.',
        imageHeadline:
            '<h3>„Tablet PLUS“:</h3><p class="no-margin-bottom">Die Tablet- und Notebook-Versicherung mit Schutz in der Schule UND bei privater Nutzung.</p>',
        taxInfo: 'Inkl. 19 % Versicherungssteuer',
        docIPIDUrl: 'astra_IPID_Tablet_Plus.pdf',
        docAVBUrl: 'astra_AVB_Tablet_Plus.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Tablet_Plus.pdf',
        requiresAgentConfirmation: true,
        hasFlexPrice: true,
        showPriceFrom: true,
        showDiscountInput: true,
        showB2BInfo: true,
        insuredObjectsPool: tabletPlusPoolData,
        alternateSettlementPeriodProduct: 'tablet-versicherung-plus',
        alternateProducts: [
            new AlternateProduct('tablet-versicherung-plus', 'monatlich'),
            new AlternateProduct('tablet-versicherung-plus-j', 'jährlich'),
        ],
        keepAfterCustomerChange: ['ObjectPool', 'ResellerUrl'],
    }),
    //#endregion
    //#region Zahnzusatzversicherung
    new Product({
        slug: 'zahnzusatz',
        title: 'Zahnzusatzversicherung',
        insuranceTypeName: 'Zahnzusatz',
        details: `
            <p><strong class="blue">Die Zahn-Zusatzversicherung für gesetzlich Versicherte ab 15,<sup>55</sup>&nbsp;<span class="currency-sign">€</span> pro Monat.</strong></p>
            <p>Diese Zahn-Zusatzversicherung ergänzt sinnvoll die unvollständigen Leistungen gesetzlicher Krankenversicherungen und reduziert Ihren Eigenanteil auf ein Minimum. So bleiben gesunde Zähne und notwendige Behandlungen oder Zahnersatz einfacher bezahlbar.</p>
            <p>Für Kinder, die in der GKV der Eltern mitversichert sind, gelten attraktive Jugend-Tarife bereits ab 6,<sup>57</sup>&nbsp;<span class="currency-sign">€</span> pro Monat.</p>
            <p><strong class="blue">Tarif Basic:</strong></p>
            <ul>
                <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Zahnersatz</li>
                <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Kieferorthopädie bis 800&nbsp;€ bei Vorliegen der kieferorthopädischen Indikationsgruppen 3,4 und 5 sowie 300&nbsp;€ Zuschuss bei Vorliegen der kieferorthopädischen Indikationsgruppen 1 und 2.</li>
                <li>Zahnprophylaxe bis 150&nbsp;€ im Jahr</li>
            </ul>
            <p><strong class="blue">Tarif Plus:</strong></p>
            <ul>
                <li class="no-margin-bottom">80&nbsp;% Erstattung* bei Zahnbehandlung</li>
                <li>Erstattungen und Leistungen wie Tarif Basic bei Zahnersatz, Kieferorthopädie und Prophylaxe</li>
            </ul>
            <p><strong class="blue">Tarif Premium:</strong></p>
            <ul>
                <li class="no-margin-bottom">100&nbsp;% Erstattung* bei Zahnbehandlung</li>
                <li class="no-margin-bottom">90&nbsp;% Erstattung* bei Zahnersatz</li>
                <li class="no-margin-bottom">90&nbsp;% Erstattung* bei Kieferorthopädie bis 900&nbsp;€ bei Vorliegen der kieferorthopädischen Indikationsgruppen 3,4,5 sowie 300&nbsp;€ Zuschuss bei Vorliegen der kieferorthopädischen Indikationsgruppen 1 und 2.</li>
                <li>Zahnprophylaxe bis 150&nbsp;€ im Jahr</li>
            </ul>
            <p><small>(*zusammen mit den Vorleistungen der GKV)</small></p>
            <p>Nach drei Versicherungsjahren entfällt eine Begrenzung der Versicherungsleistungen für Zahnersatz, Zahnbehandlung und Kieferorthopädie (Begrenzung auf 600&nbsp;€ im ersten Versicherungsjahr, auf 1000&nbsp;€ im zweiten Versicherungsjahr, auf 1500&nbsp;€ im dritten Versicherungsjahr).</p>
            <p>Die Kosten für die Prophylaxe werden nicht auf die Höchstsätze angerechnet.</p>
            <p>Bei einer unfallbedingten Behandlung (einschließlich Zahnersatz) entfällt die Begrenzung auf diese Höchstsätze.</p>
            <p><strong class="blue">Ihr Zusatzschutz für gesunde und schöne Zähne – wählen Sie hier Ihren Wunsch-Tarif.</strong></p>
        `,
        bulletPoints: [
            'Umfassende Zahn-Zusatzversicherung für gesetzlich Versicherte',
            'Inkl. Schutz für mitversicherte Kinder und Jugendliche ab nur 6,57 € im Monat',
            'Wählen Sie aus 3 Tarifen Ihren individuellen Wunsch-Zusatzschutz: Basic, Plus und Premium – für mitversicherte Kinder gelten die gleichen Leistungen wie für den versicherten Elternteil',
            'Europaweiter Schutz – auch im Urlaub und auf Reisen',
        ],
        wizardSteps: [
            '',
            'tarif-antragsteller',
            'tarif-mitversicherte',
            'vertragsbeginn',
            'persoenliche-angaben',
            'zahlungsdaten',
            'vertragserklaerungen',
        ],
        imageUrl: 'assets/img/product-headers/astra_Versicherungen_Zahnzusatzversicherung.jpg',
        imageAlt: 'Ihr Zusatzschutz für gesunde und schöne Zähne von astra Versicherung AG',
        imageHeadline: '<h3 class="no-margin-bottom">Ihr Zusatzschutz für gesunde und schöne Zähne</h3>',
        taxInfo: 'Steuerfrei nach § 4 Nr. 5 VersStG',
        docIPIDUrl: 'astra_IPID_Zahnzusatzversicherung.pdf',
        docAVBUrl: 'astra_AVB_Zahnzusatz.pdf',
        docAddInfoUrl: 'astra_Kundeninfo_Zahnzusatz.pdf',
        showPriceFrom: false,
        requiresMedicalData: true,
        hasFlexPrice: true,
        showDiscountInput: true,
        keepAfterCustomerChange: ['Insurants', 'LegalHealthInsurance', 'ObjectValue'],
        titleString: 'Zahn-Zusatzversicherung | ab 15,55 € / Monat | astra Versicherung',
        insurantMaxAge: 80,
    }),
    //#endregion
];
