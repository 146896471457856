import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { HttpService } from '../../core/http/http.service';
import { environment } from '../../../environments/environment';
import { HttpParams } from '../../../../node_modules/@angular/common/http';

const REQUEST_PW_RESET_MAIL_URL = environment.basePath + environment.forgotPassword;

@Component({
    selector: 'app-reset-pw-view',
    templateUrl: './reset-pw-view.component.html',
    styleUrls: ['./reset-pw-view.component.scss'],
})
export class ResetPwViewComponent implements OnInit {
    form: FormGroup;
    resetRequested: boolean = false;
    constructor(private fb: FormBuilder, private http: HttpService) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.email],
            insuranceNo: ['', Validators.required],
        });
    }

    invalid() {
        return !this.form.valid;
    }

    onSubmit() {
        const params = new HttpParams()
            .set('email', this.form.get('email').value)
            .set('InsuranceNo', this.form.get('insuranceNo').value);
        this.http
            .put(REQUEST_PW_RESET_MAIL_URL, {}, { params, notify: 'ERROR' })
            .subscribe(response => (this.resetRequested = true));
    }
}
