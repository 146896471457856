import { NavigationLink } from './navigationLinks.model';

export class NavigationLinkCategory {
    index: number;
    description: string;
    links: NavigationLink[];
    constructor(index: number, description: string, links: NavigationLink[]) {
        this.index = index;
        this.description = description;
        this.links = links;
    }
}
