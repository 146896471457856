<app-hero
    imagePath="/assets/img/astra_Versicherung_Wir_versichern_was_Sie_lieben.jpg"
    imageAlt="astra Versicherung AG - Wir versichern, was sie lieben"
    headline="<h3>Wir versichern,<br>was Sie lieben</h3><p class='no-margin-bottom'>Sparen Sie sich die Sorgen um das, was Ihnen lieb und teuer ist -<br>in nur wenigen Schritten sichern wir Sie dagegen ab.</p>"
></app-hero>

<div class="headline">
    <h2>{{ headline }}</h2>
    <h3>{{ subtitle }}</h3>
</div>

<div class="clearer"></div>
<div class="content-container">
    <div class="grid-container boxes">
        <div class="grid-1-1 align-center margin-bottom">
            <h1><strong>In wenigen Schritten zum Versicherungs&shy;schutz</strong></h1>
            <p class="big">Versichern einfach anders: kein Papierkram, schneller Abschluss.</p>
        </div>
        <div class="textbox grid-1-3 grid-sm-1-1 margin-bottom" *ngFor="let box of textboxes" id="{{ box.id }}">
            <h4>{{ box.title }}</h4>
            <p>{{ box.description }}</p>
        </div>
    </div>
</div>
<div class="clearer"></div>
<div class="bg-light-grey-a gutter-top double-gutter-bottom">
    <div class="content-container">
        <h2 class="">Unsere Topseller</h2>
        <div class="topseller-container">
            <a
                class="topseller-card"
                routerLink="/produkte/tablet-versicherung-plus"
                title="Tablet PLUS - Die umfassende Tablet- und Notebook-Versicherung für Schüler und digitale Klassen."
            >
                <img
                    class="hidden-xs hidden-lg"
                    src="/assets/img/astra-Versicherung_Tabletversicherung-PLUS_medium.jpg"
                    width="1325"
                    height="530"
                    alt="Tablet PLUS - Die umfassende Tablet- und Notebook-Versicherung für Schüler und digitale Klassen."
                />
                <img
                    class="hidden-md hidden-lg hidden-sm"
                    src="/assets/img/astra-Versicherung_Tabletversicherung-PLUS_small.jpg"
                    width="678"
                    height="530"
                    alt="Tablet PLUS - Die umfassende Tablet- und Notebook-Versicherung für Schüler und digitale Klassen."
                />
                <img
                    class="hidden-sm hidden-xs hidden-md"
                    src="/assets/img/product-headers/astra-Versicherung_Tabletversicherung-PLUS.jpg"
                    width="1920"
                    height="530"
                    alt="Tablet PLUS - Die umfassende Tablet- und Notebook-Versicherung für Schüler und digitale Klassen."
                />
                <img
                    class="overlay -big"
                    src="/assets/img/astra_Topseller_Tabletversicherung_Plus.png"
                    width="192"
                    height="192"
                />
                <h3>Tablet "PLUS"</h3>
                <p>
                    Die umfassende Tablet- und Notebook-Versicherung für Schüler und digitale Klassen.
                </p>
            </a>
            <a
                class="topseller-card"
                routerLink="/produkte/schueler-fahrrad-versicherung"
                title="Schüler-Fahrrad-Versicherung - Der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung"
            >
                <img
                    class="hidden-xs hidden-lg"
                    src="/assets/img/astra-Versicherungen_Schüler-Fahrrad-Versicherung_medium.jpg"
                    width="1325"
                    height="530"
                    alt="Schüler-Fahrrad-Versicherung - Der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung"
                />
                <img
                    class="hidden-md hidden-sm"
                    src="/assets/img/astra-Versicherungen_Schüler-Fahrrad-Versicherung_small.jpg"
                    width="678"
                    height="530"
                    alt="Schüler-Fahrrad-Versicherung - Der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung"
                />
                <img class="overlay" src="/assets/img/astra_Angebot_Fahrradversicherung.png" width="123" height="123" />
                <h3>Die Schüler-Fahrrad-Versicherung</h3>
                <p>
                    Der günstige Schutz bei Diebstahl, Beschädigung oder Zerstörung
                </p>
            </a>
            <a
                class="topseller-card"
                routerLink="/produkte/musikinstrumentenversicherung-schueler"
                title="Musikinstrumentenversicherung für Schüler - Inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!"
            >
                <img
                    class="hidden-xs hidden-lg"
                    src="/assets/img/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schueler_medium.jpg"
                    width="1325"
                    height="530"
                    alt="Musikinstrumentenversicherung für Schüler - Inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!"
                />
                <img
                    class="hidden-md hidden-sm"
                    src="/assets/img/astra_Versicherung_Musikinstrumente_small.jpg"
                    width="678"
                    height="530"
                    alt="Musikinstrumentenversicherung für Schüler - Inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!"
                />
                <img
                    class="overlay"
                    src="/assets/img/astra_Angebot_Musikinstrumentenversicherung.png"
                    width="123"
                    height="123"
                />
                <h3>Der Schutz für Schüler-Instrumente</h3>
                <p>
                    Inkl. Schutz bei Transport, Diebstahl und Brand-, Blitz- oder Wasserschäden!
                </p>
            </a>
            <a
                class="topseller-card"
                routerLink="/produkte/zahnzusatz2022"
                title="Zahnzusatzversicherung - Inkl. Schutz für mitversicherte Kinder und Jugendliche ab nur 9,52 € im Monat"
            >
                <img
                    class="hidden-xs hidden-lg"
                    src="/assets/img/astra_Versicherungen_Zahnzusatzversicherung_medium.jpg"
                    width="1325"
                    height="530"
                    alt="Zahnzusatzversicherung - Inkl. Schutz für mitversicherte Kinder und Jugendliche ab nur 9,52 € im Monat"
                />
                <img
                    class="hidden-md hidden-sm"
                    src="/assets/img/astra_Versicherungen_Zahnzusatzversicherung_small.jpg"
                    width="678"
                    height="530"
                    alt="Zahnzusatzversicherung - Inkl. Schutz für mitversicherte Kinder und Jugendliche ab nur 9,52 € im Monat"
                />
                <img
                    class="overlay"
                    src="/assets/img/astra_Angebot_Zahnzusatzversicherung.png"
                    width="123"
                    height="123"
                />
                <h3>Der Zahnzusatzschutz</h3>
                <p>
                    Für die ganze Familie: die günstige Zahnzusatzversicherung für gesetzlich Versicherte.
                </p>
            </a>
        </div>
    </div>
</div>
