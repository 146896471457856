import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { Notification } from './notification.model';
import { transition, style, trigger, animate } from '@angular/animations';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [
        trigger('fadeUp', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translate(100%, 0)' }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'none' }))
            ]),
            transition(':leave', [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))])
        ])
    ]
})
export class NotificationComponent implements OnInit {
    @Input() notificationCount: number;
    @Input() closeAfter: number;
    notifications: Notification[];

    constructor(public notificationService: NotificationService) {}

    ngOnInit() {
        this.notificationService.configure(this.notificationCount, this.closeAfter);
    }

    public removeNotification(notification: Notification) {
        this.notificationService.removeNotification(notification);
    }
}
