import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LegalViewComponent } from './legal-view/legal-view.component';
import { TermsViewComponent } from './terms-view/terms-view.component';
import { StartpageViewComponent } from './startpage-view/startpage-view.component';
import { AboutViewComponent } from './about-view/about-view.component';
import { ResetPwViewComponent } from './reset-pw-view/reset-pw-view.component';
import { ConfirmPwResetViewComponent } from './confirm-pw-reset-view/confirm-pw-reset-view.component';
import { ContactFormViewComponent } from './contact-form-view/contact-form-view.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { DataProtectionViewComponent } from './data-protection-view/data-protection-view.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginViewComponent,
    },
    {
        path: 'impressum',
        component: LegalViewComponent,
    },
    {
        path: 'agb',
        component: TermsViewComponent,
    },
    {
        path: 'start',
        component: StartpageViewComponent,
    },
    {
        path: 'ueber-uns',
        component: AboutViewComponent,
    },
    {
        path: 'passwort-vergessen',
        component: ResetPwViewComponent,
    },
    {
        path: 'neues-passwort/:token',
        component: ConfirmPwResetViewComponent,
    },
    {
        path: 'kontakt',
        component: ContactFormViewComponent,
    },
    {
        path: 'fehler/:errCode',
        component: ErrorViewComponent,
    },
    {
        path: 'datenschutz',
        component: DataProtectionViewComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PublicRoutingModule {}

export const routingComponents = [
    LegalViewComponent,
    TermsViewComponent,
    StartpageViewComponent,
    AboutViewComponent,
    ResetPwViewComponent,
    ConfirmPwResetViewComponent,
    ContactFormViewComponent,
    ErrorViewComponent,
    LoginViewComponent,
    DataProtectionViewComponent,
];
