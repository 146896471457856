import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { environment } from '../../../environments/environment';

export const isDebugMode = environment.debug;

const noop = () => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {

    get info() {
        return isDebugMode ? console.info.bind(console) : noop;
    }

    get warn() {
        return isDebugMode ? console.warn.bind(console) : noop;
    }

    get error() {
        return isDebugMode ? console.error.bind(console) : noop;
    }
}
