<div class="birthday-picker">
    <div class="picker-elements">
        <ng-container [formGroup]="picker">
            <div class="day-container">
                <select required formControlName="day">
                    <option [ngValue]="null" disabled>Tag</option>
                    <option *ngFor="let day of days" [ngValue]="day">{{ day }}</option>
                </select>
            </div>
            <div class="month-container">
                <select required formControlName="month">
                    <option [ngValue]="null" disabled>Monat</option>
                    <option
                        *ngFor="let month of months; let i = index"
                        [ngValue]="i < 9 ? '0' + (i + 1) : '' + (i + 1)"
                        >{{ month }}</option
                    >
                </select>
            </div>
            <div class="year-container">
                <select required formControlName="year">
                    <option [ngValue]="null" disabled>Jahr</option>
                    <option *ngFor="let year of years" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
        </ng-container>
    </div>
</div>
