export class ErrorMessage {
    errCode: string;
    headline: string;
    message: string;

    constructor(data: { errCode: string; headline?: string; message?: string }) {
        this.errCode = data.errCode;
        this.headline = data.headline || '';
        this.message = data.message || '';
    }
}
