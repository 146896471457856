import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { InsuranceType } from '../../models/insurance-type';
import { OrderStore, OrderState } from './order.store';

@Injectable({ providedIn: 'root' })
export class OrderQuery extends Query<OrderState> {
    public state$ = this.select();

    public order$ = this.select([
        'insuranceType',
        'product',
        'billingAddress',
        'contract',
        'payment',
        'insurants',
        'insuredObjects',
        'insurantsFamilyRelationship',
    ]);

    public product$ = this.select('product');

    public insuranceType$ = this.select('insuranceType');

    public currentStep$ = this.select('currentStep');

    public get currentStep(): string {
        return this.store.getValue().currentStep;
    }

    public get currentStepIndex(): number {
        const steps = this.store.getValue().product?.config.steps;
        if (!steps) {
            return -1;
        }
        return steps.findIndex(step => step === this.currentStep);
    }

    public get completedSteps(): string[] {
        return this.store.getValue().completedSteps;
    }

    public get productSlug(): string {
        return this.store.getValue().product?.slug || '';
    }

    public get insuranceType(): InsuranceType {
        return this.store.getValue().insuranceType;
    }

    constructor(protected store: OrderStore) {
        super(store);
    }
}
