import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
    @Input()
    imagePath: string;
    @Input()
    imageAlt: string;
    @Input()
    headline: string;
    @Input()
    imageAlign: string = 'center';
}
