import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-login-view',
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent implements OnDestroy {
    public form: FormGroup;
    private unsubscribe$ = new Subject<void>();
    private redirectUrl: string | null = null;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.subscribeToLoginState();
        this.subscribeToRouteParams();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public submit(): void {
        this.authService.login(this.form.get('email').value, this.form.get('password').value).subscribe();
    }

    public invalid(): boolean {
        return this.form.pristine || this.form.invalid;
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    private navigate(): void {
        if (this.redirectUrl !== null) {
            this.router.navigate([this.redirectUrl], { replaceUrl: true });
        } else {
            this.router.navigate(['/portal'], { replaceUrl: true });
        }
    }

    private subscribeToRouteParams(): Subscription {
        return this.route.paramMap
            .pipe(
                map(params => {
                    return params.get('redirect');
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(res => {
                this.redirectUrl = res;
            });
    }

    private subscribeToLoginState(): Subscription {
        return this.authService.isLoggedIn$
            .pipe(
                filter(res => res === true),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.navigate();
            });
    }
}
