import { FormGroup, Validators } from '@angular/forms';
import { addMonths } from 'date-fns';
import { of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';
import { CommonValidators } from '../../shared/validators/common-validators';
import { regexes } from '../../utils/regexes.const';
import { conradDevices, lensManufacturers } from './conrad-b2c';

const config: ProductConfig = {
    steps: ['', 'versicherte-objekte', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: false,
    requiresNoPriorDefect: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    hideSummarySidebarOnLandingPage: true,
    headerMode: 'conrad',
    companiesOnly: true,
};

const presentation: ProductPresentation = {
    description: `
        <p class="semibold">
            Unsere Elektronikversicherung für Geschäftskunden schützt nahezu all Ihre gewerblichen Geräte 
            vor möglichen Risiken und Schäden.
        </p>
        <p>
            Ganz gleich ob Flüssigkeitsschäden, eine Beschädigung durch Dritte oder Bruchschäden am Display: 
            Die astra Versicherung für gewerbliche Elektronikgeräte greift im Notfall ein und schützt Ihr 
            Unternehmen vor unerwarteten weiteren Kosten.
        </p>
    `,
    bulletPoints: [],
    imageUrl: 'assets/img/product-headers/astra_versicherung_conrad_b2b.jpg',
    imageAlt: 'B2B Conrad-Elektronikversicherung by astra',
    imageHeadline: '<h3 class="no-margin-bottom">Die Elektronikversicherung speziell für Ihr Gewerbe</h3>',
    docIPIDUrl: 'astra_IPID_Elektronik_Business_2022.pdf',
    docAVBUrl: 'astra_AVB_Elektronik_Business_2022.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Elektronik_Business_2022.pdf',
    //pageTitle: 'Seitentitel (optional)',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Zu versichernde Geräte',
    description: '',
    objectDescriptionSingular: 'Gerät',
    objectDescriptionPlural: 'Geräte',
    max: 999,
    addButtonLabel: 'Gerät hinzufügen',
    note: `<strong>Hinweis:</strong> Sollte Ihnen die IMEI noch nicht bekannt sein, haben Sie die
        Möglichkeit diese nach erfolgtem Abschluss im LogIn-Bereich jederzeit zu ergänzen. Bitte beachten
        Sie, dass der Versicherungsschutz erst ab Ergänzung der IMEIs besteht.`,
    controls: [
        {
            name: 'type',
            label: 'Gerätetyp',
            type: 'select',
            preset: null,
            required: true,
            validators: [Validators.required],
            size: 'm',
            options: () => {
                return of(
                    [
                        'Smartphone',
                        'Smartwatch',
                        'Kamera',
                        'Notebook',
                        'Beamer',
                        'Monitor',
                        'Fernseher',
                        'PC',
                        'Konsole',
                        'VR-Brille',
                        'Kopfhörer',
                        'Tablet',
                        'Objektiv',
                    ].map(deviceType => {
                        return { label: deviceType, value: deviceType };
                    })
                );
            },
        },
        {
            name: 'description',
            label: 'Hersteller',
            type: 'select-with-input',
            required: true,
            validators: [Validators.required],
            size: 'm',
            options: (formGroup: FormGroup) => {
                const deviceType = formGroup.get('type');
                if (deviceType) {
                    return deviceType.valueChanges.pipe(
                        startWith(deviceType.value),
                        map(dt => {
                            if (dt === 'Objektiv') {
                                return lensManufacturers.map(manufacturer => {
                                    return { label: manufacturer, value: manufacturer };
                                });
                            }
                            const d = conradDevices.find(device => device.name === dt);
                            if (d) {
                                return d.manufacturers.map(manufacturer => {
                                    return { label: manufacturer, value: manufacturer };
                                });
                            }
                            return [];
                        })
                    );
                } else {
                    return of([]);
                }
            },
        },
        {
            name: 'model',
            label: 'Modell und Speicherkapazität',
            type: 'text',
            required: true,
            validators: CommonValidators.something,
            size: 'l',
        },
        {
            name: 'value',
            label: 'Kaufpreis',
            type: 'value',
            errorMsg: 'Bitte geben Sie einen Betrag bis max. € 10.000,- ein.',
            required: true,
            validators: [Validators.required, Validators.pattern(regexes.valueTillTenthousand)],
            size: 's',
        },
        {
            name: 'date',
            label: 'Kaufdatum',
            type: 'date',
            required: true,
            errorMsg: {
                invalidDateFormat: 'Bitte geben Sie ein valides Datum im Format TT.MM.JJJJ ein.',
                dateComponentIsNaN: 'Bitte geben Sie ein valides Datum im Format TT.MM.JJJJ ein.',
                invalidDay: 'Bitte geben Sie einen gültigen Tag an.',
                dateToLate: 'Das Kaufdatum darf nicht in der Zukunft liegen.',
                dateToEarly: 'Das Kaufdatum darf nicht länger als sechs Monate zurück liegen.',
            },
            validators: CommonValidators.dateBetween(addMonths(new Date(), -6), new Date()),
            size: 's',
        },
        {
            name: 'detail',
            label: 'Seriennummer',
            type: 'text',
            size: 'm',
        },
    ],
};

export const conradB2B: Product = new Product({
    slug: 'cnrdb2b',
    title: 'Conrad-Elektronikversicherung',
    fullTitle: 'B2B Conrad-Elektronikversicherung by astra',
    insuranceTypeName: 'Elektronikversicherung B2B',
    config,
    presentation,
    insuredObjectsForm,
});

// see conradb2c for a list of device types. use this only if a different list is necessary:
// export const conradDevices: ConradDevice[] = [];
