import { NgModule } from '@angular/core';

import { PublicRoutingModule, routingComponents } from './public-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule, PublicRoutingModule],
    declarations: [routingComponents],
})
export class PublicModule {}
