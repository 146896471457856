<div class="content-container double-gutter-vertical">
    
    <div class="grid-container" *ngIf="state=='pending'">
        <div class="grid-1-1">
            <h2 class="no-margin-bottom">Neues Passwort anfordern</h2>
            <hr>
        </div>
        <div class="grid-1-1 double-gutter-vertical">
            <p>Ihre Anfrage wird geprüft. Wir bitten um einen Moment Geduld.</p>
        </div>
    </div>

    <div class="grid-container" *ngIf="state=='expired' || state=='error'">
        <div class="grid-1-1">
            <h2 class="no-margin-bottom">Dieser Bestätigungslink ist nicht gültig</h2>
            <hr>
        </div>
        <div class="grid-1-1 double-gutter-vertical">
            <p>Der Bestätigungslink, den Sie aufgerufen haben, ist entweder ungültig oder abgelaufen.</p>
            <p>Bestätigungslinks zur Anforderung eines neuen Passworts können nur einmal benutzt werden und sind aus Sicherheitsgründen nur für 4 Stunden gültig.</p>
            <p>Wenn Sie weiterhin ein neues Passwort anfordern möchten, nutzen Sie bitte die Funktion <a routerLink="/passwort-vergessen">Passwort vergessen</a>, um einen neuen Bestätigungslink anzufordern.</p>
        </div>
    </div>

    <div class="grid-container" *ngIf="state=='success'">
        <div class="grid-1-1">
            <h2 class="no-margin-bottom">Ihr neues Passwort wurde versendet</h2>
            <hr>
        </div>
        <div class="grid-1-1 double-gutter-vertical">
            <p>Wir haben Ihnen eine E-Mail mit Ihrem neuen Passwort geschickt.</p>
            <p>Wenn Sie keine E-Mail erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.</p>
        </div>
    </div>
</div>

