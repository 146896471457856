import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-startpage',
    templateUrl: './startpage-view.component.html',
    styleUrls: ['./startpage-view.component.scss'],
})
export class StartpageViewComponent {
    headline: 'In wenigen Schritten zum Versicherungsschutz';
    subtitle: 'Versichern einfach anders: kein Papierkram, schneller Abschluss.';
    textboxes = [
        {
            title: 'Versicherung wählen',
            description: 'Wählen Sie aus, was Sie versichern möchten.',
            id: 'snap',
        },
        {
            title: 'Daten eintragen',
            description:
                'Hier benötigen wir paar wenige Angaben wie Anschrift, Bankverbindung oder z.B. die E-Mail-Adresse, an die wir das Versicherungszertifikat verschicken.',
            id: 'data',
        },
        {
            title: 'Absenden',
            description:
                'Prüfen Sie nur noch Ihre Daten und klicken Sie dann auf „Abschließen“. Ihr Versicherungsschutz beginnt sofort.',
            id: 'tick',
        },
    ];
}
