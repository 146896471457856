import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation, Variants } from '../../models/product';
import { CommonValidators } from '../../shared/validators/common-validators';

const config: ProductConfig = {
    steps: ['', 'versicherte-objekte', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: true,
    requiresMedicalData: false,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: true,
    naturalPersonsOnly: false,
};

const presentation: ProductPresentation = {
    description: `
    <p><strong class="blue">Nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument! Die Musikinstrumenten-Versicherung für Schulen, Musikklassen oder Instrumenten-Pools: Der Rundumschutz für wertvolle Instrumente bei Verlust sowie Schäden durch Transport, Brand, Blitz,  Wasser sowie Schäden durch Unachtsamkeit, etc.</strong></p>
    <p>Instrumente, die von der Schule an Schüler und Schülerinnen für den Musikunterricht, zum Üben oder schulische Veranstaltungen zur Verfügung gestellt werden, sind meist viel häufiger in Gebrauch und im Schulbetrieb ganz anderen Gefahren ausgesetzt als ein Schülerinstrument, das ausschließlich privat und zuhause benutzt wird.</p>
    <p>Ob Beschädigungen durch Unachtsamkeit oder beim Transport, oder Schäden durch Brand, Blitz oder Wasserschäden - diese Risiken deckt diese Musikinstrumenten-Versicherung für Schulen komplett ab – ebenso wie Schäden durch Herunterfallen oder Umstoßen sowie Schäden durch Dritte und Verlust durch Diebstahl.</p>
    <p>Sie haben zudem – bei identischen Tarifen – die Wahl zwischen zwei Vertragsmodellen: Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten. Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest an einen einzigen Schüler verleihen oder ausgeben.</p>
    <p><strong class="blue">Sichern auch Sie Ihre Schul-Instrumente ab – der Rundum-Schutz für Instrumente der astra Versicherung AG für nur 9,<sup>99</sup>&nbsp;<span class="currency-sign">€</span> pro Jahr je Instrument.</strong></p>
    `,
    bulletPoints: [
        'Ein Vertrag für beliebig viele Schul-Instrumente',
        'Der Rundumschutz für Musikinstrumente',
        'Schutz bei Diebstahl und Herunterfallen',
        'Schutz bei Transportschäden',
        'Schutz bei Brand, Blitz oder Wasserschäden',
    ],
    imageUrl: 'assets/img/product-headers/astra-Versicherung_Musikinstrumentenversicherung_fuer_Schulen.jpg',
    imageAlt: 'Der Instrumentenschutz für Schulen und Musikklassen von astra',
    imageHeadline: '<h3>Der<br>Instrumentenschutz<br>für Schulen und Musikklassen</h3>',
    docIPIDUrl: 'astra_IPID_Musikinstrumente_Schulen.pdf',
    docAVBUrl: 'astra_AVB_Musikinstrumente_Schulen.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente_Schulen.pdf',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Angaben zu versicherten Instrumenten',
    description: '',
    objectDescriptionSingular: 'Instrument',
    objectDescriptionPlural: 'Instrumente',
    max: 999,
    addButtonLabel: 'Weitere Instrument hinzufügen',
    controls: [
        {
            name: 'description',
            label: 'Instrumentenart',
            type: 'text',
            required: true,
            validators: CommonValidators.something,
            preset: '',
        },
        {
            name: 'detail',
            label: 'Hersteller',
            type: 'text',
            required: true,
            validators: CommonValidators.something,
        },
    ],
};

const variants: Variants = {
    label: 'Bitte wählen Sie zunächst die gewünschte Vertragsart.',
    description:
        'Wählen Sie die Vertragsart „Instrumentenpool“, wenn Sie die Instrumente Ihrer Schule ohne Zuordnung an einen bestimmten Schüler oder eine bestimmte Schülerin versichern möchten. Wählen Sie „Schülerinstrumente“, wenn Ihre Instrumente über einen längeren Zeitraum fest an einen einzigen Schüler verleihen oder ausgeben.',
    options: [
        {
            productSlug: 'musikinstrumentenversicherung-schulen-p',
            title: 'Vertragsart Instrumentenpool',
            isSelf: true,
        },
        {
            productSlug: 'musikinstrumentenversicherung-schulen-k',
            title: 'Vertragsart Schülerinstrumente',
            isSelf: false,
        },
    ],
    showOn: 'insuredObjects',
    keepOnToggle: [
        'billingAddress',
        'completedSteps',
        'currentStep',
        'contract',
        'payment',
        'additionalResellerData',
        'resellerUrl',
    ],
    removeCompletedSteps: ['versicherte-personen', 'versicherte-person'],
};

export const musikinstrumentenversicherungSchulenPool: Product = new Product({
    slug: 'musikinstrumentenversicherung-schulen-p',
    title: 'Musikinstrumentenversicherung für Schulen',
    fullTitle: 'Musikinstrumentenversicherung für Schulen',
    insuranceTypeName: 'Musik Instrumente',
    config,
    presentation,
    insuredObjectsForm,
    variants,
});
