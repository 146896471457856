import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-data-protection-view',
    templateUrl: './data-protection-view.component.html',
    styleUrls: ['./data-protection-view.component.scss'],
})
export class DataProtectionViewComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
