import { Injectable } from '@angular/core';
import { Subject, Observable, timer } from 'rxjs';
import { Notification } from './notification.model';
import { NotificationType } from './notification-type.enum';
import { share } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class NotificationService {
    public notificationsSubject: Subject<Notification[]> = new Subject<Notification[]>();
    public notificationStack: Notification[] = [];

    private notificationCount = 99;
    private closeAfter = -1;

    constructor(private log: LoggerService) {
        this.log.info('Notification service initialized.');
    }

    public configure(notificationCount: number = 99, closeAfter: number = -1): void {
        if (notificationCount > 1 && closeAfter >= -1) {
            this.notificationCount = notificationCount;
            this.closeAfter = closeAfter;
        } else {
            this.log.warn('Invalid values given. Keeping previous values.');
        }
    }

    public notify(
        message: string,
        type: NotificationType,
        closeAfter: number = this.closeAfter,
        dismissible: boolean = true
    ): void {
        if (closeAfter < -1) {
            this.log.warn('AutoClose must be >= -1. Using default instead.');
            closeAfter = this.closeAfter;
        }
        const notification = { type, message, dismissible };

        this.notificationStack.push(notification);
        this.notificationsSubject.next(this.notificationStack);
        if (closeAfter > -1) {
            this.scheduleNotificationHiding(closeAfter, notification);
        }
        this.log.info('Current notifications:');
        this.log.info(this.notificationStack);
    }

    public notifications(): Observable<any> {
        return this.notificationsSubject.asObservable() /* .pipe(share()) */;
    }

    private scheduleNotificationHiding(timeout: number, notification: Notification): void {
        const timeOut = timer(timeout);
        timeOut.subscribe(() => this.removeNotification(notification));
    }

    public removeNotification(notification: Notification): void {
        const index = this.notificationStack.indexOf(notification);
        if (index !== -1) {
            this.notificationStack.splice(index, 1);
            this.notificationsSubject.next(this.notificationStack);
        }
    }
}
