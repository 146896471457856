import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-person', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
    insurantMaxAge: 18,
};

const presentation: ProductPresentation = {
    description: `
    <p><strong class="blue">Die Nachhilfe-Versicherung „Plus“: Bei längerer Krankheit zu Hause oder im Krankenhaus gut betreut – inklusive Rooming-In-Leistungen für eine Begleitperson.</strong></p>
    <p>Wenn ein Kind wegen einer längeren Krankheit zu Hause bleiben muss oder gar ein Krankenhaus-Aufenthalt notwendig wird, ist das für jedes Kind und seine Familie eine außerordentliche Belastung in mehrfacher Hinsicht.</p>
    <p>Zieht sich die Krankheit über einen längeren Zeitraum, kommen durch verpassten Unterrichtsstoff Probleme in der Schule hinzu. Mehrere Wochen Unterrichtsausfall lassen sich kaum noch aufholen – die Folgen sind meist schlechte Noten und Zeugnisse – im schlimmsten Fall ist die Versetzung gefährdet.</p>
    <p>Mit der Nachhilfe-Versicherung „Plus“ schützen Sie Ihr Kind davor, dass es nach einer Krankheit nicht noch zusätzlich durch schulische Probleme belastet wird: Wir übernehmen die Kosten für pädagogische Betreuung und Nachhilfe ab dem 7. Schultag Abwesenheit bis zu einer Höhe von 400 <span class="currency-sign">€</span>, ab dem 14. Schultag sogar bis zu 600 <span class="currency-sign">€</span>.</p>
    <p>Bei Aufenthalt im Krankenhaus bereits ab dem 5. und 10. Schultag (bis zu 400 <span class="currency-sign">€</span>/600<span class="currency-sign">€</span>).</p>
    <p>Bei Kindern bis 14 Jahre werden zudem die Unterbringungs- und Verpflegungskosten („Rooming-In“) einer Begleitperson im Krankenhaus bis zu 50 <span class="currency-sign">€</span> erstattet – und dies schon ab dem ersten Tag – egal wie lange der Aufenthalt dauert.</p>
    <p>Und: Sollten Sie eine Nachhilfeleistung einmal nicht in Anspruch nehmen müssen, werden einmalig 100,- Euro als Pauschalleistung erstattet.</p>
    <p><strong class="blue">Nur 29,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr: Der Versicherungsschutz bei Schulausfall durch längere Krankheit oder Krankenhausaufenthalt, damit schulische Folgeprobleme erst gar nicht entstehen – bei Kindern bis 14 Jahren inkl. Rooming-in-Leistungen für die Eltern ab dem ersten Tag!</strong></p>
    `,
    bulletPoints: [
        'Pädagogische Betreuung durch professionelle Nachhilfe bei längerer Krankheit zu Hause',
        'Nachhilfe bei längerem Aufenthalt im Krankenhaus sowie danach im häuslichen Umfeld.',
        'Unterstützt das Wohlbefinden der Kinder durch Rooming-in von Eltern im Krankenhaus ab dem ersten Tag.',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherungen_Nachhilfeversicherung-PLUS.jpg',
    imageAlt:
        'Die Nachhilfe-Versicherung der astra Versicherung AG - Pädagogische Betreuung bei Krankenhaus-Aufenthalt inkl. Rooming-In',
    imageHeadline:
        '<h3>Die Nachhilfe-Versicherung „Plus“</h3><p class="no-margin-bottom">Nachhilfe bei Krankheit – zu Hause und im Krankenhaus inkl. Rooming-In</p>',
    docIPIDUrl: 'astra_IPID_Nachhilfe_Plus.pdf',
    docAVBUrl: 'astra_AVB_Nachhilfe_Plus.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Nachhilfe_Plus.pdf',
};

export const nachhilfePlus: Product = new Product({
    slug: 'nachhilfe-plus',
    title: 'Nachhilfe-Versicherung "Plus"',
    fullTitle: 'Nachhilfe-Versicherung "Plus"',
    insuranceTypeName: 'Nachhilfe Plus',
    config,
    presentation,
});
