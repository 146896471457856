import { DocumentTypeId } from './documentTypeId.const';

export interface IDamageDocumentUploadMeta {
    documentTypeId: DocumentTypeId;
    description: string;
}

export class DamageDocument {
    Id: number;
    DamageId: number;
    DocumentTypeId: DocumentTypeId;
    Filename: string;
    Description: string;
}
