import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { ContractData } from '../../models/contract-data';
import { BillingAddress } from '../../models/billing-address';
import { InsuranceType } from '../../models/insurance-type';
import { Insurant } from '../../models/insurant';
import { InsuredObject } from '../../models/insured-object';
import { PaymentData } from '../../models/payment-data';
import { Product } from '../../models/product';
import { CustomerService } from '../../core/customer/customer.service';
import { customerToOrderState } from '../../mappers/customer';

export interface OrderState {
    currentStep: string;
    completedSteps: string[];
    insuranceType?: InsuranceType;
    product?: Product;
    billingAddress?: Partial<BillingAddress>;
    contract?: Partial<ContractData>;
    payment?: Partial<PaymentData>;
    insurants: Insurant[];
    insuredObjects: InsuredObject[];
    resellerUrl?: string;
    additionalResellerData?: string;
    insurantsFamilyRelationship?: boolean;
}

export function createInitialState(): OrderState {
    return {
        currentStep: '',
        completedSteps: [],
        insurants: [],
        insuredObjects: [],
    };
}

/**
 * @description stores information relevant for the order form:
 *  User and order data, available steps and current step, product
 *  config and so on. Attention: Components should never inject this!
 *  Use OrderQuery to gain access to the current state of the store,
 *  and OrderService to update its state.
 * @see OrderService
 * @see OrderQuery
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order', resettable: true })
export class OrderStore extends Store<OrderState> {
    public constructor(private customerService: CustomerService) {
        super(createInitialState());
    }

    public reinit(state: Partial<OrderState>): void {
        let initialState: OrderState;
        if (this.customerService.customer !== null) {
            initialState = {
                ...createInitialState(),
                ...customerToOrderState(this.customerService.customer),
                ...state,
            };
        } else {
            initialState = { ...createInitialState(), ...state };
        }
        this._setState(initialState);
    }
}
