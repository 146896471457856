import { Type } from 'class-transformer';
import { DamageState } from './damageState.model';

export interface NewDamageDto {
    Id: number;
    ContractId: number;
    EntryDate: string;
    DamageDate: string;
    Location: string;
    DescriptionEvent: string;
    DescriptionDamage: string;
    Notes: string;
}

export class Damage {
    Id: number;
    ContractId: number;
    EntryDate: string;
    DamageDate: string;
    Location: string;
    DescriptionEvent: string;
    DescriptionDamage: string;
    Notes: string;
    @Type(() => DamageState)
    DamageState: DamageState;
}
