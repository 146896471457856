import { Validators } from '@angular/forms';
import { EMPTY, of } from 'rxjs';
import { expand, map, tap, toArray } from 'rxjs/operators';
import { InsuredObjectFormConfig } from '../../models/insured-object-form-config';
import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-objekte', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: true,
};

const presentation: ProductPresentation = {
    description: `
        <p><strong>Der umfassende Premium-Schutz für Instrumente im Wert bis zu 10.000 <span class="currency-sign">€</span> inkl. Schutz bei Verlust sowie Schäden durch Transport, Brand, Blitz oder Wasser etc.</strong></p>
        <p>Hochwertige Instrumente, die Berufsmusiker oder angehende Musik-Virtuosen während den Proben und bei Konzerten einsetzen, sind besonderen Gefahren ausgesetzt: Ob Transport im Auto, per Bahn oder Flugzeug, Fahrten zu Proben und Konzerten sowie Schäden durch Dritte bei Auftritten. Diese weltweit geltende Spezial-Versicherung deckt all diese Gefahren ab. Selbstverständlich versichert sind auch Schäden durch Dritte, Verlust durch Raub und Diebstahl sowie Schäden durch Brand, Blitz, Explosion und Elementarschäden. Sogar versehentliches Umstoßen oder Herunterfallen sind versichert.</p>
        <p>Nicht versicherbar sind Instrumente von Rockbands oder vergleichbaren Bands.</p>
        <p><strong class="blue">Der weltweit geltende Schutz der astra Versicherung AG für wertvolle Instrumente.</strong></p>
    `,
    bulletPoints: [
        'Der weltweite Premium-Instrumenten-Schutz bis 10.000 € Wert',
        'Inklusive Schutz für Zubehör und Noten',
        'Schutz bei Diebstahl, Raub, Veruntreuung',
        'Schutz bei Umstoßen oder Herunterfallen',
        'Schutz bei Transportschäden und -Unfällen',
        'Schutz bei Brand, Blitz, Wasser- und Elementarschäden',
    ],
    imageUrl: 'assets/img/product-headers/astraversicherung_Musikinstrumentenversicherung_Premium.jpg',
    imageAlt: 'astra Musikinstrumentenversicherung Premium: Der umfassende Schutz für hochwertige Instrumente.',
    imageHeadline:
        '<h3>Der umfassende<br>Schutz für hochwertige Instrumente</h3><p class="no-margin-bottom">inkl. umfassendem Schutz bei Transport.</p>',
    docIPIDUrl: 'astra_IPID_Musikinstrumente_Premium.pdf',
    docAVBUrl: 'astra_AVB_Musikinstrumente_Premium.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Musikinstrumente_Premium.pdf',
    pageTitle: 'Musikinstrumentenversicherung Premium | ab 37,00 € / Jahr | astra Versicherung',
};

const insuredObjectsForm: InsuredObjectFormConfig = {
    headline: 'Zu versicherndes Instrument',
    description:
        'Ihr Versicherungsbeitrag orientiert sich an Art und Wert des zu versichernden Objekts. Bitte geben Sie den Neupreis des Objekts an, gerundet auf die nächste Hunderterstelle.',
    objectDescriptionSingular: 'Instrument',
    max: 1,
    controls: [
        {
            name: 'description',
            label: 'Zu versicherndes Objekt',
            type: 'select',
            required: true,
            validators: [Validators.required],
            options: 'getInsurableObjects',
            preset: null,
            optionsParams: [6],
        },
        {
            name: 'value',
            label: 'Neupreis',
            type: 'select',
            required: true,
            validators: [Validators.required, Validators.min(1500), Validators.max(10000)],
            options: () =>
                of(1500).pipe(
                    expand(val => {
                        if (val === 10000) {
                            return EMPTY;
                        }
                        return of(val + 100);
                    }),
                    map(val => {
                        return {
                            label: `${val === 1500 ? 'bis ' : ''}${Math.floor(val / 1000)}.${val % 1000 || '000'},00 €`,
                            value: val,
                        };
                    }),
                    toArray()
                ),
            preset: null,
        },
    ],
};

export const musikinstrumentenversicherungPremium: Product = new Product({
    slug: 'musikinstrumentenversicherung',
    title: 'Musikinstrumentenversicherung Premium',
    fullTitle: 'Musikinstrumentenversicherung Premium',
    insuranceTypeName: 'Musik',
    config,
    presentation,
    insuredObjectsForm,
});
