export class Country {
    Id: number;
    Country: string;
}

export class City {
    Id: number;
    City: string;
    Zip: string;
}

export interface ILockersNetErrorMessage {
    ErrorCode: number;
    ErrorText: string;
}
