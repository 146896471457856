import { ErrorMessage } from './errorMessage.model';

export const errorMessages: ErrorMessage[] = [
    new ErrorMessage({
        errCode: 'keine-versicherungsdaten',
        headline: 'Versicherungsdaten konnten nicht geladen werden',
        message: `
            <p>Derzeit besteht keine Verbindung zu unserem Sicherheitsserver. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</p>
        `,
    }),
];
