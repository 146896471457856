import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import CookieManager from '../../utils/cookie-manager';

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class CookieSettingsService {
    public cookieManager = new CookieManager('cookiesAllowed');
    public showCookieSettingsDialog: boolean;
    constructor() {
        this.showCookieSettingsDialog = !this.cookieManager.getValue('cookiesAllowed');
        this.initScripts();
    }

    public initScripts(): void {
        this.initGA();
    }

    private initGA(): void {
        if (this.cookieManager.getAllowance('google') && typeof gtag === 'undefined') {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = environment.gtagUrl;
            script.onload = () => {
                window['dataLayer'] = window['dataLayer'] || [];
                window['gtag'] = function(...args: any[]) {
                    window['dataLayer'].push(arguments);
                };
                gtag('js', new Date());
                gtag('config', environment.googleAnalyticsId, { send_page_view: false, anonymize_ip: true });
                gtag('config', environment.googleConversionId);
                // track the current page
                gtag('event', 'page_view');
            };
            document.getElementsByTagName('body')[0].appendChild(script);
        }
    }
}
