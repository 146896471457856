import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class SpinnerComponent implements OnInit, OnDestroy {
    unsubscribe$: Subject<void> = new Subject<void>();
    spinning: boolean = false;
    constructor(private spinnerService: SpinnerService) {}

    ngOnInit() {
        this.spinnerService.spinning.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
            this.spinning = val;
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
