import { Component, OnInit, Input } from '@angular/core';

/**
 *  @class InfoBoxComponent
 *  @desc displays an array of strings as a nice list inside a grey box.
 *      Headline, background color and wether the box floats can also
 *      be set.
 *  @example
 *      <app-info-box
 *          [bulletPoints]="['first', 'second', 'third']" // array of strings displayed as bullet point list
 *          floatBox="left" // the box floats left
 *          headline="Example Box" // the headline of the box
 *          backgroundColor="grey" // set a background color class (grey is default, others not implemented yet)
 *      >
 *          <p>This paragraph will be displayed inside the box!</p>
 *      </app-info-box>
 *
 */
@Component({
    selector: 'app-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
    @Input() headline: string;
    @Input() bulletPoints: string[];
    @Input() backgroundColor: string = 'grey';
    @Input() floatBox: string = 'none';

    constructor() {}

    get hasBulletPoints(): boolean {
        return this.bulletPoints !== null && typeof this.bulletPoints !== 'undefined' && !!this.bulletPoints.length;
    }

    get float(): string {
        return 'float-' + this.floatBox;
    }
}
