import { OrderState } from '../order/state/order.store';
import { InsurantsFormConfig } from './insurants-form-config';
import { InsuredObjectFormConfig } from './insured-object-form-config';

export interface ProductPresentation {
    description: string;
    bulletPoints: string[];
    pageTitle?: string;

    imageUrl: string;
    imageAlt: string;
    imageHeadline: string;

    docIPIDUrl: string;
    docAVBUrl: string;
    docAddInfoUrl: string;
    docAnzeigepflichtUrl?: string;
}

export interface ProductConfig {
    /**
     * @description array of form step slugs. customer
     *  will complete form steps in the given order.
     */
    steps: string[];

    /**
     * @description displayed next to product price. Use this
     *  to show that a product is tax exempt or price
     *  includes VAT
     * @default ''
     */
    taxInfo?: string;

    /**
     * @description max allowed age of insurant.
     * @default 99
     */
    insurantMaxAge?: number;

    /**
     * @description if true customers must consent to transfer of
     *  medical data
     * @default false
     */
    requiresMedicalData?: boolean;

    /**
     * @description if true customers must declare that insured
     *  objects where not damaged before the insurance
     *  contract start
     * @default false
     */
    requiresNoPriorDefect?: boolean;

    /**
     * @description if true, price will not be displayed
     *  on product description page, since it will
     *  be calculated by InsureNet
     * @default false
     */
    hasFlexPrice?: boolean;

    /**
     * @description if true, price on description page
     *  will be prefixed with "ab"
     * @default false
     */
    showPriceFrom?: boolean;

    /**
     * @description if true, input for discount codes
     *  is displayed on submit page
     * @default true
     */
    showDiscountInput?: boolean;

    /**
     * @description if true a second info box will be
     *  displayed with info about bulk insuring
     *  for companies
     * @default false
     */
    showB2BInfo?: boolean; // TODO: check if we can get rid of this

    /**
     * @description if true a info box will be displayed
     *  on the insured objects page, informing
     *  the customer about the option to place
     *  bulk orders directly with customer service.
     * @default false
     */
    showBulkServiceInfo?: boolean;

    /**
     * @description if true, only titles for natural
     *  persons (Herr/Frau) are shown on the
     *  billing address form. Customers logged
     *  in with a company account are not
     *  allowed to order this product. This
     *  will always be false when companiesOnly
     *  is set to true.
     * @default true
     */
    naturalPersonsOnly?: boolean;

    /**
     * @description if true, the title selection input
     *  on the billing address form is hidden
     *  and defaults to company. Customers logged
     *  in with a non-company account are not
     *  allowed to order this product. If this
     *  is set to true naturalPersonsOnly will
     *  be set to false.
     * @default false
     */
    companiesOnly?: boolean;

    /**
     * @description set to true when the sidebar should
     *  be hidden on the product landing page.
     *  Used for custom product landing pages.
     * @default false
     */
    hideSummarySidebarOnLandingPage?: boolean;

    /**
     * @description should insurantsFamilyRelationship
     *  be set to true by default when product can
     *  have only one insurant.
     *  (for multiple insurants use
     *  InsurantsFormConfig.requiresInsurantsFamilyRelationship)
     * @default false
     */
    setFamilyRelationshipOnSingleInsurant?: boolean;

    /**
     * @description how many months in advance of
     *  the contract start an order is
     *  allowed.
     * @default 12
     */
    monthsInAdvance?: number;

    /**
     * @description set to 'day' when customer should
     *  be able to select a precise date for
     *  contract start. Set to 'month' if
     *  customer only should only be able to
     *  select a starting month.
     * @default 'day'
     */
    contractStartMode?: 'day' | 'month';

    /**
     * @description set to 'default' if normal
     *  header and footer should be visible.
     *  Use the appropriate keyword for
     *  product specific header and footer.
     * @
     * @default 'default'
     */
    headerMode?: 'default' | 'conrad';
}

export interface ProductVariant {
    productSlug: string;
    title: string;
    isSelf: boolean;
}

export interface Variants {
    label: string;
    description?: string;
    options: ProductVariant[];
    showOn: 'productInfo' | 'insuredObjects';
    keepOnToggle?: Array<keyof OrderState>;
    removeCompletedSteps?: string[];
}

export interface ProductOptions {
    slug: string;
    title: string;
    fullTitle?: string;
    insuranceTypeName: string;

    presentation?: ProductPresentation;
    config: ProductConfig;
    insuredObjectsForm?: InsuredObjectFormConfig;
    insurantsForm?: InsurantsFormConfig;
    variants?: Variants;
    legacy?: boolean;
}

export class Product implements ProductOptions {
    public slug: string;
    public title: string;
    public fullTitle: string;
    public insuranceTypeName: string;
    public legacy: boolean;
    public presentation: ProductPresentation | undefined;
    public config: ProductConfig = {
        steps: [''],
    };
    public insuredObjectsForm: InsuredObjectFormConfig | undefined;
    public insurantsForm: InsurantsFormConfig | undefined;
    public variants: Variants | undefined;

    constructor(options: ProductOptions) {
        this.slug = options.slug;
        this.title = options.title;
        this.fullTitle = options.fullTitle || options.title;
        this.insuranceTypeName = options.insuranceTypeName;
        this.legacy = options.legacy ?? false;

        this.presentation = options.presentation;

        this.config.steps = options.config.steps;
        this.config.taxInfo = options.config.taxInfo ?? '';
        this.config.requiresMedicalData = options.config.requiresMedicalData ?? false;
        this.config.hasFlexPrice = options.config.hasFlexPrice ?? false;
        this.config.showPriceFrom = options.config.showPriceFrom ?? false;
        this.config.showDiscountInput = options.config.showDiscountInput ?? true;
        this.config.showB2BInfo = options.config.showB2BInfo ?? false;
        // will be overridden by companies only setting, since they are mutualy exclusive:
        this.config.naturalPersonsOnly =
            options.config.companiesOnly === true ? false : options.config.naturalPersonsOnly ?? true;
        this.config.companiesOnly = options.config.companiesOnly ?? false;
        this.config.insurantMaxAge = options.config.insurantMaxAge ?? 99;
        this.config.showBulkServiceInfo = options.config.showBulkServiceInfo ?? false;
        this.config.monthsInAdvance = options.config.monthsInAdvance ?? 12;
        this.config.contractStartMode = options.config.contractStartMode ?? 'day';
        this.config.hideSummarySidebarOnLandingPage = options.config.hideSummarySidebarOnLandingPage ?? false;
        this.config.headerMode = options.config.headerMode ?? 'default';
        this.config.requiresNoPriorDefect = options.config.requiresNoPriorDefect ?? false;
        this.config.setFamilyRelationshipOnSingleInsurant =
            options.config.setFamilyRelationshipOnSingleInsurant ?? false;

        if (typeof options.insuredObjectsForm !== 'undefined') {
            this.insuredObjectsForm = {
                controls: options.insuredObjectsForm.controls,
                max: options.insuredObjectsForm.max ?? -1,
                headline: options.insuredObjectsForm.headline,
                description: options.insuredObjectsForm.description,
                objectDescriptionSingular: options.insuredObjectsForm.objectDescriptionSingular,
                objectDescriptionPlural:
                    options.insuredObjectsForm.objectDescriptionPlural ??
                    options.insuredObjectsForm.objectDescriptionSingular,
                addButtonLabel: options.insuredObjectsForm.addButtonLabel ?? 'hinzufügen',
                note: options.insuredObjectsForm.note,
            };
        }

        this.insurantsForm = options.insurantsForm;
        this.variants = options.variants;
    }
}
