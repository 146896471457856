import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { PublicModule } from './public/public.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';

registerLocaleData(localeDe);

@NgModule({
    declarations: [AppComponent, routingComponents],
    imports: [
        BrowserModule,
        PublicModule,
        CoreModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'de' }, Title, CurrencyPipe],
    bootstrap: [AppComponent],
})
export class AppModule {}
