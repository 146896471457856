import { Product, ProductConfig, ProductPresentation } from '../../models/product';

// NOTE: this is identical to the Schutzbrief Students in all but name!

const config: ProductConfig = {
    steps: ['', 'laufzeit', 'versicherte-person', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Steuerfrei nach § 4 Abs. 1 Nr. 5 VersStG',
    requiresMedicalData: true,
    showDiscountInput: true,
    setFamilyRelationshipOnSingleInsurant: true,
};

const presentation: ProductPresentation = {
    description: `
    <p><strong>Auslandskrankenversicherung für Auslandsaufenthalte zur schulischen und universitären Weiterbildung</strong></p>
    <p>Versichert durch astra Versicherung AG</p>
    <p>Die flexible Auslandskrankenversicherung mit weltweitem Geltungsbereich speziell für Schüler, Studenten, Praktikanten, Doktoranten sowie Au Pair- und Work ’n’ Travel-Aufenthalte für nur 38,<sup>99</sup> <span class="currency-sign">€</span> pro Monat.</p>
    <p>Wer sich längerfristig im Ausland aufhält, ist mit dieser speziellen Versicherung gut beraten. Denn wer im Ausland auf ärztliche Hilfe angewiesen ist oder wegen Krankheit oder Unfall in einem Krankenhaus behandelt oder operiert wird, benötigt wegen der möglichen hohen Kosten eine ausreichende finanzielle Absicherung.</p>
    <p>Insbesondere für gesetzlich Krankenversicherte ist angesichts deren nicht vollständigen oder zum Teil gänzlich fehlenden Absicherung eine Auslandskrankenzusatzversicherung unverzichtbar, um sich privat vor möglicherweise horrenden Zahlungsforderungen von ausländischen Ärzten und Kliniken zu schützen.</p>
    <p>Die astra-Auslandskrankenzusatzversicherung trägt medizinisch notwendige Behandlungs- und Untersuchungskosten und erstattet die Kosten für ärztlich verordnete Arzneimittel. Dabei ist die Vertragslaufzeit ab 1 Monat bis zu 12 Monaten frei wählbar.</p>
    <ul>
        <li>Weltweiter Schutz vor Krankheitskosten bei unvorhergesehener Krankheit oder Unfall im Ausland</li>
        <li>Kostenerstattung für medizinisch notwendigen Rücktransport nach Deutschland</li>
        <li>Kostenerstattung auch für Überführung im Todesfall</li>
    </ul>
    <p>Die astra-Auslandskrankenversicherung - mit individuell wählbarer Vertragslaufzeit - speziell für Auslandsaufenthalte zur schulischen und universitären Weiterbildung sowie Au Pair- und Work ’n’ Travel-Aufenthalte.</p>
    `,
    bulletPoints: [
        'Der Versicherungsschutz für Auslandsaufenthalte bei unvorhergesehener Krankheit oder Unfall',
        'Inklusive zahnärztlicher Behandlungen',
        'Freie Arztwahl und freie Auswahl eines Krankenhauses, inklusive Kostenerstattung für Rücktransport',
        'Flexible Vertragslaufzeiten: Ab 1 Monat bis zu 12 Monaten',
    ],
    imageUrl: 'assets/img/product-headers/astra_Versicherung_Schutzbrief_Students.jpg',
    imageAlt: 'astra Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
    imageHeadline: 'Schutzbrief Students, Work ‚n‘ Travel Auslandsschutz für lange Reisen',
    docIPIDUrl: 'astra_IPID_Auslandskrankenschutz.pdf',
    docAVBUrl: 'astra_AVB_Auslandskrankenschutz.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Auslandskrankenschutz.pdf',
};

export const auslandsKvSchueler: Product = new Product({
    slug: 'schueler-auslandskrankenversicherung',
    title: 'Schüler-Auslandskrankenversicherung',
    fullTitle: 'Auslandskrankenversicherung für Auslandsaufenthalte zur schulischen und universitären Weiterbildung',
    insuranceTypeName: 'Schutzbrief Students',
    config,
    presentation,
});
