import { Product, ProductConfig, ProductPresentation } from '../../models/product';

const config: ProductConfig = {
    steps: ['', 'versicherte-person', 'vertragsbeginn', 'rechnungsadresse', 'zahlungsdaten', 'vertragserklaerungen'],
    taxInfo: 'Inkl. 19 % Versicherungssteuer',
    hasFlexPrice: false,
    requiresMedicalData: true,
    showB2BInfo: false,
    showDiscountInput: true,
    showPriceFrom: false,
};

const presentation: ProductPresentation = {
    description: `
    <p><strong class="blue">Schüler-Unfallschutz für nur 18,<sup>99</sup> <span class="currency-sign">€</span> pro Jahr!</strong></p>
    <p>Unfälle ereignen sich bei Kindern und Jugendlichen meist auf dem Weg zur Schule oder zurück, während der Schulzeit oder den Pausen, bzw. während außerunterrichtlichen Veranstaltungen oder Freistunden, Mittagspausen und Schulausflügen. Genau diese Risiken deckt die Schüler-Unfallversicherung zum attraktiven Tarif ab. Der Schutz erstreckt zudem auf schulische Praktika und Arbeitsplatzerkundungen.</p>
    <p>Insbesondere Fälle, wie kosmetische Operationen, die durch einen Unfall erforderlich werden, oder Bergungs- und Transportkosten, deren finanzieller Aufwand von den meisten Eltern selbst nicht getragen werden kann, werden von diesem Schutz abgedeckt. Aber auch Leistungen bei Invalidität oder im Todesfall sind im Schüler-Unfallschutz enthalten.</p>
    <p><strong class="blue">Die Schüler-Unfallversicherung – Schutz bei Invalidität und kosmetischen Operationen sowie Erstattung von Bergungskosten, Krankenhaustagegeld und Leistungen im Todesfall.</strong></p>
    `,
    bulletPoints: [
        'Umfassender Schüler-Unfallschutz bei Krankenhaus-Aufenthalt, Invalidität oder Bergungskosten, etc.',
        'Versicherungsschutz bei allen schulischen Veranstaltungen wie Schulausflüge, Schülergottesdiensten oder Betriebspraktika sowie Freistunden und Mittagspausen',
        'Versicherungsschutz auf dem Schulweg und dem Heimweg',
    ],
    imageUrl: 'assets/img/product-headers/astra-Versicherung_Schueler-Unfallversicherung.jpg',
    imageAlt: 'Die astra Unfall- und Sach-Versicherung zum attraktiven Schüler-Tarif',
    imageHeadline: '<h3>Die Unfallversicherung</h3><p class="no-margin-bottom">zum attraktiven Schüler-Tarif</p>',
    docIPIDUrl: 'astra_IPID_Unfall_Sach.pdf',
    docAVBUrl: 'astra_AVB_Unfall_Sach.pdf',
    docAddInfoUrl: 'astra_Kundeninfo_Unfall_Sach.pdf',
};

export const unfallversicherungSchueler: Product = new Product({
    slug: 'schueler-unfallversicherung',
    title: 'Schüler-Unfallversicherung',
    fullTitle: 'Schüler-Unfallversicherung',
    insuranceTypeName: 'Unfall Schüler',
    config,
    presentation,
});
