import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

const API_TOKEN = environment.apiToken;
const LN_TOKEN = environment.lockersNetToken;
const LN_URL = environment.lockersNet;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(AuthService);
        if (req.url.indexOf(LN_URL) !== -1) {
            req = req.clone({
                setHeaders: {
                    apitoken: LN_TOKEN,
                },
            });
        } else {
            req = req.clone({
                setHeaders: {
                    apitoken: API_TOKEN,
                    AccessToken: `${auth.token ? auth.token : ''}`,
                },
            });
        }

        return next.handle(req);
    }
}
