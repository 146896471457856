import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class SpinnerService {
    private spinningSubject = new BehaviorSubject<number>(0);

    constructor() {}

    /**
     * If subscribed to, emits the last value of spinningSubject as an Observable.
     * spinningSubject counts the number of requests to spin. This methods emits true, if there is at least 1 request and
     * only re-emits if this changes.
     *
     * @returns {Observable<boolean>}
     */
    public get spinning(): Observable<boolean> {
        return this.spinningSubject.asObservable().pipe(
            shareReplay(1),
            map(x => x > 0),
            distinctUntilChanged()
        );
    }

    public start(): void {
        this.spinningSubject.next(this.spinningSubject.value + 1);
    }

    public stop(): void {
        const count = this.spinningSubject.value;
        this.spinningSubject.next(count > 0 ? count - 1 : 0);
    }
}
