import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Subject } from '../../../../node_modules/rxjs';
import { takeUntil } from '../../../../node_modules/rxjs/operators';

@Component({
    selector: 'app-legal',
    templateUrl: './legal-view.component.html',
    styleUrls: ['./legal-view.component.scss'],
})
export class LegalViewComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    private fragment: string;
    private viewInit: boolean = false;
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.fragment.pipe(takeUntil(this.unsubscribe$)).subscribe(fragment => {
            this.fragment = fragment;
            if (this.viewInit) this.scrollToFragment();
        });
    }

    ngAfterViewInit() {
        this.viewInit = true;
        this.scrollToFragment();
    }

    private scrollToFragment() {
        if (this.fragment) {
            setTimeout(() => {
                window.scrollTo(
                    0,
                    document.getElementById(this.fragment).getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top
                );
            });
        } else {
            window.scrollTo(0, 0);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
