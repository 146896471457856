import { Type } from 'class-transformer';
import { Insurant } from './insurant.model';
import { InsuranceType } from './insuranceType.model';
import { PoolObject } from './poolObject.model';
import { CustomerDto } from '../../models/customer-dto';

export class Contract {
    Id: number;
    Customer: CustomerDto;
    @Type(() => InsuranceType)
    InsuranceType: InsuranceType;
    InsuranceNumber: string;
    SignatureDate: string;
    RenewalDate: string;
    ContractFrom: string;
    ContractTo: string;
    @Type(() => Insurant)
    Insurants: Insurant[];
    InsuranceObject: string;
    ObjectValue: number;
    @Type(() => PoolObject)
    ObjectPool: PoolObject[];
}
