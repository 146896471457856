import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { FileDropEvent } from '../models/file-drop-event.model';

@Directive({
    selector: '[appDropZone]',
})
export class DropZoneDirective {
    @HostBinding('class.dragOver') public dragOver = false;
    @HostBinding('class.notAllowed') public notAllowed = false;
    @Input() public allowedFileTypes: string[] = ['image/png', 'image/jpeg', 'application/pdf'];
    @Input() public maxSize = 2048;
    @Output() public fileDropped: EventEmitter<FileDropEvent> = new EventEmitter<FileDropEvent>();

    @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.dragOver = true;
        const items = e.dataTransfer.items;
        // looks like we can't check for file size here bc that information is not provided
        // by the event (items[0].getAsFile() returns null)
        if (items.length > 0 && this.allowedFileTypes.includes(items[0].type)) {
            this.notAllowed = false;
        } else {
            this.notAllowed = true;
        }
    }
    @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.dragOver = false;
        this.notAllowed = false;
    }
    @HostListener('drop', ['$event']) public onDrop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.dragOver = false;
        const files = e.dataTransfer.files;
        if (files.length) {
            if (this.allowedFileTypes.includes(files[0].type)) {
                if (files[0].size / 1024 < this.maxSize) {
                    this.fileDropped.emit(new FileDropEvent('drop', files[0]));
                } else {
                    this.fileDropped.emit(new FileDropEvent('rejected-size'));
                }
            } else {
                this.fileDropped.emit(new FileDropEvent('rejected-type'));
            }
        } else {
            this.fileDropped.emit(new FileDropEvent('empty'));
        }
    }
    public constructor() {}
}
