<footer *ngIf="headerModeService.headerMode$ | async as mode">
    <div class="content-container">
        <div class="grid-container" *ngIf="mode === 'default'; else minimalFooter">
            <div class="grid-sm-1-2 grid-1-4">
                <h3>Service</h3>
                <p><a [routerLink]="['portal', 'schaden-melden']">Schaden online melden</a></p>
                <p>Hotline: 0621 1247682-0</p>
            </div>
            <div class="grid-sm-1-2 grid-1-4">
                <h3>Unternehmen</h3>
                <p><a routerLink="./ueber-uns">Über uns</a></p>
                <p><a routerLink="./kontakt">Kontakt</a></p>
            </div>
            <div class="grid-sm-1-2 grid-1-4">
                <h3>Rechtliches</h3>
                <p><a routerLink="./impressum">Impressum</a></p>
                <p><a routerLink="./datenschutz">Datenschutz</a></p>
            </div>
            <div class="grid-sm-1-2 grid-1-4">
                <h3>Links</h3>
                <p><a href="https://www.astradirect.de/" target="_blank">astradirect Schließfächer</a></p>
                <p><a href="https://www.onlinebox.com/" target="_blank">onlinebox.com</a></p>
            </div>
        </div>
        <ng-template #minimalFooter>
            <div class="grid-container">
                <div class="grid-1-1">
                    <p>
                        <a routerLink="./impressum">Impressum</a> |
                        <a routerLink="./datenschutz">Datenschutz</a>
                    </p>
                </div>
            </div>
        </ng-template>
    </div>
</footer>
