import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieSettingsService } from './cookie-settings.service';

@Component({
    selector: 'app-cookie-settings',
    templateUrl: './cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
})
export class CookieSettingsComponent implements OnInit {
    public form: FormGroup;
    public settingsMode: 'general' | 'detailed' = 'general';
    constructor(private formBuilder: FormBuilder, private cookieService: CookieSettingsService) {
        this.form = this.formBuilder.group({
            necessary: [true],
            google: [this.cookieService.cookieManager.getAllowance('ga')],
        });
        this.form.get('necessary').disable();
    }

    ngOnInit() {}

    get showCookieSettings(): boolean {
        return this.cookieService.showCookieSettingsDialog;
    }

    public submit(): void {
        this.cookieService.cookieManager.setAllAllowances({ ...this.form.value });
        this.cookieService.initScripts();
        this.cookieService.showCookieSettingsDialog = false;
    }

    public acceptAll(): void {
        this.form.patchValue({
            necessary: true,
            google: true,
        });
        this.submit();
    }
}
