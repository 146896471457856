import { User } from '../models/user';
import { UserDto } from '../models/user-dto';
import { dtoToCustomer } from './customer';

export function dtoToUser(dto: UserDto): User {
    return {
        auth: {
            id: dto.Id,
            accessToken: dto.AccessToken,
            tokenExpires: new Date(dto.TokenExpirationDate),
        },
        customer: dtoToCustomer(dto.Customer),
    };
}

export function userToJSON(user: User): string {
    return JSON.stringify({ ...user, auth: { ...user.auth, tokenExpires: user.auth.tokenExpires.toISOString() } });
}

export function JSONToUser(userJSON: string): User {
    const user = JSON.parse(userJSON) as User;
    user.auth.tokenExpires = new Date(user.auth.tokenExpires);
    return user;
}
