import { Pipe, PipeTransform } from '@angular/core';
/**
 *  @class SplitCurrencyPipe
 *  @desc Use this pipe to convert a number to currency, and return either the number before or after decimal point or both.
 *  @param {any} value the value to convert.
 *  @param {ReturnOptions} returnOptions wether to return value "before" or "after" decimal point should be returned, or "both"
 *
 *  @return {any} value as a currency formated string, or value, if input is not viable
 *
 *  @example
 *    {{ 4.23 | splitCurrency:"both" }} // output: 4,23
 */
type ReturnOptions = 'before' | 'after' | 'both';

@Pipe({
    name: 'splitCurrency',
})
export class SplitCurrencyPipe implements PipeTransform {
    transform(value: any, returnOptions: ReturnOptions = 'both'): any {
        if (typeof value !== 'string' && typeof value !== 'number') return value;
        let val: number;
        if (typeof value === 'string') {
            if (isNaN(Number(value))) return value;
            val = Number(value);
        } else {
            val = value;
        }
        let before = Math.floor(val),
            after = Math.round((val % 1) * 100) + '';

        if (after.length < 2) {
            after = '0' + after;
        }
        switch (returnOptions) {
            case 'before':
                return before;
            case 'after':
                return after;
            case 'both':
            default:
                return before + ',' + after;
        }
    }
}
