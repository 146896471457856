import { Injectable, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Customer } from '../../models/customer';
import { InsurenetInterfaceService } from '../insurenet-interface/insurenet-interface.service';
import { UserSessionStorage } from '../../utils/user-session-storage';

@Injectable()
export class CustomerService implements OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private _customer$ = new BehaviorSubject<Customer | null>(null);

    public customer$: Observable<Customer | null> = this._customer$.asObservable();

    public constructor(private insurenetInterface: InsurenetInterfaceService) {}

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public updateCustomer(customer: Customer): Observable<Customer> {
        return this.insurenetInterface.updateCustomer(customer).pipe(
            tap(updatedCustomer => this._customer$.next(updatedCustomer)),
            tap(updatedCustomer => UserSessionStorage.update({ customer: updatedCustomer }))
        );
    }

    public updateCustomerBankDetails(customer: Customer): Observable<Customer> {
        return this.insurenetInterface.updateBankData(customer).pipe(
            tap(updatedCustomer => this._customer$.next(updatedCustomer)),
            tap(updatedCustomer => UserSessionStorage.update({ customer: updatedCustomer }))
        );
    }

    public get customer(): Customer | null {
        return this._customer$.value;
    }

    public set customer(value: Customer | null) {
        this._customer$.next(value);
    }
}
