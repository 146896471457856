import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaToDot',
})
export class CommaToDotPipe implements PipeTransform {
    transform(value: any): any {
        if (typeof value !== 'string') {
            return value;
        }

        return value.replace(',', '.');
    }
}
