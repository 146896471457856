import { Validators, ValidatorFn } from '@angular/forms';
import { ValidateIban } from './iban.validator';
import { ValidateAccountNo } from './account-number.validator';
import { ValidateBankcode } from './bankcode.validator';
import { matchOtherValidator } from './match-other-control.validator';
import { Subject } from 'rxjs';
import { dateBetween, validateBirthday } from './birthday.validator';

/**
 * @class CommonValidators
 * @desc Provides static getters for commonly used validators and validator combinations.
 *  Use them as a shorthand when setting up form groups with validators.
 */
export class CommonValidators {
    /**
     * @method phone
     * @desc returns validator functions for phone numbers. Allows country codes (p.e. +49),
     *  slashes, dashes, brackets and spaces to make numbers more readable to humans.
     * @returns {ValidatorFn[]} Array of validator functions required to validate phone numbers.
     */
    public static get phone(): ValidatorFn[] {
        return [
            Validators.pattern(
                /^\+?\d{1,4}?[\s]?[-.\s\/]?[\s]?\(?\d{1,3}?\)?[\s]?[-.\s\/]?[\s]?\d{1,4}[\s]?[-.\s\/]?[\s]?\d{1,4}[\s]?[-.\s\/]?[\s]?\d{1,9}[\s]?[-.\s\/]?[\s]?\d{1,9}[\s]?[-.\s\/]?[\s]?\d{1,9}$/
            ),
        ];
    }

    /**
     * @method zip
     * @desc returns validator functions for german zip codes.
     * @returns {ValidatorFn[]} Array of validator functions required to validate german zip codes.
     */
    public static get zip(): ValidatorFn[] {
        return [Validators.pattern(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/), Validators.required];
    }

    /**
     * @method something
     * @desc returns validator functions for free form text inputs (min 3 digits, required).
     * @returns {ValidatorFn[]} Array of validator functions required to validate free form text inputs.
     */
    public static get something(): ValidatorFn[] {
        return [Validators.required, Validators.minLength(3)];
    }

    /**
     * @method shortSomething
     * @desc returns validator functions for free form text inputs (min 2 digits, required). Use
     *  this for names to avoid trouble with people with very short names.
     * @returns {ValidatorFn[]} Array of validator functions required to validate free form text inputs.
     */
    public static get shortSomething(): ValidatorFn[] {
        return [Validators.required, Validators.minLength(2)];
    }

    /**
     * @method iban
     * @desc returns validator functions to validate IBANs. Ignores spaces and lower case country codes.
     * @returns {ValidatorFn[]} Array of validator functions required to validate IBANs.
     */
    public static get iban(): ValidatorFn[] {
        return [Validators.required, ValidateIban];
    }

    /**
     * @method bic
     * @desc returns validator functions necessary to validate BICs.
     * @returns {ValidatorFn[]} Array of necessary validator functions.
     */
    public static get bic(): ValidatorFn[] {
        return [Validators.required, Validators.pattern(/^([a-zA-Z]{6})([a-zA-Z0-9]{2}|[a-zA-Z0-9]{5})$/)];
    }

    /**
     * @method accNumber
     * @desc returns validator functions to validate account numbers.
     * @returns {ValidatorFn[]} Array of validator functions required to validate account numbers.
     * TODO: remove this?
     */
    public static get accNumber(): ValidatorFn[] {
        return [Validators.required, ValidateAccountNo];
    }

    /**
     * @method bankcode
     * @desc returns validator functions to validate bankcodes.
     * @returns {ValidatorFn[]} Array of validator functions required to validate bankcodes.
     */
    public static get bankcode(): ValidatorFn[] {
        return [Validators.required, ValidateBankcode];
    }

    /**
     * @method mail
     * @desc returns validator functions to validate mail input fields (required and mail).
     * @returns {ValidatorFn[]} Array of validator functions required to validate mail input fields.
     */
    public static get mail(): ValidatorFn[] {
        return [Validators.required, Validators.email];
    }

    /**
     * @method city
     * @desc returns validator functions required to validate a city name.
     * @returns {ValidatorFN[]} Array of validator functions required to validate city name input fields.
     * @see https://regex101.com/r/hHiogJ/4/
     */
    public static get city(): ValidatorFn[] {
        return [
            Validators.required,
            Validators.pattern(/^[ ]*[a-zA-ZÀ-ž.]{3,}([ \-\(\/,]*[a-zA-ZÀ-ž0-9.]+[. \)]?)*[ ]*$/),
        ];
    }

    /**
     * @method singleNonZeroDigit
     * @desc returns validator function that is true if user entered a single digit other than 0
     * @returns {ValidatorFn[]} Array of required validator functions
     */
    public static get singleNonZeroDigit(): ValidatorFn[] {
        return [Validators.required, Validators.pattern(/^[1-9]$/)];
    }

    public static matchMail(otherControlName: string, unsubscribe$: Subject<any> | null = null): ValidatorFn[] {
        return this.mail.concat([matchOtherValidator(otherControlName, unsubscribe$)]);
    }

    public static matchOther(otherControlName: string, unsubscribe$: Subject<any> | null = null): ValidatorFn[] {
        return [Validators.required, matchOtherValidator(otherControlName, unsubscribe$)];
    }

    public static matchPassword(
        otherControlName: string,
        minLength: number,
        unsubscribe$: Subject<any> | null = null
    ): ValidatorFn[] {
        return this.matchOther(otherControlName, unsubscribe$).concat([Validators.minLength(minLength)]);
    }

    public static birthday(minAge: number = -1, maxAge: number = -1, delimiter: string = '.'): ValidatorFn[] {
        return [Validators.required, validateBirthday(minAge, maxAge, delimiter)];
    }

    public static dateBetween(start: Date, end: Date): ValidatorFn[] {
        return [Validators.required, dateBetween(start, end)];
    }
}
