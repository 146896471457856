import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../core/http/http.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { environment } from '../../../environments/environment';
import { HttpParams } from '../../../../node_modules/@angular/common/http';
import { Subject } from '../../../../node_modules/rxjs';
import { takeUntil } from '../../../../node_modules/rxjs/operators';

const CONFIRM_PW_RESET_URL = environment.basePath + environment.confirmForgotPassword;

@Component({
    selector: 'app-confirm-pw-reset-view',
    templateUrl: './confirm-pw-reset-view.component.html',
    styleUrls: ['./confirm-pw-reset-view.component.scss'],
})
export class ConfirmPwResetViewComponent {
    private unsubscribe$: Subject<void> = new Subject<void>();
    public state: 'error' | 'success' | 'pending' | 'expired' = 'pending';
    constructor(private http: HttpService, private route: ActivatedRoute) {
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            if (params['token']) {
                this.confirmPWReset(params['token']);
            }
        });
    }

    confirmPWReset(token: string) {
        this.state = 'pending';
        const params = new HttpParams().set('token', token);
        this.http.post(CONFIRM_PW_RESET_URL, {}, { params }).subscribe(
            response => (this.state = 'success'),
            err => (this.state = err == 'token invalid or expired' ? 'expired' : 'error')
        );
    }
}
