import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CommonValidators } from '../validators/common-validators';

type Option = string | { label: string | number; value: any };
@Component({
    selector: 'app-select-with-other-option',
    templateUrl: './select-with-other-option.component.html',
    styleUrls: ['./select-with-other-option.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectWithOtherOptionComponent),
            multi: true,
        },
    ],
})
export class SelectWithOtherOptionComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    @Input()
    public options: Option[] = [];
    @Input()
    public otherOptionLabel: string = 'Sonstiger';
    @Input()
    public pleaseEnterOtherLabel: string = 'Bitte geben Sie den Hersteller an:';
    @Input()
    public placeholder: string = 'Hersteller';

    public selectForm: FormGroup;

    private onChange: Function | undefined;
    private onTouched: Function | undefined;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private formBuilder: FormBuilder) {
        this.selectForm = this.formBuilder.group({
            select: [null, Validators.required],
            other: ['', CommonValidators.shortSomething],
        });
        this.subscribeToFormChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']) {
            const currentOptionsValue = changes['options'].currentValue as Option[];
            if (currentOptionsValue?.length > 0) {
                this.selectForm.enable();
                const select = this.selectForm.get('select')?.value;
                if (
                    !currentOptionsValue.find((option: Option) =>
                        typeof option === 'string' ? option === select : option.value === select
                    )
                ) {
                    this.selectForm.patchValue({
                        select: null,
                        other: '',
                    });
                }
            } else {
                this.selectForm.disable();
            }
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    public writeValue(value: any): void {
        if (typeof value === 'string' && value !== '') {
            if (this.hasOption(value)) {
                this.selectForm.patchValue({ select: value, other: '' });
            } else {
                this.selectForm.patchValue({ select: 'other', other: value });
            }
        } else {
            this.selectForm.patchValue({ select: null, other: '' });
        }
    }

    private subscribeToFormChange(): void {
        this.selectForm.valueChanges
            .pipe(
                map(val => (val.select === 'other' ? val.other : val.select)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(val => {
                if (typeof this.onChange === 'function') {
                    this.onChange(val);
                }
            });
    }

    private hasOption(value): boolean {
        for (let i = 0; i < this.options.length; i++) {
            // TODO: this is really hacky, find a better way to do this:
            if (
                (typeof this.options[i] === 'string' && this.options[i] === value) ||
                (typeof this.options[i] !== 'string' && this.options[i]['value'] === value)
            ) {
                return true;
            }
        }
        return false;
    }
}
